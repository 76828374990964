<template>
    <div class="card-header row">
        <h3 class="card-title col-md-9"><b>Current Step</b></h3>
    </div>
    <div id="inner_accordion">
        <div class="card m-2" v-for="(workstep, ind) in worksteps" :key="ind">
            <div class="card-header row" :id="'heading_inner' + ind"
                 v-if="!workstep.is_last && workstep.workstep_type != 'Function' && auth.user.groups.find(group => group.id == workstep.group_id)">
                <div class="col-md-10">
                    <h5><b>Activity: </b><span class="mr-5">{{ workstep.activity }}</span></h5>
                    <p><b>Description: </b><span class="mr-5">{{ workstep.description }}</span></p>
                </div>
                <h5 class="mb-0 col-md-2">
                    <button class="btn" data-toggle="collapse" :data-target="'#inner' + ind" aria-expanded="false"
                            :aria-controls="ind">
                        <span class="material-symbols-outlined col-md-2 mr-1 pr-0"
                              style="border:none;">arrow_drop_down</span>
                    </button>
                </h5>
            </div>

            <div :id="'inner' + ind" class="collapse" :aria-labelledby="'heading_inner' + ind"
                 data-parent="#inner_accordion">
                <div class="card-body">
                    <div class="permissions-table">
                        <div v-for="(action, index) in workstep.possible_actions" :key="index" class="row">
                            <div class="col-md-12">
                                {{ action.description }}
                            </div>
                            <button type="submit" class="btn col-md-4 text-light ml-5"
                                    @click="addWorkstepResult(action)"
                                    :style="{ backgroundColor: (action.button_color != null ? action.button_color : '#355b11'), }"
                                    name="addWorkstepResult">{{ action.name }}
                            </button>
                            <br>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

// import the styles
import {useToast} from "vue-toastification";
import { useAuth } from "@/stores/auth";
import axios from "axios";

export default {
    // register the component
    components: {},
    props: {
        document: {},
        current_folder: {},
    },
    data() {
        return {
            toast: useToast(),
            auth: useAuth(),
            baseUrl: this.baseApiUrl,
            token: localStorage.getItem("edms_token"),
            worksteps: [],
            workstep_id: '',
            document_id: '',
            action_id: '',
            value: '',
        };
    },
    computed: {},
    mounted() {

    },
    watch: {
        '$props.document': {
            immediate: true, // Execute the handler immediately after component is created
            handler(document) {
                this.worksteps = [];
                this.$progress.start();
                if (document != null) {
                    this.getWorksteps(document.folder_id);
                }
                this.$progress.finish();
            },
        },
    },
    methods: {
        async getWorksteps(target_folder) {
            const response = await axios.get('/folder/' + target_folder + '/worksteps', {
                headers: {
                    'Authorization': `Bearer ${this.token}`,
                    'Content-type': 'application/json'
                }
            });
            const data = await response.data;
            // console.log(data);
            if (data.success == false) {
                // Use it!
                this.toast.error(data.message, {
                    timeout: 2000
                });
            } else {
                // Use it!
                this.toast.success(data.message, {
                    timeout: 2000
                });
                this.worksteps = data.data.data;
            }
        },
        async addWorkstepResult(possible_action) {
            // Implement logic to handle editing the indexing field
            this.workstep_id = possible_action.workstep_id;
            this.document_id = this.document.id;
            this.action_id = possible_action.id;
            this.value = possible_action.name;
            const response = await axios.post('workstep/workstepresults/store', 
            {
                    workstep_id: this.workstep_id,
                    document_id: this.document_id,
                    action_id: this.action_id,
                    value: this.value,
                },
            {
                headers: {
                    'Authorization': `Bearer ${this.token}`,
                    'Content-type': 'application/json'
                },
            });
            const data = await response.json();
            if (data.data == 403) {
                // Use it!
                this.toast.error(data.message, {
                    timeout: 5000
                });
            } else {
                // Use it!
                this.toast.success(data.message, {
                    timeout: 5000
                });
                // this.$emit('get-folder', data.data.data.workstep.folder_id);
            }
        },
    },
};
</script>

<style scoped></style>
