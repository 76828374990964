<template>
    <div id="myModal" class="modalIn">
        <div class="modalIn-content-right" style="float: right">
            <span class="close" @click="closeModal()">&times;</span>
            <h2 id="modalTitle" class="modalIn-title">Edit Workstep</h2>
            <!---------------------------------------------------------------------------------------------------------------------------------------->
            <form v-on:submit.prevent="editWorkStepFunc">
                <input
                    type="text"
                    id="folder_id"
                    name="folder_id"
                    hidden
                    v-model="editedWorkStep.folder_id"
                />
                <div class="form-group">
                    <label for="Action Type">Action Type</label>
                    <select
                        class="form-control"
                        id="Action Type"
                        name="workstep_type"
                        required
                        v-model="editedWorkStep.workstep_type"
                    >
                        <option disabled value="null">Select Type</option>
                        <option value="Action">Action</option>
                        <option value="Function">Function</option>
                    </select>
                </div>
                <div
                    class="form-group"
                    v-if="editedWorkStep.workstep_type == 'Function'"
                >
                    <label for="Function">Function</label>
                    <select
                        class="form-control"
                        id="Function"
                        name="workstep_type"
                        required
                        v-model="editedWorkStep.activity"
                    >
                        <option disabled value="null">
                            Select System Function
                        </option>
                        <option value="print_document">Print Document</option>
                        <option value="tesing_function">Tesing Function</option>
                    </select>
                </div>
                <div
                    class="form-group"
                    v-if="editedWorkStep.workstep_type == 'Action'"
                >
                    <label for="name">Activity</label>
                    <input
                        type="text"
                        class="form-control"
                        id="name"
                        placeholder="Add activity"
                        name="activity"
                        required
                        v-model="editedWorkStep.activity"
                    />
                </div>
                <div
                    class="form-group"
                    v-if="editedWorkStep.workstep_type == 'Action'"
                >
                    <label for="description">Description</label>
                    <input
                        type="text"
                        class="form-control"
                        id="description"
                        placeholder="Add Description"
                        name="description"
                        required
                        v-model="editedWorkStep.description"
                    />
                </div>
                <div class="form-group">
                    <label for="Select Previous">Previous</label>
                    <select
                        class="form-control"
                        id="previous"
                        name="previous"
                        required
                        v-model="editedWorkStep.previous"
                    >
                        <option disabled value="0">
                            Select Previous Workstep
                        </option>
                        <option
                            v-for="workstep in worksteps"
                            :key="workstep.id"
                            :value="workstep.id"
                        >
                            {{ workstep.activity }} --
                            {{ workstep.description }}
                        </option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="Select Group">Assign Team</label>
                    <select
                        class="form-control"
                        id="group_id"
                        name="group_id"
                        required
                        v-model="editedWorkStep.group_id"
                    >
                        <option disabled value="null">Select Team</option>
                        <option
                            v-for="group in groups"
                            :key="group.id"
                            :value="group.id"
                        >
                            {{ group.group_name }}
                        </option>
                    </select>
                </div>
                <div style="text-align: right">
                    <button type="submit" class="btn btn-success mt-2">
                        Save Changes
                    </button>
                </div>
            </form>
            <!---------------------------------------------------------------------------------------------------------------------------------------->
        </div>
    </div>
</template>

<script>
import { useToast } from "vue-toastification";
import axios from "axios";

export default {
    props: {
        current_folder: {},
        worksteps: {
            type: Array,
            required: true,
        },
        targetWorkstep: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            toast: useToast(),
            baseUrl: this.baseApiUrl,
            document: null,
            version: this.document_version,
            editedWorkStep: {},
            groups: {},
        };
    },
    mounted() {
        this.editedWorkStep = { ...this.targetWorkstep };
        console.log(this.editedWorkStep);
        this.getGroups(this.$props.current_folder.folder_owner_id);
    },
    methods: {
        async getGroups(group_admin_id) {
            try {
                const response = await axios.get(
                    `/groups/${group_admin_id}`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${localStorage.getItem(
                                "edms_token"
                            )}`,
                        },
                    }
                );
                const data = await response.data;
                this.groups = data.data.data;
            } catch (error) {
                console.error("Error fetching groups:", error);
            }
        },
        async editWorkStepFunc() {
            try {
                const response = await axios.post(
                    `/folder/worksteps/update/${this.editedWorkStep.id}`,
                    this.editedWorkStep,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                                "edms_token"
                            )}`,
                            "Content-type": "application/json",
                        },
                    }
                );
                const data = await response.data;
                if (response.ok) {
                    this.toast.success(data.message, {
                        timeout: 5000,
                    });
                    // Emit an event to notify the parent component that the workstep has been updated
                    this.$emit(
                        "workstep-updated",
                        this.$props.current_folder.id
                    );
                    this.closeModal();
                } else {
                    this.toast.error(data.message, { timeout: 2000 });
                }
            } catch (error) {
                console.error("Error updating workstep:", error);
                this.toast.error("Failed to update workstep", {
                    timeout: 2000,
                });
            }
        },
        closeModal() {
            this.$emit("close-modal");
        },
    },
};
</script>

<style scoped>
.tab {
    cursor: pointer;
    padding: 10px;
}

.tab.active {
    background-color: #355b11;
    color: white;
}
</style>
