<template>
    <div class="py-2 px-4 d-flex align-items-center form-inline my-2 my-lg-0">
        <label class="sr-only">Search</label>
        <div class="relative w-full">
            <input
                type="search"
                name=""
                placeholder="Search"
                @input="search"
                class="mr-sm-2 form-control bg-gray-500 border border-gray-300 text-gray-600"
            />
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        search(event) {
            this.$emit("search", event.target.value);
        },
    },
};
</script>

<style scoped></style>
