import { useRepository } from "@/stores/repository";
import axios from "axios";
import { defineStore } from "pinia";
import { useToast } from "vue-toastification";
const parseItem = (item) => {
    return {
        id: item.id,
        label: item.name,
        children: item.children ? item.children.map(parseItem) : [],
    };
};
export const useFiles = defineStore("files", {
    state: () => ({
        repositoryStore: useRepository(),
        toast: useToast(),
        token: localStorage.getItem("edms_token"),
        userId: localStorage.getItem("userId"),
        openReport: false,
        openFiles: true,
        openFileRequisition: false,
        openFileCategory: false,
        targetFile: {},
        targetRequistion: {},
        openAllFileRequisitions: false,
        openPendingReturnRequisition: false,
        sortedColumn: "created_at",
        sortOrder: "asc",
        files: {
            data: [],
            links: "",
            meta: "",
        },
        file_categories: {
            data: [],
            links: "",
            meta: "",
        },
        file_requistions: {
            data: [],
            links: "",
            meta: "",
        },
        users: [],
    }),
    getters: {
        folder_lookup: (state) => {
            let lookup_array = [];
            console.log(state.repositoryStore.folders);
            state.repositoryStore.folders.forEach((child) => {
                lookup_array.push(parseItem(child));
            });
            return lookup_array;
        },
        file_category_lookup: (state) => {
            let lookup_array = [];
            state.file_categories.data.forEach((child) => {
                lookup_array.push(parseItem(child));
            });
            return lookup_array;
        },
        user_lookup: (state) => {
            let lookup_array = [];
            state.users.forEach((child) => {
                lookup_array.push(parseItem(child));
            });
            return lookup_array;
        },
        phycsical_file_lookup: (state) => {
            let lookup_array = [];
            state.files.data.forEach((child) => {
                child.name = child.file_name + " - " + child.file_description
                lookup_array.push(parseItem(child));
            });
            return lookup_array;
        },
    },
    actions: {
        async fetchFiles(page = 1) {
            try {
                const response = await axios.get("/files" + "?page=" + page, {
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                        "Content-type": "application/json",
                    },
                });
                const data = response.data;
                if (data.success == false) {
                    console.log(data.message);
                } else {
                    this.files.data = data.data.data;
                    this.files.links = data.data.links;
                    this.files.meta = data.data.meta;
                }
            } catch (error) {
                console.error("Error fetching files:", error);
                // Handle errors gracefully
            }
        },
        async fetchFileCategories(page = 1) {
            try {
                const response = await axios.get(
                    "/file-categories" + "?page=" + page,
                    {
                        headers: {
                            Authorization: `Bearer ${this.token}`,
                            "Content-type": "application/json",
                        },
                    }
                );
                const data = response.data;
                if (data.success == false) {
                    console.log(data.message);
                } else {
                    this.file_categories.data = data.data.data;
                    this.file_categories.links = data.data.links;
                    this.file_categories.meta = data.data.meta;
                }
            } catch (error) {
                console.error("Error fetching file_categories:", error);
                // Handle errors gracefully
            }
        },

        async fetchUsers(page = 1) {
            try {
                const response = await axios.get("/users" + "?page=" + page, {
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                        "Content-type": "application/json",
                    },
                });
                const data = response.data;
                if (data.success == false) {
                    console.log(data.message);
                } else {
                    this.users = data.data.data;
                }
            } catch (error) {
                console.error("Error fetching file_categories:", error);
                // Handle errors gracefully
            }
        },

        async editFile(target) {
            try {
                // Create a deep copy of the target object
                const fileData = JSON.parse(JSON.stringify(target));

                // Remove unnecessary fields from fileData
                const fieldsToRemove = ["file_parties", "folder"];
                fieldsToRemove.forEach((field) => {
                    if (field in fileData) {
                        delete fileData[field];
                    }
                });

                // Send only the ID of related objects instead of the whole object
                if (fileData.stage && fileData.stage.id) {
                    fileData.stage = fileData.stage.id;
                }

                // Make the PUT request to update the file
                const response = await axios.put(
                    `/files/${target.id}`,
                    fileData,
                    {
                        headers: {
                            Authorization: `Bearer ${this.token}`,
                            "Content-type": "application/json",
                        },
                    }
                );

                // Extract data from the response
                const data = response.data;

                // Handle response based on status code
                if (response.status === 403) {
                    this.toast.error(data.message, {
                        timeout: 5000,
                    });
                } else {
                    this.toast.success(data.message, {
                        timeout: 5000,
                    });

                    // Update the local state with the updated file data
                    const index = this.files.data.findIndex(
                        (fileItem) => fileItem.id === target.id
                    );
                    if (index !== -1) {
                        // Ensure that the correct part of the response is used for update
                        this.files.data[index] = data.data;
                    }
                }
            } catch (error) {
                console.error(
                    "Error updating file:",
                    error.response ? error.response.data : error.message
                );
                this.toast.error("Error updating file.", {
                    timeout: 5000,
                });
            }
        },

        async addFile(fileData) {
            try {
                const response = await axios.post("/files", fileData, {
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                        "Content-type": "application/json",
                    },
                });
                const data = response.data;
                if (data.data === 403) {
                    this.toast.error(data.message, {
                        timeout: 5000,
                    });
                } else {
                    this.toast.success(data.message, {
                        timeout: 5000,
                    });
                    this.files.data.push(data.data.data);
                }
            } catch (error) {
                console.error(
                    "Error adding file:",
                    error.response ? error.response.data : error.message
                );
            }
        },

        async addFileCategory(fileCategoryData) {
            try {
                const response = await axios.post(
                    "/file-categories",
                    fileCategoryData,
                    {
                        headers: {
                            Authorization: `Bearer ${this.token}`,
                            "Content-type": "application/json",
                        },
                    }
                );
                const data = response.data;
                if (data.data === 403) {
                    this.toast.error(data.message, {
                        timeout: 5000,
                    });
                } else {
                    this.toast.success(data.message, {
                        timeout: 5000,
                    });
                    this.file_categories.data.push(data.data.data);
                }
            } catch (error) {
                console.error(
                    "Error adding file:",
                    error.response ? error.response.data : error.message
                );
            }
        },

        async addFileRequisition(fileRequisitionData) {
            try {
                const response = await axios.post(
                    "/file-requisitions",
                    fileRequisitionData,
                    {
                        headers: {
                            Authorization: `Bearer ${this.token}`,
                            "Content-type": "application/json",
                        },
                    }
                );
                const data = response.data;
                if (data.data === 403) {
                    this.toast.error(data.message, {
                        timeout: 5000,
                    });
                } else {
                    this.toast.success(data.message, {
                        timeout: 5000,
                    });
                    this.file_requistions.data.push(data.data.data);
                }
            } catch (error) {
                console.error(
                    "Error adding file:",
                    error.response ? error.response.data : error.message
                );
            }
        },

        async fetchRequisitions(file_id) {
            try {
                const response = await axios.get(
                    "file/file-requisitions/" + file_id,
                    {
                        headers: {
                            Authorization: `Bearer ${this.token}`,
                            "Content-type": "application/json",
                        },
                    }
                );
                const data = response.data;
                if (data.success == false) {
                    console.log(data.message);
                } else {
                    this.file_requistions.data = data.data.data;
                    this.file_requistions.links = data.data.links;
                    this.file_requistions.meta = data.data.meta;
                }
            } catch (error) {
                console.error("Error fetching File:", error);
                // Handle errors gracefully
            }
        },

        async fetchAllRequisitions(page = 1, params = null,) {
            try {
                const response = await axios.get(
                    "file-requisitions/" + "?page=" + page,
                    {
                        headers: {
                            Authorization: `Bearer ${this.token}`,
                            "Content-type": "application/json",
                        },
                        params: params
                    }
                );
                const data = response.data;
                if (data.success == false) {
                    console.log(data.message);
                } else {
                    this.file_requistions.data = data.data.data;
                    this.file_requistions.links = data.data.links;
                    this.file_requistions.meta = data.data.meta;
                }
            } catch (error) {
                console.error("Error fetching File:", error);
                // Handle errors gracefully
            }
        },
        async updateRequisition(requisitionData) {
            try {
                const response = await axios.put(
                    `/file-requisitions/${requisitionData.id}`,
                    {
                        status: requisitionData.status,
                        is_issued: requisitionData.is_issued,
                        is_return: requisitionData?.is_return
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${this.token}`,
                            "Content-type": "application/json",
                        },
                    }
                );
                const data = response.data;
                if (data.success == false) {
                    console.log(data.message);
                } else {
                    this.toast.success(data.message, {
                        timeout: 5000,
                    });
                    // Update the local state with the updated file data
                    const index = this.file_requistions.data.findIndex(
                        (fileItem) => fileItem.id === requisitionData.id
                    );
                    if (index !== -1) {
                        console.log(data.data.data);
                        // Ensure that the correct part of the response is used for update
                        this.file_requistions.data[index] = data.data.data;
                    }
                }
            } catch (error) {
                console.error("Error fetching file_categories:", error);
                // Handle errors gracefully
            }
        },
        refreshData(page = 1) {
            this.showListView();
            this.fetchFiles(page);
        },
        sortBy(columnKey) {
            console.log(columnKey);
            if (this.sortedColumn == columnKey) {
                this.sortOrder = this.sortOrder === "asc" ? "desc" : "asc";
            } else {
                this.sortedColumn = columnKey;
                this.sortOrder = "asc";
            }
        },

        showReport() {
            this.openFiles = false;
            this.openReport = true;
            this.openFileRequisition = false;
            this.openFileCategory = false;
            this.openAllFileRequisitions = false;
            this.openPendingReturnRequisition = false;
        },
        showListView() {
            this.openFiles = true;
            this.openReport = false;
            this.openFileRequisition = false;
            this.openFileCategory = false;
            this.openAllFileRequisitions = false;
            this.openPendingReturnRequisition = false;
        },
        showFileRequisition() {
            this.openFiles = false;
            this.openReport = false;
            this.openFileRequisition = true;
            this.openFileCategory = false;
            this.openAllFileRequisitions = false;
            this.openPendingReturnRequisition = false;
        },
        showFileCategory() {
            this.openFiles = false;
            this.openReport = false;
            this.openFileRequisition = false;
            this.openFileCategory = true;
            this.openAllFileRequisitions = false;
            this.openPendingReturnRequisition = false;
        },
        showAllFilesRequisitions() {
            this.openFiles = false;
            this.openReport = false;
            this.openFileRequisition = false;
            this.openFileCategory = false;
            this.openAllFileRequisitions = true;
            this.openPendingReturnRequisition = false;
        },
        showPendingReturnRequisition() {
            this.openFiles = false;
            this.openReport = false;
            this.openFileRequisition = false;
            this.openFileCategory = false;
            this.openAllFileRequisitions = false;
            this.openPendingReturnRequisition = true;
        },
    },
});
