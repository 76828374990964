import axios from "axios";
import { defineStore } from "pinia";
import {useToast} from "vue-toastification";
import { useRouter } from 'vue-router';


export const useNotification = defineStore("notification", {
    state: () => ({
        toast: useToast(),
        router:useRouter(),
        token: localStorage.getItem("edms_token"),
        id: localStorage.getItem("id"),
        showNotificationModal:false,
        read_notifications: [],
        unread_notifications: [],
    }),

    getters: {},

    actions: {
        async fetchNotifications() {
            try {
                const response = await axios.get("/notifications", {
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                        "Content-type": "application/json",
                    },
                });
                const data = await response.data;
                // this.unread_notifications = data.data[0].data;
                data.data[0].data.forEach(notification => {
                    if (!this.unread_notifications.some(existingNotification => existingNotification.id === notification.id)) {
                        this.unread_notifications.push(notification);
                        // this.toast.info(notification.title, {
                        //     timeout: 5000,
                        //     position: POSITION.TOP_RIGHT,
                        // });
                    }
                });

                this.read_notifications = data.data[1].data;
            } catch (error) {
                console.error("Error fetching Notifications:", error);
                // Handle errors gracefully
            }
            setTimeout(this.fetchNotifications, 30 * 1000)
        },
        toggleNotificationModal(){
            this.showNotificationModal = !this.showNotificationModal;
        },
        openNotificationTarget(notification){
            if(notification.target_type == 'task') {
                this.router.push({
                    name: "Tasks",
                    query: { slug: notification.target_id },
                });
            }
            this.markAsRead(notification)
            this.toggleNotificationModal()
        },
        async markAsRead(notification) {
            try {
                const response = await axios.get(
                    "/notification/" + notification.id,
                    {
                        headers: {
                            Authorization: `Bearer ${this.token}`,
                            "Content-type": "application/json",
                        },
                    }
                );
                const data = await response.data;
                if (data.success == false) {
                    console.log(data.message);
                } else {
                    console.log(data.message);
                }
                await this.fetchNotifications();
            }
            catch (error) {
                console.error("Error fetching Recents:", error);
                // Handle errors gracefully
            }
        }
    },
});
