<template>
  <div class="content ml-3">
    <div class="container-fluid">
      <button class="btn rounded-pill mt-4 border" @click="showAddEvent = true">
        <span class="material-symbols-outlined rounded-circle bg-light" style="font-size: 15px;">add</span>
        Add Event
      </button>
      <AddEvent v-if="showAddEvent" :daysInMonth="daysInMonth" @add-event="addEventHandler" @close-modal="closeModalHandler"/>
    </div>
    <div class="content ml-4">
      <div class="container-fluid">
        <div class="content y">
          <div class="row">
            <div class="col-md-9">
              <h5>Calendar - {{ monthNames[currentMonth] }} {{ currentYear }}</h5>
              <div class="container bg-light rounded">
                <div class="p-2 mt-3">
                  <table class="table table-bordered calendar">
                    <thead>
                      <tr>
                        <th>Sun</th>
                        <th>Mon</th>
                        <th>Tue</th>
                        <th>Wed</th>
                        <th>Thu</th>
                        <th>Fri</th>
                        <th>Sat</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="row in Math.ceil((daysInMonth + startDay) / 7)" :key="row">
                        <td v-for="col in 7" :key="col" :class="{ 'highlight-today': isToday(row, col) }">
                          <span v-if="isValidDate(row, col)">
                            {{ getDate(row, col) }}
                            <ul>
                              <li v-for="eventObj in getEventsForDay(getDate(row, col))" :key="eventObj.id">
                                {{ eventObj.name }} - Created by: {{ eventObj.created_by?.name }}
                              </li>
                            </ul>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <h5>Events</h5>
              <div v-for="(eventsList, day) in events" :key="day" class="list-group-item">
                <h6>Day {{ day }}</h6>
                <ul v-if="eventsList.length">
                  <li v-for="eventObj in eventsList" :key="eventObj.id">
                    {{ eventObj.name }} - Created by: {{ eventObj.created_by?.name }}
                  </li>
                </ul>
                <p v-else>No events for this day</p>
              </div>
              <p v-if="!Object.keys(events).length">No events available</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="js">
import { ref, onMounted } from 'vue';
import axios from 'axios';
import AddEvent from '@/components/modals/AddEvent.vue';
import { useCases } from "@/stores/cases";

const caseStore = useCases();

const events = ref({});
const showAddEvent = ref(false);
const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
const now = new Date();
const currentMonth = ref(now.getMonth());
const currentYear = ref(now.getFullYear());
const daysInMonth = new Date(currentYear.value, currentMonth.value + 1, 0).getDate();
const startDay = new Date(currentYear.value, currentMonth.value, 1).getDay();
const todayDate = now.getDate();

const closeModalHandler = () => {
  showAddEvent.value = false;
};

async function fetchEvents(caseId) {
  const url = `/events/${caseId}`;
  console.log(`Fetching events for case ID ${caseId} from url: ${url}`);
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("edms_token")}`,
        "Content-Type": "application/json",
      },
    });
    const fetchedEvents = response.data.data.data; // Accessing nested data
    console.log('Fetched events:', fetchedEvents);
    if (Array.isArray(fetchedEvents)) {
      fetchedEvents.forEach(event => {
        const day = new Date(event.event_start).getDate();
        if (!events.value[day]) {
          events.value[day] = [];
        }
        events.value[day].push(event);
      });
    } else {
      console.error('Fetched events is not an array:', fetchedEvents);
    }
  } catch (error) {
    console.error('Error fetching events:', error);
  }
}

const addEventHandler = async (newEvent) => {
  const caseId = caseStore.targetCase?.id || ''; // Ensure you have the correct case ID
  const url = `/events`;
  try {
    const response = await axios.post(url, {
      case_id: caseId,
      name: newEvent.eventDescription,
      event_date_start: newEvent.eventStartDate,
      event_date_end: newEvent.eventEndDate ? newEvent.eventEndDate : null,
    }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("edms_token")}`,
        "Content-Type": "application/json",
      },
    });
    console.log('Event added:', response.data);
    fetchEvents(caseId); // Refresh events after adding a new one
    closeModalHandler(); // Close the modal after adding the event
  } catch (error) {
    console.error('Error adding event:', error);
  }
};

onMounted(() => {
  const caseId = caseStore.targetCase?.id || ''; // Ensure you have the correct case ID
  fetchEvents(caseId);
});

const isValidDate = (row, col) => {
  const date = (row - 1) * 7 + col - startDay + 1;
  return date > 0 && date <= daysInMonth;
};

const getDate = (row, col) => {
  return (row - 1) * 7 + col - startDay + 1;
};

const getEventsForDay = (day) => {
  return events.value[day] || [];
};

const isToday = (row, col) => {
  const date = getDate(row, col);
  return currentMonth.value === now.getMonth() && currentYear.value === now.getFullYear() && date === todayDate;
};
</script>

<style scoped>
.calendar th, .calendar td {
  text-align: center;
  vertical-align: top;
  padding: 5px;
  font-size: 0.8em;
}
.table-bordered {
  border: 1px solid #dee2e6;
}
.table-bordered th, .table-bordered td {
  border: 1px solid #dee2e6;
}
.highlight-today {
  background-color: yellow;
}
</style>
