<template>
	<div class="row">
		<div class="card-body table-responsive">
			<div class="row">
				<div class="col-md-9">
					<h3>File Requisitions</h3>
				</div>
			</div>
                    <table class="table table-hover table-bordered">
                        <thead class="thead-light">
                            <tr>
                                <th># File Id</th>
                                <th>File Name</th>
                                <th>Requisition Date</th>
                                <th>Requisitioned By</th>
                                <th>Requisition Reason</th>
                                <th>Date Modified</th>
                                <th>Modified By</th>
                                <th>Status</th>
                                <th>Return Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr style="cursor: pointer" v-for="(target_file, id) in filesStore.file_requistions.data" :key="id"
                                :class="{ 'table-active': isActive == target_file.id }"
                                @click="highlistItem(target_file)">
                                <td>{{ target_file.id }}</td>
                                <td>{{ target_file.file?.file_name }}</td>
                                <td>
                                    {{ new Date(target_file.created_at).toLocaleString() }}
                                </td>
                                <td>{{ target_file.requisition_by?.name }}</td>
                                <td>{{ target_file.requisition_reason }}</td>
                                <td>
                                    {{ new Date(target_file.updated_at).toLocaleString() }}
                                </td>
                                <td>{{ target_file.updated_by?.name }}</td>
                                <td>{{ target_file.status }}</td>
                                <td>
                                    {{ target_file.returned_at ? new Date(target_file.returned_at).toLocaleString(): '' }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
		</div>
        
        <div class="card-footer clearfix">
                    <ul class="pagination pagination-sm m-0 float-right">
                        <li class="page-item">
                            <a class="page-link" href="#" @click="
                                    filesStore.refreshData(
                                        'files',
                                        filesStore.file_requistions.meta.current_page == 1
                                            ? filesStore.file_requistions.meta.current_page
                                            : filesStore.file_requistions.meta.current_page -
                                            1
                                    )
                                    " :disabled="filesStore.file_requistions.meta.current_page == 1">&laquo;</a>
                        </li>
                        <li class="page-item">
                            <a class="page-link" href="#" @click="filesStore.refreshData('files', 1)"
                                :disabled="filesStore.file_requistions.meta.current_page == 1">First</a>
                        </li>
                        <span class="page-item page-link">Page {{ filesStore.file_requistions.meta.current_page }} of
                            {{ filesStore.file_requistions.meta.last_page }}</span>
                        <li class="page-item">
                            <a class="page-link disabled" href="#" @click="
                                    filesStore.refreshData(
                                        'files',
                                        filesStore.file_requistions.meta.last_page
                                    )
                                    ">Last</a>
                        </li>
                        <li class="page-item">
                            <a class="page-link" href="#" @click="
                                    filesStore.refreshData(
                                        'files',
                                        filesStore.file_requistions.meta.current_page ==
                                            filesStore.file_requistions.meta.last_page
                                            ? filesStore.file_requistions.meta.current_page
                                            : filesStore.file_requistions.meta.current_page +
                                            1
                                    )
                                    ">&raquo;</a>
                        </li>
                    </ul>
                </div>
	</div>
	<RequisitionApproval v-if="showApprovalModal" @close-modal="closeModalHandler" />
</template>

<script setup lang="js">
import { onMounted, ref} from "vue";
import RequisitionApproval from "@/components/modals/RequisitionApproval.vue";
import { useFiles } from "@/stores/files";

const showApprovalModal = ref(false);
const isActive = ref(0);


const filesStore = useFiles();

function highlistItem(item) {
    isActive.value = item.id;
    showApprovalModal.value = true;
    filesStore.targetRequistion = item;
}

function closeModalHandler() {
    // Update the prop to close the modal
    showApprovalModal.value = false;
}
onMounted(async () => {
    await filesStore.fetchRequisitions(filesStore.targetFile.id);
})

</script>

<style scoped>
.context_overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: transparent;
	z-index: 49;
}

.context_overlay::before {
	content: "";
	position: absolute;
	width: 100%;
	height: 100%;
}

.comparision_icon {
	-webkit-transform: rotate(90deg);
	-moz-transform: rotate(90deg);
	-o-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
}

.form-control {
	padding-left: 2.375rem;
}

.has-search .form-control-feedback {
	position: absolute;
	z-index: 2;
	display: block;
	width: 2.375rem;
	height: 2.375rem;
	line-height: 2.375rem;
	text-align: center;
	pointer-events: none;
	color: #aaa;
}
</style>
