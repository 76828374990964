<template>
    <div>
        <div class="card">
            <div class="card-header row" style="text-align: center">
                <p class="btn btn-success col-md-6" @click="openAddUser = true">
                    <span class="material-symbols-outlined" style="float: left">person_add</span>
                    Add User
                </p>
            </div>

            <div class="card-body">
                <table class="table table-bordered">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>User</th>
                        <th>Action</th>
                    </tr>
                    </thead>
                    <tbody v-if="propertiesStore.current_folder?.allowed_users != null || propertiesStore.document?.allowed_users != null ">
                    <tr
                        v-for="(user, id) in propertiesStore.focus == 'folder' ? propertiesStore.current_folder?.allowed_users?.split(',') : propertiesStore.document?.allowed_users?.split(',')"
                        :key="id"
                    >
                        <td>{{ id + 1 }}</td>
                        <td>
                            {{ users?.find(u => u.id == user)?.name }}
                        </td>
                        <td>
                            <a
                                class="btn"
                                @click="removeUser(user)"
                            >
                                <i
                                    class="material-symbols-outlined col-md-2 mr-1 pr-0"
                                    style="
                                                        cursor: pointer;
                                                        color: red;
                                                    "
                                >delete</i
                                >
                            </a>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <!-- Modal components -->
        <AllowUsersModal
            v-if="openAddUser"
            :current_folder="propertiesStore.current_folder"
            @close-modal="closeModal"
            @refresh-data="refreshData"
        />
    </div>
</template>

<script setup lang="js">
import {useToast} from "vue-toastification";
import AllowUsersModal from "./modals/AllowUsersModal.vue";
import { ref, onMounted } from "vue";
import { useProperties } from "@/stores/properties";
import axios from "axios";

const propertiesStore = useProperties();
const toast = useToast();
const token = localStorage.getItem("edms_token");
const openAddUser = ref(false);
const users = ref([]);

// const folder_allowed_users = ref(propertiesStore.current_folder?.allowed_users?.split(",") || []);
// const document_allowed_users = ref(propertiesStore.document?.allowed_users?.split(",") || []);
//
// const allowed_users = ref(propertiesStore.focus == "folder" ? folder_allowed_users : document_allowed_users);
function closeModal() {
    openAddUser.value = false;
}
async function removeUser(user) {
    if(propertiesStore.focus == "folder"){
        propertiesStore.current_folder.allowed_users = propertiesStore.current_folder?.allowed_users.split(",").filter(u => u != user).toString();
        propertiesStore.current_folder.allowed_users = propertiesStore.current_folder.allowed_users.length > 0 ? propertiesStore.current_folder.allowed_users : null;
        const response = await axios.post(
            `/folder/manage_access/${propertiesStore.current_folder.id}`,
            {
                parent_folder_id: propertiesStore.current_folder.parent_folder_id,
                name: propertiesStore.current_folder.name,
                allowed_users: propertiesStore.current_folder.allowed_users,
            },
            //  propertiesStore.current_folder,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            }
        );
        if (!response.data.success) {
            this.success = false;
            const errorData = response.data;
            throw new Error(errorData.message || 'Failed to add user to access list');
        }
    }else{
        propertiesStore.document.allowed_users = propertiesStore.document?.allowed_users.split(",").filter(u => u != user).toString();
        propertiesStore.document.allowed_users = propertiesStore.document.allowed_users.length > 0 ? propertiesStore.document.allowed_users : null;
        const response = await axios.post(
            '/folder/documents/manage_access/' + propertiesStore.document.id,
            {
                allowed_users: propertiesStore.document.allowed_users,
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            }
        );
        if (!response.data.success) {
            this.success = false;
            const errorData = response.data;
            throw new Error(errorData.message || 'Failed to add user to access list');
        }
    }
    toast.success('Access Updated successfully', {
        timeout: 5000,
    });
}
function refreshData() {
    closeModal();
}
const fetchUsers = async () => {
    try {
        const response = await axios.get(`/users`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        });
        users.value = response.data.data.data;
    } catch (error) {
        console.error('Error fetching users:', error);
    }
}
onMounted(() => {
    fetchUsers();
})
</script>

<style scoped>
.table {
    width: 100%;
    border-collapse: collapse;
}

.table th,
.table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.table th {
    background-color: #f2f2f2;
}

.tooltip-container {
    position: relative;
}

.tooltip-text {
    visibility: hidden;
    width: auto;
    background-color: black;
    color: white;
    text-align: center;
    padding: 5px;
    border-radius: 5px;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}
</style>
