<template>
    <div class="abs w50 main-sidebar sidebar-light-indigo elevation-4 overflow-y-auto"
        style="padding: 10px; font-weight: bold; width: 7%; font-size: 12px">
        <!-- Brand Logo -->
        <center>
            <a href="" @click="$router.push('/')">
                <img src="../assets/logo.png" alt="Company Logo" class="img-circle elevation-3"
                    style="opacity: 0.8; padding: 7%" width="50px" height="50px" /> </a><br />
            <div class="font-weight-bold" style="margin-top: 10px">
                DISI EDMS
            </div>
        </center>
        <hr />
        <!-- <div class="" style="padding-bottom: 2px" v-if="authStore.user_permissions.includes('view_dashboard')"> -->
        <div class="" style="padding-bottom: 2px" v-if="authStore.allowedAccess('view_dashboard')">
            <input class="g" name="sidebar_tab1" value="0" type="radio" v-model="activeTab" />
            <li class="x y tab" @click="_tab(0)">
                <center>
                    <span class="material-symbols-outlined"
                        style="font-size: 30px">space_dashboard</span><br />Dashboard
                </center>
            </li>
        </div>
        <div class="" style="padding-bottom: 2px" v-if="authStore.allowedAccess('view_repository')">
            <input class="g" name="sidebar_tab1" value="1" type="radio" v-model="activeTab" />
            <li class="x y tab" @click="_tab(1)">
                <center>
                    <span class="material-symbols-outlined" style="font-size: 30px">database</span><br />Repository
                </center>
            </li>
        </div>
        <div class="" style="padding-bottom: 2px" v-if="authStore.allowedAccess('view_tasks')">
            <input class="g" name="sidebar_tab1" value="7" type="radio" v-model="activeTab" />
            <li class="x y tab" @click="_tab(7)">
                <center>
                    <span class="material-symbols-outlined" style="font-size: 30px">task</span><br />Tasks
                </center>
            </li>
        </div>

        <div class="" style="padding-bottom: 2px" v-if="authStore.allowedAccess('view_cases')">
            <input class="g" name="sidebar_tab1" value="8" type="radio" v-model="activeTab" />
            <li class="x y tab" @click="_tab(8)">
                <center>
                    <span class="material-symbols-outlined" style="font-size: 30px">work</span><br />Cases
                </center>
            </li>
        </div>
        <div class="" style="padding-bottom: 2px" v-if="authStore.allowedAccess('view_tasks')">
            <input class="g" name="sidebar_tab1" value="9" type="radio" v-model="activeTab" />
            <li class="x y tab" @click="_tab(9)">
                <center>
                    <span class="material-symbols-outlined"
                        style="font-size: 30px">account_balance</span><br />Contracts
                </center>
            </li>
        </div>

        <div class="" style="padding-bottom: 2px" v-if="authStore.allowedAccess('view_tasks')">
            <input class="g" name="sidebar_tab1" value="10" type="radio" v-model="activeTab" />
            <li class="x y tab" @click="_tab(10)">
                <center>
                    <span class="material-symbols-outlined" style="font-size: 30px">swap_horiz</span><br />File
                    Movement
                </center>
            </li>
        </div>

        <div class="" style="padding-bottom: 2px" v-if="authStore.allowedAccess('view_recenlty_viewed')">
            <input class="g" name="sidebar_tab1" value="2" type="radio" v-model="activeTab" />
            <li class="x y tab" @click="_tab(2)">
                <center>
                    <span class="material-symbols-outlined" style="font-size: 30px">calendar_clock</span><br />Recently
                    Viewed
                </center>
            </li>
        </div>

        <div class="" style="padding-bottom: 2px" v-if="authStore.allowedAccess('view_bookmarks')">
            <input class="g" name="sidebar_tab1" value="3" type="radio" v-model="activeTab" />
            <li class="x y tab" @click="_tab(3)">
                <center>
                    <span class="material-symbols-outlined"
                        style="font-size: 30px">collections_bookmark</span><br />Bookmarks
                </center>
            </li>
        </div>
        <div class="" style="padding-bottom: 2px" v-if="authStore.allowedAccess('view_saved_searches')">
            <input class="g" name="sidebar_tab1" value="4" type="radio" v-model="activeTab" />
            <li class="x y tab" @click="_tab(4)">
                <center>
                    <span class="material-symbols-outlined" style="font-size: 30px">saved_search</span><br />Saved
                    Searches
                </center>
            </li>
        </div>
        <div class="" style="padding-bottom: 2px" v-if="authStore.allowedAccess('view_settings')">
            <input class="g" name="sidebar_tab1" value="6" type="radio" v-model="activeTab" />
            <li class="x y tab" @click="_tab(6)">
                <center>
                    <span class="material-symbols-outlined" style="font-size: 30px">settings</span><br />Settings
                </center>
            </li>
        </div>
    </div>
</template>

<script setup lang="js">
import { useAuth } from "@/stores/auth";
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const router = useRouter()
const route = useRoute()
const authStore = useAuth();

const activeTab = computed(() => {
    switch (route.name) {
        case "Dashboard":
            return 0;
        case "Repository":
            return 1;
        case "Recently Viewed":
            return 2;
        case "Bookmarks":
            return 3;
        case "Saved Searches":
            return 4;
        case "Workflows":
            return 5;
        case "Settings":
            return 6;
        case "Tasks":
            return 7;
        case "Cases":
            return 8;
        case "Contracts":
            return 9;
        case "File Movement":
            return 10;
        // Add cases for other tabs
        default:
            return 0;
        // break;
    }
});
const _tab = (index) => {
    // this.activeTab = index;
    switch (index) {
        case 0:
            router.push("/");
            break;
        case 1:
            router.push("/repository");
            break;
        case 2:
            router.push("/recently_viewed");
            break;
        case 3:
            router.push("/bookmarks");
            break;
        case 4:
            router.push("/saved-searches");
            break;
        case 5:
            router.push("/Workflows");
            break;
        case 6:
            router.push("/settings");
            break;
        case 7:
            router.push("/tasks");
            break;
        case 8:
            router.push("/cases");
            break;
        case 9:
            router.push("/contracts");
            break;
        case 10:
            router.push("/file-movement");
            break;
        // Add cases for other tabs
        default:
            break;
    }
};
</script>

<style scoped>
.tab {
    cursor: pointer;
    padding: 10px;
}

.tab.active {
    background-color: #355b11;
    color: white;
}

/* Add styles for other tab content as needed */
</style>
