<template>
    <div>
        <div style="margin-bottom: 15px; align-items: center">
            <button v-if="authStore.allowedAccess('add_metadata_field')" style="font-weight: bolder; display: flex"
                class="new btn btn-success" @click="showAdd">
                <span style="align-items: center" class="material-symbols-outlined">add</span>
                New
            </button>
        </div>
        <table class="table table-bordered">
            <thead class="thead-light">
                <tr>
                    <th>Field Name</th>
                    <th>Data Type</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(field, id) in currentFolderFields" :key="id"
                    @contextmenu.prevent="showContextMenu($event, field)">
                    <td>
                        <template v-if="editingFieldId === field.id">
                            <input v-model="editedField.field_name" class="form-control" placeholder="Field Name" />
                        </template>
                        <template v-else>
                            {{ field.field_name }}
                        </template>
                    </td>
                    <td>
                        <template v-if="editingFieldId === field.id">
                            <treeselect v-model="editedField.field_datatype" :multiple="false" :clearable="true"
                                :searchable="true" :disabled="disabled" :open-on-click="true" :open-on-focus="true"
                                :clear-on-select="true" :close-on-select="true" :always-open="false"
                                :append-to-body="appendToBody" :options="options" :limit="3" :max-height="200"
                                style="width: 8vw" />
                        </template>
                        <template v-else>
                            {{ field.field_datatype }}
                        </template>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <!-- Custom Context Menu -->
    <ContextMenu v-if="showMenu" :actions="contextMenuActions" @action-clicked="handleActionClick" :x="menuX"
        :y="menuY" />
    <EditField :editField="editField" v-if="showEditField" v-on:close-modal="closeModalHandler"
        @update-index-field-data="editField" :options="options" />
    <AddField v-if="showAddField" v-on:close-modal="closeModalHandler" @update-index-field-data="editField"
        :options="options" :current_folder="current_folder" :currentFolderFields="currentFolderFields" />
</template>

<script>
import ContextMenu from "@/components/ContextMenu.vue";
import AddField from "@/components/modals/AddField.vue";
import EditField from "@/components/modals/EditField.vue";
import { useAuth } from "@/stores/auth";
import { ref } from "vue";
import { useToast } from "vue-toastification";

// import Treeselect from "vue3-treeselect";
// import "vue3-treeselect/dist/vue3-treeselect.css";
// import the component
import Treeselect from "@zanmato/vue3-treeselect";
// import the styles
import "@zanmato/vue3-treeselect/dist/vue3-treeselect.min.css";
import axios from "axios";

export default {
    components: {
        Treeselect,
        ContextMenu,
        EditField,
        AddField,
    },
    props: {
        document: {},
        current_folder: {},
        serials: Array,
        lookups: Array,
    },
    data() {
        return {
            toast: useToast(),
            baseUrl: this.baseApiUrl,
            token: localStorage.getItem("edms_token"),
            value: null,
            showMenu: ref(false),
            menuX: ref(0),
            menuY: ref(0),
            targetRow: ref({}),
            contextMenuActions: ref([
                { label: "Edit", action: "edit" },
                { label: "Delete", action: "delete" },
            ]),
            showEditField: ref(false),
            showAddField: ref(false),
            authStore: useAuth(),
        };
    },
    computed: {
        lookups_bases() {
            return this.$props.lookups.map((lookup) => {
                return { id: "lookup-" + lookup.id, label: lookup.name };
            });
        },
        serials_bases() {
            return this.$props.serials.map((serial) => {
                return {
                    id: "serial-" + serial.id,
                    label:
                        serial.prefix +
                        " " +
                        serial.number_format +
                        " " +
                        serial.date_format +
                        " " +
                        serial.postfix +
                        " (order: " +
                        serial.order_of_items +
                        ")",
                };
            });
        },
        options() {
            return [
                {
                    id: "String",
                    label: "Text (String)",
                },
                {
                    id: "Float",
                    label: "Numeric (Float)",
                },
                {
                    id: "Datetime",
                    label: "Datetime",
                },
                {
                    id: "lookup",
                    label: "Category",
                    children: this.lookups_bases,
                },
                {
                    id: "serial",
                    label: "serial",
                    children: this.serials_bases,
                },
            ];
        },
        currentFolderFields() {
            return this.current_folder ? this.current_folder.fields : [];
        },
    },
    methods: {
        showContextMenu(event, field) {
            event.preventDefault();
            this.showMenu = true;
            this.targetRow = field;
            this.menuX = event.clientX - 1350;
            this.menuY = event.clientY - 20;
            console.log(this.menuX, this.menuY, event.clientX, event.clientY);
        },
        closeContextMenu() {
            this.showMenu = false;
        },
        async handleActionClick(action) {
            this.closeContextMenu();
            switch (action) {
                case "edit":
                    this.editField = this.targetRow;
                    this.showEditField = true;
                    break;
                case "delete":
                    this.$swal
                        .fire({
                            title: "Are you sure?",
                            text: "Once deleted, you will not be able to recover this Meta Data Field and All It's Contents!",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonText: "Yes, delete it!",
                            cancelButtonText: "No, cancel!",
                            reverseButtons: true,
                            showLoaderOnConfirm: true,
                        })
                        .then((result) => {
                            if (result.isConfirmed) {
                                axios.get(
                                    "/folder/fields/delete/" +
                                    this.targetRow.id,
                                    {
                                        headers: {
                                            Authorization: `Bearer ${this.token}`,
                                            "Content-type": "application/json",
                                        },
                                    }
                                )
                                    .then((response) => response.data)
                                    .then((response_data) => {
                                        if (response_data.data == 403) {
                                            // Use it!
                                            this.toast.error(
                                                response_data.message,
                                                {
                                                    timeout: 5000,
                                                }
                                            );
                                        } else {
                                            // Use it!
                                            this.toast.success(
                                                response_data.message,
                                                {
                                                    timeout: 5000,
                                                }
                                            );
                                            this.$emit(
                                                "update-index-field-data",
                                                "delete",
                                                response_data.data.data
                                            );
                                        }
                                    });
                                // console.log(response);
                                // const data = response.json();
                            } else {
                                this.toast.success("Meta Data Field is safe!", {
                                    timeout: 5000,
                                });
                            }
                        });
                    break;
                // Add cases for other tabs
                default:
                    break;
                // console.log(action);
                // console.log(this.targetRow);
            }
        },
        closeModalHandler() {
            // Update the prop to close the modal
            this.showEditField = false;
            this.showAddField = false;
        },
        editField(field) {
            this.$emit("update-index-field-data", "update", field);
        },
        addItem(field) {
            this.$emit("update-index-field-data", "add", field);
        },
        showAdd() {
            this.showAddField = true;
        },
    },
};
</script>
