<template>
  <div class="content ml-3" :style="{ width: caseStore.openProperties ? '75%' : '100%' }">
    <div class="container-fluid">
      <div class="content y">
        <table class="plain-table">
          <thead>
            <tr>
              <th>Action</th>
              <!-- <th>Action_by</th> -->
              <th>case_id</th>
              <!-- <th>stage</th> -->
              <th>items_changed</th>
              <th>Date & Time</th>
              <th>Notes</th>
            </tr>
          </thead>
          <tbody>
            <tr class="table-row" v-for="(target_case, index) in caseHistory" :key="index">
              <td>{{ target_case.action }}</td>
              <!-- <td>{{ target_case.action_by }}</td> -->
              <td>{{ target_case.case_id }}</td>
              <!-- <td>{{ target_case.stage }}</td> -->
              <td>
                <div v-if="target_case.items_changed">
                  <div v-if="parsedItems = JSON.parse(target_case.items_changed)">
                    <p v-if="parsedItems.parties">
                      <strong>Contact Name:</strong> {{ parsedItems.parties.contact.name }}<br>
                      <strong>Phone Number:</strong> {{ parsedItems.parties.contact.phone_number }}<br>
                      <strong>Email:</strong> {{ parsedItems.parties.contact.email }}<br>
                    </p>
                  </div>
                </div>
              </td>
              <td>{{ new Date(target_case.created_at).toLocaleString() }}</td>
              <td class="notes-cell">
                <div class="notes-content">{{ target_case.notes }}</div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="pagination">
          <button @click="previousPage" :disabled="currentPage === 1">
            <i class="material-icons-outlined">keyboard_arrow_left</i>
          </button>
          <span>Page {{ currentPage }} of {{ totalPages }}</span>
          <button @click="nextPage" :disabled="currentPage === totalPages">
            <i class="material-icons-outlined">keyboard_arrow_right</i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {  ref, onMounted,} from 'vue';
import { useCases } from "@/stores/cases";
import axios from 'axios';
import { useToast } from "vue-toastification";
const toast = useToast();
const caseStore = useCases();
const caseHistory= ref([]);

const caseData = ref({});
// caseData.value.stage = caseData.value.stage.id;
// caseData.value.court_location = caseData.value.court_location.id;
// caseData.value.external_advocate = caseData.value.external_advocate.id;
// caseData.value.court = caseData.value.court.id;
// caseData.value.created_by = caseData.value.created_by.id;
// const assigned_to_external = ref();
// const externalAdvocateOptions = ref([]);
// const internalAdvocateOptions = ref([]);
async function fetchCaseHistory(page = 1) {
    try {
        const response = await axios.get(`/case-history/${caseData.value.id}?page="${page}`, {
            headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                                "edms_token"
                            )}`,
                            "Content-Type": "application/json",
                        },
        });
        const data = response.data;
           console.log("data",data.data)
        if (response.status === 403) {
            toast.error(data.message, {
                timeout: 5000,
            });
        } else {
          
          caseHistory.value=data.data.data
           // this.caseHistory.data.push(data.data.data)
        }
    } catch (error) {
        console.error(
            "Error fetching case history:",
            error.response ? error.response.data : error.message
        );
        toast.error("Error fetching case history.", {
            timeout: 5000,
        });
    }
}

 // function formatItemsChanged(itemsChanged) {
 //        try {
 //            let parsedItems = JSON.parse(itemsChanged);
 //            let parties = parsedItems.parties;
 //            return `
 //                <div>
 //                    <strong>ID:</strong> ${parties.id}<br>
 //                    <strong>Contact ID:</strong> ${parties.contact_id}<br>
 //                    <strong>Is Active:</strong> ${parties.is_active}<br>
 //                    <strong>Case ID:</strong> ${parties.case_id}<br>
 //                    <strong>Created By:</strong> ${parties.created_by}<br>
 //                    <strong>Updated By:</strong> ${parties.updated_by}<br>
 //                    <strong>Created At:</strong> ${new Date(parties.created_at).toLocaleString()}<br>
 //                    <strong>Updated At:</strong> ${new Date(parties.updated_at).toLocaleString()}
 //                </div>
 //            `;
 //        } catch (error) {
 //            console.error("Error parsing items_changed:", error);
 //            return "Invalid data";
 //        }
 //    }



onMounted(async () => {
      caseData.value= caseStore.targetCase;
      console.log("id",caseData.value.id)
      fetchCaseHistory(1);

});

</script>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  padding: 8px;
  text-align: left;
  position: relative; 
  max-width: 300px;  
  white-space: nowrap;  
  overflow: hidden;  
  text-overflow: ellipsis;  
}

th {
  background-color: #f2f2f2;
}

.plain-table th,
.plain-table td {
  border: none;
}

.table-row {
  border-bottom: 1px solid #ddd;
}

.notes-cell {
  max-width: 300px; 
}

.notes-content {
  position: relative;
  max-width: 100%;  
  overflow-wrap: break-word;  
  word-wrap: break-word; 
}

</style>
