<template>
    <div id="myModal" class="modalIn">
        <!-- Modal content -->
        <div class="modalIn-content mt-5">
            <!-- Modal header and tabs -->
            <div class="modal-header border-bottom-0">
                <!-- Tab headers -->
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <!-- Document Tab -->
                    <li class="nav-item">
                        <a
                            class="nav-link active"
                            data-toggle="tab"
                            href="#documents"
                            role="tab"
                            aria-controls="documents"
                            aria-selected="true"
                            >Document</a
                        >
                    </li>

                    <!-- Metadata Tab -->
                    <li class="nav-item">
                        <a
                            class="nav-link"
                            data-toggle="tab"
                            href="#metadata"
                            role="tab"
                            aria-controls="metadata"
                            aria-selected="false"
                            >Metadata</a
                        >
                    </li>
                </ul>
                <!-- Close button -->
                <button type="button" class="close" @click="closeModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <!-- Tab content -->
            <div class="tab-content" id="myTabContent">
                <!-- Document search tab -->
                <div
                    class="tab-pane fade show active"
                    id="documents"
                    role="tabpanel"
                    aria-labelledby="documents-tab"
                >
                    <!-- Document search form -->
                    <form @submit.prevent="submitFilters">
                        <!-- Document search inputs -->
                        <div class="modal-body">
                            <!--  <div class="form-group">
                                <label for="documentName">Document Id: </label>
                                <input
                                    v-model="documentSearchCriteria.id"
                                    type="text"
                                    class="form-control"
                                    id="documentName"
                                />
                            </div> -->
                            <div class="form-group">
                                <label for="documentVersion">Version: </label>
                                <input
                                    v-model="documentSearchCriteria.version"
                                    type="text"
                                    class="form-control"
                                    id="documentVersion"
                                />
                            </div>
                            <div class="form-group">
                                <label for="folderName">Created by: </label>
                                <select
                                    class="form-control"
                                    id="userSelect"
                                    v-model="documentSearchCriteria.created_by"
                                >
                                    <option disabled value=""></option>
                                    <option
                                        v-for="user in users"
                                        :value="user.name"
                                        :key="user.id"
                                    >
                                        {{ user.name }}
                                    </option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label for="documentCreatedOn"
                                    >Created On:</label
                                >
                                <input
                                    v-model="
                                        documentSearchCriteria.created_date
                                    "
                                    type="date"
                                    class="form-control"
                                    id="documentCreatedOn"
                                />
                            </div>
                            <!--  <div class="form-group">
                                <label for="creation_date"
                                    >Creation date range:</label
                                >
                                <div class="input-group">
                                    <input
                                        class="form-control"
                                        type="date"
                                        v-model="
                                            documentSearchCriteria.startDate
                                        "
                                        placeholder="Start date"
                                    />
                                    <span class="input-group-addon addon-custom"
                                        >to</span
                                    >
                                    <input
                                        class="form-control"
                                        type="date"
                                        v-model="documentSearchCriteria.endDate"
                                        placeholder="End date"
                                    />
                                </div>
                            </div> -->
                        </div>
                        <div
                            class="modal-footer border-top-0 d-flex justify-content-right"
                        >
                            <button
                                type="button"
                                class="btn btn-secondary mx-5"
                                @click="closeModal()"
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                class="btn btn-primary"
                                id="serializerSubmitBtn"
                            >
                                Apply
                            </button>
                        </div>
                    </form>
                </div>

                <!-- Metadata search tab -->
                <div
                    class="tab-pane fade"
                    id="metadata"
                    role="tabpanel"
                    aria-labelledby="metadata-tab"
                >
                    <form @submit.prevent="searchMetadata">
                        <div class="modal-body">
                            <div class="form-group">
                                <label for="MetadataName">Value: </label>
                                <input
                                    v-model="metadataSearchCriteria.doc_value"
                                    type="text"
                                    class="form-control"
                                    id="MetadataName"
                                />
                            </div>
                            <div class="form-group">
                                <label for="MetadataName">Field Name: </label>
                                <input
                                    v-model="metadataSearchCriteria.field_name"
                                    type="text"
                                    class="form-control"
                                    id="folderUsername"
                                />
                            </div>
                            <div class="form-group">
                                <label for="MetadataName"
                                    >Field Datatype:</label
                                >
                                <input
                                    v-model="metadataSearchCriteria.data_type"
                                    type="text"
                                    class="form-control"
                                    id="folderEmail"
                                />
                            </div>
                        </div>
                        <div
                            class="modal-footer border-top-0 d-flex justify-content-right"
                        >
                            <button
                                type="button"
                                class="btn btn-secondary mx-5"
                                @click="closeModal()"
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                class="btn btn-primary"
                                id="serializerSubmitBtn"
                            >
                                Apply
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useToast } from "vue-toastification";

export default {
    data() {
        return {
            baseUrl: this.apiBaseUrl,
            toast: useToast(),
            documentSearchCriteria: {
                version: "",
                created_date: "",
                created_by: "",
            },
            authorSearchCriteria: {
                name: "",
                username: "",
                email: "",
            },
            metadataSearchCriteria: {
                doc_value: "",
                field_name: "",
                field_datatype: "",
            },
            users: [],
            selectedUser: "",
        };
    },
    mounted() {
        this.users = this.populateUsers();
        this.populateMetadata();
    },
    methods: {
        closeModal() {
            this.$emit("close-modal");
        },
        async populateUsers() {
            try {
                // Fetch user data from the API
                const response = await fetch(`${this.baseUrl}/users`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "edms_token"
                        )}`,
                        "Content-Type": "application/json",
                    },
                });
                const data = await response.json();
                // Process the fetched data and update the users array
                this.users = data.data.data;
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        },
        async populateMetadata(target_folder) {
            console.log(target_folder);
            // try {
            //     // Fetch user data from the API
            //     const response = await fetch(
            //         `${this.baseUrl}/folder/${target_folder}/documents`,
            //         {
            //             headers: {
            //                 Authorization: `Bearer ${localStorage.getItem(
            //                     "edms_token"
            //                 )}`,
            //                 "Content-Type": "application/json",
            //             },
            //         }
            //     );
            //     const data = await response.json();
            //     console.log(data);
            // } catch (error) {
            //     console.error("Error fetching user data:", error);
            // }
        },
        submitFilters() {
            // console.log(this.documentSearchCriteria.createdOn);
            // console.log(this.documentSearchCriteria.folder);

            this.$emit("applyDocumentFilters", this.documentSearchCriteria);
            this.closeModal();
        },
        searchMetadata() {
            this.$emit("applyMetadataFilters", this.metadataSearchCriteria);
            this.closeModal();
        },
        searchAuthor() {
            this.$emit("applyAuthorFilters", this.authorSearchCriteria);
            this.closeModal();
        },
    },
};
</script>

<style scoped>
.addon-custom {
    padding: 6px 12px;
}
</style>
