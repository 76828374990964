<template>
  <div class="modal fade show" style="display: block;" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Add Event</h5>
          <button type="button" class="close" @click="$emit('close-modal')" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
           <div class="form-group">
            <label for="event-description">Event</label>
            <input type="text" id="event-description" class="form-control" v-model="eventDescription" required placeholder="Enter Event">
          </div>
          <div class="form-group">
            <label for="event-date">Start Date</label>
            <input
              v-model="eventStartDate"
              type="date"
              class="form-control"
              id="event-date"
              name="date"
              required
            />
          </div>
           <div class="form-group">
            <label for="event-enddate">End Date</label>
            <input
              v-model="eventEndDate"
              type="date"
              class="form-control"
              id="event-end-date"
              name="date"
            />
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="$emit('close-modal')">Close</button>
          <button type="button" class="btn btn-primary" @click="handleAddEvent">Save changes</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits } from 'vue';

const emit = defineEmits(['close-modal', 'add-event']);

const eventStartDate = ref('');
const eventEndDate = ref('');
const eventDescription = ref(null);

const handleAddEvent = () => {
  if (eventStartDate.value && eventDescription.value) {
    emit('add-event', {
      eventStartDate: eventStartDate.value,
      eventEndDate: eventEndDate.value,
      eventDescription: eventDescription.value,
    });
    emit('close-modal'); 
  }
};

</script>

<style scoped>
.modal-backdrop {
  display: none;
}
</style>
