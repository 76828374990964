<!-- Edit Action Modal Component -->
<template>
    <!-- Your edit action modal template content -->
    <div id="myModal" class="modalIn">
        <!-- Modal content -->
        <div class="modalIn-content-right" style="float: right">
            <span class="close" @click="closeModal">&times;</span>
            <h2 id="modalTitle" class="modalIn-title">Edit Action</h2>

            <!-- Form for editing action -->
            <form v-on:submit.prevent="editActionFunc">
                <!-- Action Name -->
                <div class="form-group">
                    <label for="name">Action Name</label>
                    <input
                        type="text"
                        class="form-control"
                        id="name"
                        placeholder="Add Action Name"
                        name="action_name"
                        required
                        v-model="editedAction.name"
                    />
                </div>

                <!-- Description -->
                <div class="form-group">
                    <label for="description">Description</label>
                    <input
                        type="text"
                        class="form-control"
                        id="description"
                        placeholder="Add Description"
                        name="description"
                        required
                        v-model="editedAction.description"
                    />
                </div>

                <!-- Workstep ID (Hidden) -->
                <input
                    type="text"
                    id="workstep_id"
                    name="workstep_id"
                    hidden
                    v-model="editedAction.workstep_id"
                />

                <!-- Next Workstep -->
                <div class="form-group">
                    <label for="next_workstep_id">Select Next WorkStep</label>
                    <select
                        class="form-control"
                        id="next_workstep_id"
                        name="next_workstep_id"
                        required
                        v-model="editedAction.next_workstep_id"
                    >
                        <option disabled value="null">
                            Select Next WorkStep
                        </option>
                        <option
                            v-for="workstep in worksteps"
                            :key="workstep.id"
                            :value="workstep.id"
                        >
                            {{ workstep.activity }} --
                            {{ workstep.description }}
                        </option>
                    </select>
                </div>

                <!-- Button Color -->
                <div class="form-group">
                    <label for="button_color">Button Color</label>
                    <input
                        type="color"
                        class="form-control"
                        id="button_color"
                        name="button_color"
                        v-model="editedAction.button_color"
                        required
                    />
                </div>

                <!-- Save button -->
                <div style="text-align: right">
                    <button type="submit" class="btn btn-success">
                        Save Changes
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { useToast } from "vue-toastification";
import axios from "axios";

export default {
    props: {
        action: {
            type: Object,
            required: true,
        },
        worksteps: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            toast: useToast(),
            baseUrl: this.baseApiUrl,
            token: localStorage.getItem("edms_token"),
            editedAction: {},
        };
    },
    mounted() {
        this.editedAction = { ...this.action };
    },
    methods: {
        async editActionFunc() {
            const response = await axios.post(
                `/workstep/possibleactions/update/${this.editedAction.id}`,
                this.editedAction,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "edms_token"
                        )}`,
                        "Content-type": "application/json",
                    },
                }
            );
            const data = await response.dat;
            console.log(data);
            if (response.ok) {
                this.toast.success(data.message, {
                    timeout: 3000,
                });
            }
            this.closeModal();
        },
        closeModal() {
            // Emit an event to inform the parent component to close the modal
            this.$emit("close-modal");
        },
    },
};
</script>

<style scoped>
/* Your component styles */
</style>
