<template>
    <div>
        <table class="table table-striped table-sm">
            <thead class="thead-dark">
                <tr>
                    <th scope="col">Workstep</th>
                    <th scope="col">Action</th>
                    <th scope="col">User</th>
                    <th scope="col">Date & Time</th>
                    <th scope="col">Notes</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="record in history" :key="record.id">
                    <td>{{ record.workstep.activity }}</td>
                    <td>{{ record.value }}</td>
                    <td>{{ record.user.name }}</td>
                    <td>{{ new Date(record.updated_at).toLocaleString() }}</td>
                    <td>{{ record.notes }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
  
<script>

import { useToast } from "vue-toastification";
import axios from "axios";
export default {
    // register the component
    components: {},
    props: {
        document: {},
        current_folder: {},
    },
    data() {
        return {
            toast: useToast(),
            token: localStorage.getItem("edms_token"),
            history: [],
        };
    },
    computed: {

    },
    mounted() {

    },
    watch: {
        '$props.document': {
            immediate: true, // Execute the handler immediately after component is created
            handler(document) {
                this.history = [];
                this.$progress.start();
                if (document != null) {
                    this.getHistory(document);
                }
                this.$progress.finish();
            },
        },
    },
    methods: {
        async getHistory(document) {
            const response = await axios.get('/workstep/' + document.id + '/workstepresults', {
                headers: {
                    'Authorization': `Bearer ${this.token}`,
                    'Content-type': 'application/json'
                }
            });
            const data = await response.data;
            console.log(data);
            if (data.success == false) {
                // Use it!
                this.toast.error(data.message, {
                    timeout: 2000
                });
            } else {
                // Use it!
                this.toast.success(data.message, {
                    timeout: 2000
                });
                this.history = data.data.data;
            }
        },
    },
};
</script>
  
<style scoped></style>
  