<template>
    <vue3-progress />
    <!-- <Login /> -->
    <PageLayout />
</template>

<script>
import PageLayout from "@/components/PageLayout.vue";
// import Login from "@/views/LoginView.vue";
import { useAuth } from "@/stores/auth";
// import axios from "axios";
// import { ref } from "vue";

export default {
    name: "HomeView",
    components: {
        PageLayout,
        // Login,
    },
    data() {
        return {
            authStore: useAuth(),
            // showLogin: ref(false),
        };
    },
    async mounted() {
        this.$progress.start();
        await this.authStore.checkUserStatus();
        const token = localStorage.getItem("edms_token");
        if (token) {
            this.authStore.token = token;
        }
        this.$progress.finish();
    },
    async created() {
        this.$progress.start();
        // await this.authStore.checkUserStatus();
        // const token = localStorage.getItem("edms_token");
        // if (token) {
        //     this.authStore.token = token;
        // }
        this.$router.beforeEach((to, from, next) => {
            this.$progress.start();
            next();
        });

        this.$router.afterEach(() => {
            this.$progress.finish();
        });
    },
    methods: {},
};
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
}
</style>
