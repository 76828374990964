<template>
	<div class="x y">
		<!-- Content of Registered Cabinets -->
		<div class="card elevation-3">
			<div class="card-header">
				<h3 class="card-title"><b>{{ repositoryStore.current_folder ? repositoryStore.current_folder.name : ""
						}}</b></h3>
			</div>
			<div class="card-body table-responsive">
				<div class="row">
					<div class="col-md-12">
					<div class="d-flex justify-content-between mb-4 align-items-center">
                        <div></div>
                        <div class="d-flex align-items-center justify-content-end text-sm font-weight-bold justify-content-between"
                                style="margin-left: 150px">
                                <!-- search bar -->
                                <SearchForm @search="handleSearch" />
                            </div>
                        </div>
					</div>
				</div>
				<div v-if="repositoryStore.display_thumbnails && repositoryStore.display_thumbnails.length" class="row">
					<div v-for="(thumbnail, index) in repositoryStore.display_thumbnails" :key="thumbnail.id"
						class="column-md-3 thumbnail-container" @click="highlistItem(thumbnail, index)">
                        <template v-if="authStore.allowedAccess('view_documents', null, thumbnail)">
                            <img :src="thumbnail.dataUrl" alt="Thumbnail" style="width: 150px; height: 200px; margin: 2px;"
                                 :class="{ 'thumbnail-active': isActive == index }"
                                 @contextmenu.prevent="showContextMenu($event, thumbnail, index)" />
                            <span v-if="repositoryStore.bookmark_documents.includes(thumbnail.id)"
                                  class="material-symbols-outlined corner-icon" style="font-size:20px;">stars</span>
                            <center>
                                <span>{{ thumbnail.document_name }}</span>
                            </center>
                            <center>
                                <span>{{ thumbnail.size }} MB</span>
                            </center>
                            <center>
                                <span>{{ thumbnail.updated_at }}</span>
                            </center>
                            <center>
                                <span>Document Version: {{ thumbnail.version }}</span>
                            </center>
                        </template>
					</div>
				</div>
				<!-- Overlay to close the menu -->
				<div class="context_overlay" @click="closeContextMenu" v-if="showMenu" />

				<!-- Custom Context Menu -->
				<ContextMenu v-if="showMenu" :actions="contextMenuActions" @action-clicked="handleActionClick"
					:x="menuX" :y="menuY" />
			</div>
            <div class="card-footer clearfix">
                <ul class="pagination pagination-sm m-0 float-right">
                    <li class="page-item">
                        <a class="page-link" href="#"
                            @click="repositoryStore.refreshData(repositoryStore.current_folder.id, repositoryStore.pagination.meta.current_page == 1 ? repositoryStore.pagination.meta.current_page : repositoryStore.pagination.meta.current_page - 1)"
                            :disabled="repositoryStore.pagination.meta.current_page == 1">&laquo;</a>
                    </li>
                    <li class="page-item">
                        <a class="page-link" href="#"
                            @click="repositoryStore.refreshData(repositoryStore.current_folder.id, 1)"
                            :disabled="repositoryStore.pagination.meta.current_page == 1">First</a>
                    </li>
                    <span class="page-item page-link">Page {{ repositoryStore.pagination.meta.current_page }} of
                        {{ repositoryStore.pagination.meta.last_page }}</span>
                    <li class="page-item">
                        <a class="page-link disabled" href="#"
                            @click="repositoryStore.refreshData(repositoryStore.current_folder.id, repositoryStore.pagination.meta.last_page)">Last</a>
                    </li>
                    <li class="page-item">
                        <a class="page-link" href="#"
                            @click="repositoryStore.refreshData(repositoryStore.current_folder.id, repositoryStore.pagination.meta.current_page ==
                        repositoryStore.pagination.meta.last_page ? repositoryStore.pagination.meta.current_page : repositoryStore.pagination.meta.current_page + 1)">&raquo;</a>
                    </li>
                </ul>
            </div>
		</div>
	</div>
	<EditDocument :editDocument="editDocument" v-if="showEditDocument" @close-modal="closeModalHandler"
		:folders="repositoryStore.folders" @get-folder="repositoryStore.refreshData"
		:current_folder="repositoryStore.current_folder" />

</template>

<script setup lang="js">
// import { inject } from 'vue'
import { ref, watch, onMounted } from 'vue';
import "vue3-pdf-app/dist/icons/main.css";
import EditDocument from '@/components/modals/EditDocument.vue';
import ContextMenu from '@/components/ContextMenu.vue';
import SearchForm from "@/components/SearchForm.vue";
import { useRepository } from "@/stores/repository";
import axios from "axios";
import {useAuth} from "@/stores/auth";

const authStore = useAuth();

const repositoryStore = useRepository();


const baseUrl = `${axios.defaults.baseURL}`;
const showMenu = ref(false);
const menuX = ref(0);
const menuY = ref(0);
const targetRow = ref({});
const contextMenuActions = ref([]);
const isActive = ref(null);
const editDocument = ref({});
const showEditDocument = ref(false);

function openItem(item) {
	isActive.value = item.id;
	let path = baseUrl + '/showPdf/' + item.path.split('/')[2];
	let showViewer = true;
    repositoryStore.renderPdfViewer(path, showViewer);
}

function highlistItem(document, index) {
	isActive.value = index;
	repositoryStore.updateSelectDocument(document);
}

function showContextMenu(event, document, index) {
	event.preventDefault();
	showMenu.value = true;
	targetRow.value = document;
	menuX.value = event.clientX - 430;
	menuY.value = event.clientY - 180;
	isActive.value = index;
}

function closeContextMenu() {
	showMenu.value = false;
}

function handleActionClick(action) {
	closeContextMenu();
	switch (action) {
		case "open":
			openItem(targetRow.value);
			highlistItem(targetRow.value);
			break;
		case "bookmark":
			repositoryStore.toggleBookmark(targetRow.value);
			break;
		case "edit":
			editDocument.value = targetRow.value;
			showEditDocument.value = true;
			break;
		case "delete":
			repositoryStore.deleteDocument(targetRow.value);
			break;
		case "properties":
			openItem(targetRow.value);
			repositoryStore.showProperties("document")
			highlistItem(targetRow.value);
			break;
		// Add cases for other tabs
		default:
			break;
	}
}

function handleSearch(){
    repositoryStore.searchTerm = event.target.value
}

function closeModalHandler() {
	// Update the prop to close the modal
	showEditDocument.value = false;
}


watch(() => repositoryStore.documents, () => {
    repositoryStore.parseDocuments();
}, { immediate: true });

// const display_thumbnails = () => {
// 	let filteredRows = repositoryStore.parseDocuments(repositoryStore.documents);
// 	console.log(filteredRows);
// 	// let filteredRows = repositoryStore.thumbnails;
// 	const searchTermLowerCase = repositoryStore.searchTerm.toLowerCase();
// 	if (repositoryStore.searchTerm) {
// 		filteredRows = filteredRows.filter(row =>
// 			Object.values(row).some(value => value.toString().toLowerCase().includes(searchTermLowerCase))
// 		);
// 	}

// 	if (repositoryStore.sortedColumn) {
// 		filteredRows = _.orderBy(filteredRows, [repositoryStore.sortedColumn], [repositoryStore.sortOrder]);
// 	}

// 	return filteredRows;
// };

onMounted(async () => {
	repositoryStore.fetchDocumentBookmarks();
    if (await authStore.allowedAccess("view_documents")) {
        contextMenuActions.value.push({label: "Open", action: "open"})
    }
    if (await authStore.allowedAccess("edit_documents")) {
        contextMenuActions.value.push({label: 'Edit', action: 'edit'})
    }
    if (await authStore.allowedAccess("delete_documents")) {
        contextMenuActions.value.push({label: 'Delete', action: 'delete'})
    }
    if (await authStore.allowedAccess("view_document_properties")) {
        contextMenuActions.value.push({label: 'Properties', action: 'properties'})
    }
    contextMenuActions.value.push({label: 'Bookmark', action: 'bookmark'})
});

</script>

<style scoped>
.context_overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: transparent;
	z-index: 49;
}

.context_overlay::before {
	content: '';
	position: absolute;
	width: 100%;
	height: 100%;
}

/* .context_overlay:hover {
  cursor: pointer;
} */
.comparision_icon {
	-webkit-transform: rotate(90deg);
	-moz-transform: rotate(90deg);
	-o-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
}

.thumbnail-active {
	border: 1px solid gray;
}

/* Style for the icon */
.corner-icon {
	position: absolute;
	top: 0;
	/* Adjust top position */
	right: 0;
	/* Adjust right position */
	width: 30px;
	/* Adjust icon width */
	height: 30px;
	/* Adjust icon height */
	/* Add any additional styling as needed */
}

.thumbnail-container {
	position: relative;
	display: inline-block;
	/* Make sure it stays inline with the text */
}
</style>
