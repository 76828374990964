<template>
    <div class=" ml-3" :style="{ width: repositoryStore.openProperties ? '95%' : '100%' }">
        <div class="mr-5">
            <div class="">
                <!-- <ListView  v-if="casesStore.showList"/>
                <GridView v-if="casesStore.showThumbails"/> -->
                <ListView v-if="repositoryStore.listview" />
                <GridView v-if="repositoryStore.gridview" />
                <DocumentViewer :actual_file="repositoryStore.actual_file" v-if="repositoryStore.showViewer" />
            </div>
        </div>
    </div>
    <PropertiesSideBar v-if="repositoryStore.openProperties" @close-sidebar="repositoryStore.hideSidebar()"
        :style="{ width: repositoryStore.openProperties ? '25%' : '0%' }" />
</template>
<script setup lang="js">
import GridView from "@/components/GridView.vue";
import ListView from "@/components/ListView.vue";
import PropertiesSideBar from "@/components/PropertiesSideBar.vue";
import DocumentViewer from "@/components/DocumentViewer.vue";
import { useRepository } from '@/stores/repository';
// import SearchView from "@/components/SearchView.vue";
import { useCases } from '@/stores/cases';
import { onMounted } from 'vue';
const repositoryStore = useRepository();
const casesStore = useCases();

onMounted(() => {
    repositoryStore.getFolders(casesStore.targetCase.folder.id, 1, {});
    repositoryStore.getDocuments(casesStore.targetCase.folder.id, 1, {});
})

</script>

<style scoped>
.context_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    z-index: 49;
}

.context_overlay::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
}

/* .context_overlay:hover {
  cursor: pointer;
} */
.comparision_icon {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}

.form-control {
    padding-left: 2.375rem;
}

.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
}
</style>
