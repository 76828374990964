<script setup lang="js">
import { useNotification } from "@/stores/notification";

const notificationStore = useNotification();

</script>

<template>
    <div class="mr-3">
        <div style="cursor: pointer;" v-for="notification in notificationStore.read_notifications" :key="notification.id">
            <div  class="card text-left" @click="notificationStore.openNotificationTarget(notification)">
                <div  class="card-header row">
                    <span class="mt-0 col-8">
                        {{ notification.title }}
                    </span>
                    <span class="badge badge-primary col-4"
                          :class="notification.urgency <= '2' ? 'badge-primary' : notification.urgency == '3' ? 'badge-warning' : 'badge-danger'">
                        {{ notification.urgency == '1' ? "Low" : notification.urgency == '2' ? "Normal" : notification.urgency == '3' ? "Medium" : "High" }}
                    </span>
                </div>
                <div class="card-body">
                    <p class="card-text">{{ notification.content }}</p>
                </div>
                <div class="card-footer text-muted">
                    {{ new Date(notification.created_at).toLocaleString() }}
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
