<template>
    <div id="myModal" class="modalIn">
        <div class="modalIn-content-right">
            <span class="close" @click="closeModal()">&times;</span>
            <p id="modalTitle" class="modalIn-title">
                Add User To {{ addGroup.group_name }} Team
            </p>

            <!-- Form for adding a user -->
            <div class="modal-body">
                <!-- Select User -->
                <div class="form-group">
                    <select class="form-control mb-2 mr-sm-2" v-model="selectedUserId" required>
                        <option disabled value="null">Select User</option>
                        <option v-for="user in users" :key="user.id" :value="user.id">
                            {{ user.name }}
                        </option>
                    </select>
                </div>
                <!-- Save button -->
                <button type="submit" class="btn btn-primary" @click="addUser">
                    Add User
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { useToast } from "vue-toastification";
import axios from "axios";

export default {
    props: {
        group: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            toast: useToast(),
            baseUrl: this.baseApiUrl,
            token: localStorage.getItem("edms_token"),
            selectedUserId: null,
            users: [],
            addGroup: {},
        };
    },
    mounted() {
        // Fetch users when the modal is mounted
        this.fetchUsers();
        this.addGroup = this.group;
    },
    methods: {
        async fetchUsers() {
            try {
                // Fetch users from the API
                const response = await axios.get(`/users`, {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${this.token}`,
                    },
                });
                const data = await response.data;
                const allUsers = data.data.data;

                this.users = allUsers;
            } catch (error) {
                console.error("Error fetching users:", error);
            }
        },

        async addUser() {
            try {
                const response = await axios.post(
                    `groupmemberships/store`,
                    {
                            user_id: this.selectedUserId,
                            group_id: this.group.id,
                        },
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                                "edms_token"
                            )}`,
                            "Content-Type": "application/json",
                        },
                    }
                );

                if (!response.ok) {
                    const errorData = await response.data;
                    throw new Error(
                        errorData.message || "Failed to add user to group"
                    );
                } else {
                    this.$emit('refresh-data', this.addGroup.group_admin_id);
                    console.log(this.addGroup.group_admin_id);
                    this.$emit('close-modal');
                    console.log("GroupMembership added successfully");
				// Use it!
                    this.toast.success("User added successfully", {
                        timeout: 5000
                    });
                }
            } catch (error) {
                console.error("Error assigning group:", error);
                this.toast.error(error.message);
            }
        },

        closeModal() {
            // Emit an event to inform the parent component to close the modal
            this.selectedUserId = null;
            this.$emit("close-modal");
        },
    },
};
</script>

<style scoped>
.toggle-on {
    color: blue !important;
}

.toggle-off {
    color: #8b4513 !important;
}
</style>
