<template>
    <div class="card-header row">
        <h3 class="card-title col-md-9"><b>List of Worksteps</b></h3>
        <p
            v-if="authStore.allowedAccess('create_workflow')"
            class="new"
            @click="showAddWorkflowModal(current_folder)"
        >
            <span class="material-symbols-outlined" style="float: left"
                >add</span
            >
        </p>
    </div>
    <div id="inner_accordion">
        <div class="card m-2" v-for="(workstep, ind) in worksteps" :key="ind">
            <div
                class="card-header row"
                :id="'heading_inner' + ind"
                v-if="!workstep.is_last"
            >
                <div class="col-md-10">
                    <!-- <b>Folder: </b><span class="mr-5">{{ workstep.folder.name }}</span>
                    <b>Workstep Type: </b><span class="mr-5">{{ workstep.workstep_type }}</span> -->
                    <span>
                        <h5>
                            <b>Activity: </b
                            ><span class="mr-2">{{ workstep.activity }}</span>
                        </h5>
                        <p>
                            <b>Description: </b
                            ><span class="mr-2">{{
                                workstep.description
                            }}</span>
                        </p>
                    </span>
                    <div class="text-left" style="cursor: pointer">
                        <span
                            @click="showEditWorkflowModal(workstep)"
                            class="material-symbols-outlined mr-4 text-primary"
                            >edit</span
                        >
                        <span
                            @click="showDeleteWorkflowModal(workstep)"
                            class="material-symbols-outlined text-danger"
                            >delete</span
                        >
                    </div>
                    <!-- <b>Updated At: </b><span class="mr-5">{{ workstep.updated_at }}</span> -->
                </div>
                <h5 class="mb-0 col-md-2">
                    <button
                        class="btn"
                        data-toggle="collapse"
                        :data-target="'#inner' + ind"
                        aria-expanded="false"
                        :aria-controls="ind"
                    >
                        <span
                            class="material-symbols-outlined col-md-2 mr-1 pr-0"
                            style="border: none"
                            >arrow_drop_down</span
                        >
                    </button>
                </h5>
            </div>
            <div
                :id="'inner' + ind"
                class="collapse"
                :aria-labelledby="'heading_inner' + ind"
                data-parent="#inner_accordion"
            >
                <div class="row">
                    <p class="col-md-9" style="margin-top: 15px; left: 10px">
                        <b>List of Actions</b>
                    </p>
                    <p
                        class="new"
                        @click="showAddActionModal(workstep)"
                        style="top: 15px; margin-top: 10px"
                    >
                        <span
                            class="material-symbols-outlined"
                            style="float: left"
                            >add</span
                        >
                    </p>
                </div>
                <div class="card-body">
                    <div class="permissions-table">
                        <div
                            v-for="(action, index) in workstep.possible_actions"
                            :key="index"
                            class="action-item"
                        >
                            <div class="action-container">
                                <i>
                                    <span class="material-symbols-outlined"
                                        >check</span
                                    >
                                </i>
                                <span>
                                    <b>Action: </b
                                    ><span class="mr-4">{{ action.name }}</span>
                                    <b>Next: </b
                                    ><span>{{
                                        action.next_workstep.activity
                                    }}</span>
                                </span>
                            </div>
                            <div class="action-icons">
                                <i>
                                    <span
                                        @click="showEditActionModal(action)"
                                        class="material-symbols-outlined text-primary"
                                        style="cursor: pointer"
                                        >edit</span
                                    >
                                </i>
                                <i>
                                    <span
                                        @click="
                                            showDeleteActionModal(
                                                workstep,
                                                action
                                            )
                                        "
                                        class="material-symbols-outlined text-danger"
                                        style="cursor: pointer"
                                        >delete</span
                                    >
                                </i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <AddAction
        v-if="showAddAction"
        @close-modal="closeModalHandler"
        :worksteps="worksteps"
        @update-actions="refreshData"
        :targetWorkstep="targetWorkstep"
    />
    <AddWorkstep
        v-if="showAddWorkflow"
        @close-modal="closeModalHandler"
        :worksteps="worksteps"
        @update-actions="refreshData"
        :current_folder="current_folder"
    />
    <EditWorkstep
        v-if="showEditWorkflow"
        @close-modal="closeModalHandler"
        :targetWorkstep="selectedWorkstep"
        :worksteps="worksteps"
        @workstep-updated="refreshData"
        :current_folder="current_folder"
    />

    <EditAction
        v-if="showEditAction"
        @close-modal="closeModalHandler"
        :action="selectedAction"
        :worksteps="worksteps"
    />
</template>

<script>
import { useToast } from "vue-toastification";
import AddAction from "@/components/modals/AddAction.vue";
import AddWorkstep from "@/components/modals/AddWorkstep.vue";
import EditWorkstep from "@/components/modals/EditWorkstep.vue";
import { useAuth } from "@/stores/auth";
import EditAction from "@/components/modals/EditPossibleAction.vue";
import axios from "axios";
export default {
    // register the component
    components: {
        AddAction,
        AddWorkstep,
        EditWorkstep,
        EditAction,
    },
    props: {
        document: {},
        current_folder: {},
    },
    data() {
        return {
            toast: useToast(),
            baseUrl: this.baseApiUrl,
            token: localStorage.getItem("edms_token"),
            worksteps: [],
            selectedWorkstep: {},
            // workstep_id: '',
            // document_id: '',
            // action_id: '',
            // value: '',
            showAddAction: false,
            showAddWorkflow: false,
            showEditWorkflow: false,
            showDeleteWorkflow: false,
            showEditAction: false,
            showDeleteAction: false,
            targetWorkstep: {},
            selectedAction: {},
            authStore: useAuth(),
        };
    },
    computed: {},
    mounted() {
        this.selectedWorkstep = this.worksteps;
    },
    watch: {
        "$props.current_folder": {
            immediate: true, // Execute the handler immediately after component is created
            handler(current_folder) {
                this.worksteps = [];
                this.$progress.start();
                if (current_folder != null) {
                    this.getWorksteps(current_folder.id);
                }
                this.$progress.finish();
            },
        },
    },
    methods: {
        async getWorksteps(target_folder) {
            const response = await axios.get(
                "/folder/" + target_folder + "/worksteps",
                {
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                        "Content-type": "application/json",
                    },
                }
            );
            const data = await response.data;
            // console.log(data);
            if (data.success == false) {
                // Use it!
                this.toast.error(data.message, {
                    timeout: 2000,
                });
            } else {
                this.worksteps = data.data.data;
            }
        },
        // async addWorkstepResult(possible_action) {
        //     // Implement logic to handle editing the indexing field
        //     this.workstep_id = possible_action.workstep_id;
        //     this.document_id = this.document.id;
        //     this.action_id = possible_action.id;
        //     this.value = possible_action.name;
        //     const response = await fetch(this.baseUrl + '/api/workstep/workstepresults/store', {
        //         method: 'POST',
        //         headers: {
        //             'Authorization': `Bearer ${this.token}`,
        //             'Content-type': 'application/json'
        //         },
        //         body: JSON.stringify({
        //             workstep_id: this.workstep_id,
        //             document_id: this.document_id,
        //             action_id: this.action_id,
        //             value: this.value,
        //         })
        //     });
        //     const data = await response.json();
        //     if (data.data == 403) {
        //         // Use it!
        //         this.toast.error(data.message, {
        //             timeout: 5000
        //         });
        //     } else {
        //         // Use it!
        //         this.toast.success(data.message, {
        //             timeout: 5000
        //         });
        //         this.$emit('get-folder', data.data.data.workstep.folder_id);
        //     }
        // },
        closeModalHandler() {
            // Update the targetModal prop to close the modal
            this.showAddAction = false;
            this.showAddWorkflow = false;
            this.showEditWorkflow = false;
            this.showDeleteWorkflow = false;
            this.showEditAction = false;
            this.showDeleteAction = false;
        },
        showAddActionModal(workstep) {
            this.targetWorkstep = workstep;
            this.showAddAction = true;
        },
        showAddWorkflowModal() {
            this.showAddWorkflow = true;
        },

        showEditWorkflowModal(workstep) {
            this.selectedWorkstep = workstep;
            this.showEditWorkflow = true;
        },

        showDeleteWorkflowModal(workstep) {
            this.$swal
                .fire({
                    title: "Are you sure?",
                    text: "Once deleted, you will not be able to recover this WorkStep and All It's Contents!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Yes, delete it!",
                    cancelButtonText: "No, cancel!",
                    reverseButtons: true,
                    showLoaderOnConfirm: true,
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        axios.get(
                                "/folder/worksteps/delete/" +
                                workstep.id,
                            {
                                headers: {
                                    Authorization: `Bearer ${this.token}`,
                                    "Content-type": "application/json",
                                },
                            }
                        )
                            .then((response) => response.data)
                            .then((response_data) => {
                                if (response_data.data == 403) {
                                    // Use it!
                                    this.toast.error(response_data.message, {
                                        timeout: 5000,
                                    });
                                } else {
                                    // Use it!
                                    this.toast.success(response_data.message, {
                                        timeout: 5000,
                                    });
                                    this.refresh(
                                        response_data.data.data.folder_id
                                    );
                                }
                            });
                        // console.log(response);
                        // const data = response.json();
                    } else {
                        this.toast.success("Meta Data Field is safe!", {
                            timeout: 5000,
                        });
                    }
                });
        },

        showEditActionModal(action) {
            this.selectedAction = action;
            this.showEditAction = true;
        },

        showDeleteActionModal(workstep, action) {
            this.$swal
                .fire({
                    title: "Are you sure?",
                    text: "Once deleted, you will not be able to recover this WorkStep and All It's Contents!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Yes, delete it!",
                    cancelButtonText: "No, cancel!",
                    reverseButtons: true,
                    showLoaderOnConfirm: true,
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        axios.get(
                                "/folder/worksteps/delete/" +
                                action.id,
                            {
                                headers: {
                                    Authorization: `Bearer ${this.token}`,
                                    "Content-type": "application/json",
                                },
                            }
                        )
                            .then((response) => response.data)
                            .then((response_data) => {
                                if (response_data.data == 403) {
                                    // Use it!
                                    this.toast.error(response_data.message, {
                                        timeout: 5000,
                                    });
                                } else {
                                    // Use it!
                                    this.toast.success(response_data.message, {
                                        timeout: 5000,
                                    });
                                    this.refresh(workstep.folder_id);
                                }
                            });
                        // console.log(response);
                        // const data = response.json();
                    } else {
                        this.toast.success("Meta Data Field is safe!", {
                            timeout: 5000,
                        });
                    }
                });
        },

        refreshData(target_folder) {
            this.getWorksteps(target_folder);
        },
    },
};
</script>

<style scoped>
.action-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
}

.action-icons {
    display: flex;
    align-items: center;
}
.action-container {
    display: flex;
    align-items: center;
    left: 10px;
}

.action-icons i {
    margin-left: 5px;
}
.new {
    cursor: pointer;
    font-weight: bolder;
    border-radius: 3px;
    padding: 3px 15px;
}
.new:hover {
    background-color: #355b11;
    color: white;
}
</style>
