<template>
    <div>
        <form
            v-if="authStore.allowedAccess('add_team')"
            class="form-inline"
            v-on:submit.prevent="createNewGroup"
        >
            <label class="sr-only">Group Name</label>
            <input
                class="form-control mb-2 mr-sm-2"
                v-model="newGroupName"
                placeholder="Team Name"
            />
            <button
                type="submit"
                name="createNewGroup"
                class="btn btn-primary mb-2"
            >
                Create Team
            </button>
        </form>

        <div id="accordion">
            <div class="card" v-for="(group, index) in groups" :key="index">
                <div class="card-header row" :id="'heading' + group.id">
                    <h5 class="mb-0 col-md-10">
                        <button class="btn">
                            {{ group.group_name }}
                        </button>
                    </h5>
                    <h5 class="mb-0 col-md-2">
                        <button
                            class="btn"
                            data-toggle="collapse"
                            :data-target="'#' + group.id"
                            aria-expanded="false"
                            :aria-controls="group.id"
                        >
                            <span
                                class="material-symbols-outlined col-md-2 mr-1 pr-0"
                                style="border: none"
                                >arrow_drop_down</span
                            >
                        </button>
                    </h5>
                </div>

                <div
                    :id="group.id"
                    class="collapse"
                    :aria-labelledby="'heading' + group.id"
                    data-parent="#accordion"
                >
                    <div class="card-header row" style="text-align: center">
                        <span
                            v-if="authStore.allowedAccess('add_users_to_team')"
                            @click="openModal('addUsers', group)"
                            class="material-symbols-outlined col-md-2 mr-1 pr-0"
                            style="
                                cursor: pointer;
                                color: blue;
                                margin-left: 15%;
                            "
                            >person_add</span
                        >
                        <span
                            v-if="authStore.allowedAccess('edit_team')"
                            @click="openModal('editGroup', group)"
                            class="material-symbols-outlined col-md-2 mr-1 pr-0"
                            style="
                                cursor: pointer;
                                color: green;
                                margin-left: 15%;
                            "
                            >edit</span
                        >
                        <span
                            v-if="authStore.allowedAccess('delete_team')"
                            @click="openModal('deleteGroup', group)"
                            class="material-symbols-outlined col-md-2 mr-1 pr-0"
                            style="
                                cursor: pointer;
                                color: red;
                                margin-left: 15%;
                            "
                            >delete</span
                        >
                    </div>

                    <div class="card-body">
                        <div class="permissions-table">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>User</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        v-for="(user, id) in group.users"
                                        :key="id"
                                    >
                                        <td>{{ id + 1 }}</td>
                                        <td>{{ user.name }}</td>
                                        <td>
                                            <a
                                                class="btn"
                                                @click="removeUser(group, user)"
                                            >
                                                <i
                                                    class="material-symbols-outlined col-md-2 mr-1 pr-0"
                                                    style="
                                                        cursor: pointer;
                                                        color: red;
                                                    "
                                                    >delete</i
                                                >
                                            </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal components -->
        <AddUserModal
            v-if="modalType === 'addUsers'"
            :group="selectedGroup"
            @close-modal="closeModal"
            @refresh-data="refreshData"
        />
        <EditGroupModal
            v-if="modalType === 'editGroup'"
            :group="selectedGroup"
            @close-modal="closeModal"
        />
    </div>
</template>

<script>
import { useToast } from "vue-toastification";
import AddUserModal from "./modals/AddUsermodal.vue";
import EditGroupModal from "./modals/EditGroupModal.vue";
import { useAuth } from "@/stores/auth";
import axios from "axios";

export default {
    components: {
        AddUserModal,
        EditGroupModal,
    },
    props: {
        current_folder: {},
    },
    data() {
        return {
            toast: useToast(),
            baseUrl: this.baseApiUrl,
            token: localStorage.getItem("edms_token"),
            permissions: null,
            groups: [],
            newGroupName: "",
            ignoredPermissionsColumn: [
                "id",
                "group_id",
                "folder_id",
                "created_at",
                "updated_at",
            ],
            modalType: "",
            selectedGroup: null,
            authStore: useAuth(),
        };
    },
    mounted() {
        this.getGroups(this.$props.current_folder.folder_owner_id);
        // this.getPermissions(this.$props.current_folder.id, 2);
    },
    methods: {
        async getGroups(group_admin_id) {
            const response = await axios.get("/groups/" + group_admin_id, {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${this.token}`,
                },
            });
            const data = await response.data;
            this.groups = data.data.data;
        },
        async removeUser(group, user) {
            try {
                const response = await axios.delete(
                    "/groupmemberships/delete",
                    {
                        user_id: user.id,
                        group_id: group.id,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${this.token}`,
                        },
                    }
                );
                if (!response.ok) {
                    const errorData = await response.data;
                    throw new Error(
                        errorData.message || "Failed to delete group membership"
                    );
                } else {
                    this.getGroups(group.group_admin_id);

                    // Use it!
                    this.toast.success("User removed successfully", {
                        timeout: 5000,
                    });
                    console.log("Group Membership deleted successsfully");
                }
            } catch (error) {
                console.error("Error toggling user:", error);
                this.toast.error(error.message);
            }
        },
        async createNewGroup() {
            if (this.newGroupName.trim() === "") {
                this.toast.error("Team name is required", {
                    timeout: 2000,
                });
                return;
            }
            const response = await axios.post(
                "/groups/store",
                {
                    group_name: this.newGroupName,
                    group_admin_id: this.$props.current_folder.folder_owner_id,
                },
                {
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                        "Content-type": "application/json",
                    },
                }
            );
            const data = await response.data;
            this.groups.push(data.data.data);

            // Use it!
            this.toast.success("Group Created successfully", {
                timeout: 5000,
            });
        },
        openModal(type, group) {
            this.modalType = type;
            this.selectedGroup = group;
            if (type === "deleteGroup") {
                {
                    this.$swal
                        .fire({
                            title: "Are you sure?",
                            text: "Once deleted, you will not be able to recover this Group and All It's Contents!",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonText: "Yes, delete it!",
                            cancelButtonText: "No, cancel!",
                            reverseButtons: true,
                            showLoaderOnConfirm: true,
                        })
                        .then((result) => {
                            if (result.isConfirmed) {
                                axios
                                    .get("/groups/delete/" + group.id, {
                                        headers: {
                                            Authorization: `Bearer ${this.token}`,
                                            "Content-type": "application/json",
                                        },
                                    })
                                    .then((response) => response.data)
                                    .then((response_data) => {
                                        if (response_data.data == 403) {
                                            // Use it!
                                            this.toast.error(
                                                response_data.message,
                                                {
                                                    timeout: 5000,
                                                }
                                            );
                                        } else {
                                            // Use it!
                                            this.toast.success(
                                                response_data.message,
                                                {
                                                    timeout: 5000,
                                                }
                                            );
                                            this.handleGroupDeleted();
                                        }
                                    });
                                // console.log(response);
                                // const data = response.data;
                            } else {
                                this.toast.success("Meta Data Field is safe!", {
                                    timeout: 5000,
                                });
                            }
                        });
                }
            }
        },
        handleGroupDeleted() {
            this.groups = this.groups.filter(
                (group) => group.id !== this.selectedGroup.id
            );
        },
        closeModal() {
            this.modalType = "";
            this.selectedGroup = null;
        },
        refreshData(group_admin_id) {
            this.getGroups(group_admin_id);
        },
    },
};
</script>

<style scoped>
.permissions-table {
    padding: 20px;
}

.table {
    width: 100%;
    border-collapse: collapse;
}

.table th,
.table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.table th {
    background-color: #f2f2f2;
}

.tooltip-container {
    position: relative;
}

.tooltip-text {
    visibility: hidden;
    width: auto;
    background-color: black;
    color: white;
    text-align: center;
    padding: 5px;
    border-radius: 5px;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip-container:hover .tooltip-text {
    visibility: visible;
    opacity: 1;
}
</style>
