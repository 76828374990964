<template>
    <div id="myModal" class="modalIn">
        <div class="modalIn-content-right">
            <span class="close" @click="closeModal()">&times;</span>
            <h2 id="modalTitle" class="modalIn-title">Update Meta Data Field</h2>
            <!--------------------------------------------------------------------CREATE NEW UPLOAD FILE-------------------------------------------------------------------->
            <form v-on:submit.prevent="editMetaDataFunc">
                <div class="form-group">
                    <label for="Field_name">Field Name</label>
                    <!-- <treeselect v-model="targetMetaData.field_id" :multiple="false" :clearable="true" :searchable="true"
                        :disabled="disabled" :open-on-click="true" :open-on-focus="true" :clear-on-select="true"
                        :close-on-select="true" :always-open="false" :append-to-body="appendToBody"
                        :options="fields_options" :limit="3" /> -->
                        <select class="form-control" v-model="targetMetaData.field_id">
                            <option disabled value="">Select</option>
                            <option v-for="field in fields_options" :key="field.id" :value="field.id">{{ field.field_name }}</option>
                        </select>
                </div>
                <div class="form-group">
                    <label for="value">Value</label>
                    <treeselect v-model="targetMetaData.value" :multiple="false" :clearable="true" :searchable="true"
                        :disabled="disabled" :open-on-click="true" :open-on-focus="true" :clear-on-select="true"
                        :close-on-select="true" :always-open="false" :append-to-body="appendToBody"
                        :options="lookup_options" :limit="3" v-if="showLookupSelector" />
                    <input v-model="targetMetaData.value" class="form-control" type="text" placeholder="Serial No."
                        v-else-if="showSerialSelector">
                    <input v-model="targetMetaData.value" class="form-control" type="datetime-local" placeholder="value"
                        v-else-if="showDateSelector">
                    <input v-model="targetMetaData.value" class="form-control" type="text" placeholder="value" v-else>
                </div>
                <button type="submit" class="btn btn-success" name="addItem">Update</button>
            </form>
            <!--------------------------------------------------------------------EDIT CABINET CONTENT-------------------------------------------------------------------->
        </div>
    </div>
</template>

<script>
import { useToast } from "vue-toastification";
// import Treeselect from "vue3-treeselect";
// import "vue3-treeselect/dist/vue3-treeselect.css";
// import the component
import Treeselect from "@zanmato/vue3-treeselect";
// import the styles
import "@zanmato/vue3-treeselect/dist/vue3-treeselect.min.css";
import axios from "axios";

export default {
    components: { Treeselect },
    props: {
        editMetaData: {
            type: Object,
            required: true,
        },
        fields_options: {
            type: Array,
            required: true,
        },
        lookup_options: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            targetMetaData: this.$props.editMetaData,
            toast: useToast(),
            baseUrl: this.baseApiUrl,
            token: localStorage.getItem("edms_token"),
            activeTab: 0,
        };
    },
    methods: {
        async editMetaDataFunc() {
            // Implement logic to handle editing the indexing field
            const response = await axios.post('/document/docfields/update/' + this.targetMetaData.id,
            {
                    ...this.targetMetaData,
                },
            {
                headers: {
                    'Authorization': `Bearer ${this.token}`,
                    'Content-type': 'application/json'
                },
            });
            const data = await response.dat;
            if (data.data == 403) {
                // Use it!
                this.toast.error(data.message, {
                    timeout: 5000
                });
            } else {
                // Use it!
                this.toast.success(data.message, {
                    timeout: 5000
                });
                this.$emit('update-meta-data-data', data.data);
            }
        },
        closeModal() {
            // Emit an event to inform the parent component to close the modal
            this.$emit('close-modal');
        }
    },
};

</script>

<style scoped>
.tab {
    cursor: pointer;
    padding: 10px;
}

.tab.active {
    background-color: #355B11;
    color: white;
}
</style>