<template>
    <div class="sidebar-right sidebar-mini layout-navbar-fixed layout-fixed text-sm sidebar-light-indigo elevation-4"
        id="sidebarRight">
        <!-- Add content for right sidebar here -->
        <div class="content y mx-3" style="padding: 2%">
            <div class="row">
                <div class="col-9">
                    <div>
                        <b style="font-size: 16px">
                            <u v-if="propertiesStore.focus == 'folder'">Folder Properties - {{ propertiesStore.current_folder.name
                                }}</u>
                            <u v-if="propertiesStore.focus == 'document' || propertiesStore.focus == 'task_document'">Document Properties - {{
                                    propertiesStore.document ?
                                        propertiesStore.document.document_name : "" }}</u>
                        </b>
                    </div>
                </div>
                <div class="col-3">
                    <div class="close" @click="emit('close-sidebar')">
                        &times;
                    </div>
                </div>
            </div>
        </div>
        <div class="container y mx-1" style="padding: 2%" id="dashboardPropertiesTabs">
            <div style="font-weight: bolder">
                <div class="y">
                    <div class="c" v-for="(tab, index) in tabs" :key="index">
                        <input class="g" :name="`dashboard_properties_tab`" :value="index" type="radio"
                            v-model="activeTab" />
                        <li class="x y rsbtab" @click="_tab(index)">
                            {{ tab.name }}
                        </li>
                    </div>
                    <div class="e"></div>
                </div>
            </div>
            <div class="x y ba">
                <div v-for="(tab, index) in tabs" :key="index">
                    <input class="g" :name="`dashboard_properties_tabv${index}`"
                        :type="activeTab === index ? 'radio' : 'hidden'" :checked="activeTab === index" />
                    <div class="rsbtabv x y">
                        <!-- Dynamically render content based on the selected tab -->
                        <template v-if="activeTab === index">
                            <component :is="tab.component" :document="propertiesStore.document"
                                :lookups="propertiesStore.lookups" :serials="propertiesStore.serials"
                                :current_folder="propertiesStore.current_folder"
                                @update-index-field-data="propertiesStore.updateIndexFieldData"
                                @update-meta-data-data="propertiesStore.updateMetaDataData"
                                @get-folder="repositoryStore.refreshData" />
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="js">
import MetaDataTab from "@/components/MetaDataTab.vue"; // Import your component for Meta Data
import MetaDataFieldsTab from "@/components/MetaDataFieldsTab.vue"; //Import your component for Index Fields
import TeamTab from "@/components/TeamTab.vue";
import WorkflowTab from "@/components/WorkflowTab.vue";
import HistoryTab from "@/components/HistoryTab.vue";
import AccessTab from "@/components/AccessTab.vue";
import ActionTab from "@/components/ActionTab.vue";
import { useRepository } from '@/stores/repository';
import { useProperties } from "@/stores/properties";
import { ref, onMounted, computed, defineEmits } from "vue";
import {useAuth} from "@/stores/auth";

const authStore = useAuth();

const repositoryStore = useRepository();
const propertiesStore = useProperties();
let activeTab = ref(0);
const emit = defineEmits(['close-sidebar']);
let document_tabs = ref([
    // { name: "Meta Data", component: MetaDataTab },
    // { name: "History", component: HistoryTab },
    // { name: "Access", component: AccessTab },
    // Add more tabs as needed
]);
let task_document_tabs = ref([
    // { name: "Meta Data", component: MetaDataTab },
    // { name: "Action", component: ActionTab },
    // { name: "History", component: HistoryTab },
    // Add more tabs as needed
]);
let folder_tabs = ref([
    // { name: "Meta Data Fields", component: MetaDataFieldsTab },
    // { name: "Team", component: TeamTab },
    // { name: "Workflow", component: WorkflowTab },
    // { name: "Access", component: AccessTab },
    // Add more tabs as needed
]);

const tabs = computed(() => {
    if (propertiesStore.focus == "document") {
        return document_tabs.value;
    } else if (propertiesStore.focus == "task_document") {
        return task_document_tabs.value;
    } else {
        return folder_tabs.value;
    }
});

const _tab = (index) => {
    activeTab.value = index;
};


onMounted(async () => {
    await propertiesStore.getLookups();
    await propertiesStore.getSerials();
    if (await authStore.allowedAccess("view_meta_data_tab")) {
        document_tabs.value.splice(0, 0, { name: "Meta Data", component: MetaDataTab });
        task_document_tabs.value.splice(0, 0, { name: "Meta Data", component: MetaDataTab });
    }
    if (await authStore.allowedAccess("view_history_tab")) {
        document_tabs.value.splice(1, 0, { name: "History", component: HistoryTab });
        task_document_tabs.value.splice(2, 0, { name: "History", component: HistoryTab });
    }
    if (await authStore.allowedAccess("view_access_tab")) {
        document_tabs.value.splice(2, 0, { name: "Access", component: AccessTab });
        folder_tabs.value.splice(3, 0, { name: "Access", component: AccessTab });
    }
    if (await authStore.allowedAccess("view_team_tab")) {
        folder_tabs.value.splice(1, 0, { name: "Team", component: TeamTab });
    }
    if (await authStore.allowedAccess("view_workflow_tab")) {
        folder_tabs.value.splice(2, 0, { name: "Workflow", component: WorkflowTab });
    }
    if (await authStore.allowedAccess("view_action_tab")) {
        task_document_tabs.value.splice(1, 0, { name: "Action", component: ActionTab });
    }
    if (await authStore.allowedAccess("view_meta_data_fields_tab")) {
        folder_tabs.value.splice(0, 0, { name: "Meta Data Fields", component: MetaDataFieldsTab });
    }
});
</script>

<style></style>
