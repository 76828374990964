import axios from "axios";
import { ref } from "vue";
import _ from "lodash";
import { defineStore } from "pinia";
import Swal from "sweetalert2";
import { useRoute } from "vue-router";
import { useToast } from "vue-toastification";
export const useSetting = defineStore("settings", {
    state: () => ({
        toast: useToast(),
        route: useRoute(),
        token: localStorage.getItem("edms_token"),
        usergridview: false,
        serializerview: false,
        generalsettingsview: true,
        lookupsview: false,
        permissionsview: false,
        profileview: true,
        roleview: false,
        advocateview: false,
        contactview: false,
        contractview:true,
        partyview:false,
        requestview:false,
        searchTerm: "",
        users: [],
        approvers: [],
        stakeholders: [],
        user: '',
        roles: [],
        advocates: [],
        newUserModal: "",
        sortedColumn: "created_at",
        sortOrder: "asc",
        items: [],
        searchFilter: "",
        searchRole:"",
        searchAdvocate:"",
        searchSerial:"",
        radioFilter: "",
        openAddUser: "",
        openAssignRole: "",
        openDetailsRole: "",
        selectedUser: {},
        isModalOpen: ref(false),
        serializers: [],
        userData: {
        photoUrl: '',
        name:'',
        paginationData:[],
        paginationMeta:"",
        paginationLinks:"",
        rolePaginationData:[],
        rolePaginationMeta:"",
        rolePaginationLinks:"",
        serilizerPaginationData:[],
        serilizerPaginationMeta:"",
        serilizerPaginationLinks:"",
        advocatesPaginationData:[],
        advocatesPaginationMeta:"",
        advocatesPaginationLinks:"",

    }
    }),
    getters: {
        showFullTopBar(state) {
            return state.route.name == "Repository";
        },
        showAdminTopBar(state) {
            return state.route.name == "Settings";
        },
        showHalfTopBar(state) {
            return state.route.name == "Repository";
        },
        // showContractsTopBar(state) {
        //     return state.route.name == "Contracts";
        // },

        filteredUsers: (state) => {
            let users = state.users;
            console.log("users",users)
            if (state.searchFilter !== "") {
                const searchTermLowerCase = state.searchFilter.toString().toLowerCase();
                users = users.filter((user) => {
                    const nameLowerCase = user.name.toLowerCase();
                    const usernameLowerCase = user.username.toLowerCase();
                    const emailLowerCase = user.email.toLowerCase();
                    return (
                        nameLowerCase.includes(searchTermLowerCase) ||
                        usernameLowerCase.includes(searchTermLowerCase) ||
                        emailLowerCase.includes(searchTermLowerCase)
                    );
                });
            }
            if (state.sortedColumn) {
                 users = _.orderBy(
                    users,
                    [state.sortedColumn],
                    [state.sortOrder]
                );
            }
            return users;
        },

        filteredRoles: (state) => {
            let roles = state.roles;
            if (state.searchRole !== "") {
                const searchTermLowerCase = state.searchRole.toString().toLowerCase();
                roles = roles.filter((role) => {
                    // console.log(role.role_name)
                    const nameLowerCase = role.role_name.toLowerCase();
                    const rolenameLowerCase = role.description.toLowerCase();
                    return (
                        nameLowerCase.includes(searchTermLowerCase) ||
                        rolenameLowerCase.includes(searchTermLowerCase)
                    );
                });
            }
                  if (state.sortedColumn) {
                 roles = _.orderBy(
                    roles,
                    [state.sortedColumn],
                    [state.sortOrder]
                );
            }
            return roles;
        },

        filteredAdvocates: (state) => {
            let advocates = state.advocates;
            if (state.searchAdvocate !== "") {
                const searchTermLowerCase = state.searchAdvocate.toString().toLowerCase();
                advocates = advocates.filter((advocate) => {
                    // console.log(advocate.advocate)
                    const nameLowerCase = advocate.name.toLowerCase();
                    const advocateNameLowerCase = advocate.email.toLowerCase();
                    return (
                        nameLowerCase.includes(searchTermLowerCase) ||
                        advocateNameLowerCase.includes(searchTermLowerCase)
                    );
                });
            }
                   if (state.sortedColumn) {
                 advocates = _.orderBy(
                    advocates,
                    [state.sortedColumn],
                    [state.sortOrder]
                );
            }
            return advocates;
        },

         filteredSerials: (state) => {
            let serializers = state.serializers;
            if (state.searchSerial !== "") {
                const searchTermLowerCase = state.searchSerial.toString().toLowerCase();
                serializers = serializers.filter((serializer) => {
                    // console.log(serializer)
                    const formatLowerCase = serializer.number_format.toLowerCase();
                    const prefixLowerCase = serializer.prefix.toLowerCase();
                    const postfixLowerCase = serializer.postfix.toLowerCase();
                    return (
                        formatLowerCase.includes(searchTermLowerCase) ||
                        postfixLowerCase.includes(searchTermLowerCase) ||
                        prefixLowerCase.includes(searchTermLowerCase)
                    );
                });
            }
            return serializers;
        },
    },
    actions: {
        showUserView() {
            this.usergridview = true;
            this.serializerview = false;
            this.generalsettingsview = false;
            this.lookupsview = false;
            this.roleview = false;
            this.advocateview = false;
            this.contactview = false;
            this.contractview = false;
            this.partyview = false;
            this.requestview = false;
        },
        showLookupView() {
            this.usergridview = false;
            this.serializerview = false;
            this.generalsettingsview = false;
            this.lookupsview = true;
            this.roleview = false;
            this.advocateview = false;
            this.contactview = false;
            this.contractview = false;
            this.partyview = false;
            this.requestview = false;
        },
        showSerializerView() {
            this.usergridview = false;
            this.serializerview = true;
            this.generalsettingsview = false;
            this.lookupsview = false;
            this.roleview = false;
            this.advocateview = false;
            this.contactview = false;
            this.contractview = false;
            this.partyview = false;
            this.requestview = false;
        },
        showSettingsView() {
            this.usergridview = false;
            this.serializerview = false;
            this.generalsettingsview = true;
            this.lookupsview = false;
            this.roleview = false;
            this.advocateview = false;
            this.contactview = false;
            this.contractview = false;
            this.partyview = false;
            this.requestview = false;
        },
        showRoleView() {
            this.roleview = true;
            this.usergridview = false;
            this.serializerview = false;
            this.generalsettingsview = false;
            this.lookupsview = false;
            this.advocateview = false;
            this.contactview = false;
            this.contractview = false;
            this.partyview = false;
            this.requestview = false;
        },
        showAdvocateView() {
            this.roleview = false;
            this.usergridview = false;
            this.serializerview = false;
            this.generalsettingsview = false;
            this.lookupsview = false;
            this.advocateview = true;
            this.contactview = false;
            this.contractview = false;
            this.partyview = false;
            this.requestview = false;
        },
        showContactView() {
            this.roleview = false;
            this.usergridview = false;
            this.serializerview = false;
            this.generalsettingsview = false;
            this.lookupsview = false;
            this.advocateview = false;
            this.contactview = true;
            this.contractview = false;
            this.partyview = false;
            this.requestview = false;
        },

      
        async populateUsersTable(page = 1) {
            try {
                
                const response = await axios.get('/users'+ "?page=" + page, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("edms_token")}`,
                        "Content-Type": "application/json",
                    },
                });
                const data = await response.data;
                this.items = data;
                this.paginationData = data.data.data;
                this.paginationLinks = data.data.links;
                this.paginationMeta = data.data.meta;

                const baseUrlApi = axios.defaults.baseURL.replace("/api", "");

                this.approvers = this.paginationData.map(approver => ({
                    label: approver.name,
                    id: approver.id
                }));

                this.stakeholders = this.paginationData.map(stakeholder => ({
                    label: stakeholder.name,
                    id: stakeholder.id
                }));
                
                this.users = this.paginationData.map((user) => {
                    // console.log("hell",user.roles);
                   
                    const photoUrl = user.user_profile ? `${baseUrlApi}/storage/user_profiles/${user.user_profile}` : `${process.env.BASE_URL}images/no_image.jpg`;
                   
                    return {
                        id: user.id,
                        name: user.name,
                        username: user.username,
                        email: user.email,
                        photoUrl: photoUrl,
                        role: user.roles,
                        department: user.department,
                        statusLabel: user.is_active === 1 ? "Active" : "Inactive",
                        statusColor: user.is_active === 1 ? "green" : "indianred",
                        status: user.is_active === 1 ? "deactivate" : "activate",
                    };
                });
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        },

        async populateAdvocatesTable(page = 1) {
            try {
                // Fetch role data from the API
                const response = await axios.get('/advocates' + "?page=" + page, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "edms_token"
                        )}`,
                        "Content-Type": "application/json",
                    },
                });

                console.log(response);

                const data = await response.data;
                this.advocatesPaginationData = data.data.data;
                this.advocatesPaginationLinks = data.data.links;
                this.advocatesPaginationMeta = data.data.meta;
                
                this.advocates = this.advocatesPaginationData;
            } catch (error) {
                console.error("Error fetching advocates data:", error);
            }
        },
        
        async getUser() {
            try {
                // Fetch user data from the API
                const response = await axios.get(`/user/show/${localStorage.getItem("userId")}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("edms_token")}`,
                        "Content-Type": "application/json",
                    },
                });
                const data = await response.data;
                const baseUrlApi = axios.defaults.baseURL.replace("/api", "");
                this.userData.photoUrl = data.data.data.user_profile ? `${baseUrlApi}/storage/user_profiles/${data.data.data.user_profile}` : `${process.env.BASE_URL}images/no_image.jpg`;
                this.userData.name = data.data.data.name;
                this.user = data.data.data.id

            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        },
        
        async confirmActivate(user) {
            const { isConfirmed } = await Swal.fire({
                title: "Are you sure?",
                text: "Once activated, the user will be able to access the system and All It's Contents!",
                icon: "success",
                showCancelButton: true,
                confirmButtonText: "Yes, activate user!",
                cancelButtonText: "No, cancel!",
                reverseButtons: true,
                showLoaderOnConfirm: true,
            });
            if (isConfirmed) {
                this.activateUserRequest(user);
            } else {
                console.log("user is not activated");
            }
        },

        async activateUserRequest(user) {
            axios
                .post(
                    `/users/update/${user.id}`,
                    {
                        is_active: 1,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                                "edms_token"
                            )}`,
                            "Content-Type": "application/json",
                        },
                    }
                )
                .then((response) => {
                    if (response.status !== 200) {
                        throw new Error("Failed to activate user");
                    }
                    return response.data;
                })
                .then((data) => {
                    if (data.data == 403) {
                        this.toast.error(data.message, {
                            timeout: 2000,
                        });
                    } else {
                        this.toast.success(data.message, {
                            timeout: 2000,
                        });
                        this.populateUsersTable();
                    }
                })
                .catch((error) => {
                    console.error("Error activating user:", error);
                    this.toast.error("Failed to deactivate user", {
                        timeout: 5000,
                    });
                });
        },

        async confirmDeactivate(user) {
            const { isConfirmed } = await Swal.fire({
                title: "Are you sure?",
                text: "Once Deactivated, the user will not be able to access the system and All It's Contents!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes, deactivate user!",
                cancelButtonText: "No, cancel!",
                reverseButtons: true,
                showLoaderOnConfirm: true,
            });
            if (isConfirmed) {
                this.deactivateUserRequest(user);
            } else {
                this.toast.success("User is safe!", {
                    timeout: 5000,
                });
            }
        },

        deactivateUserRequest(user) {
            axios
                .post(
                    `/users/update/${user.id}`,
                    {
                        is_active: 0,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                                "edms_token"
                            )}`,
                            "Content-Type": "application/json",
                        },
                    }
                )
                .then((response) => {
                    if (response.status !== 200) {
                        throw new Error("Failed to deactivate user");
                    }
                    return response.data;
                })
                .then((data) => {
                    // Check the response and handle accordingly
                    if (data.data == 403) {
                        this.toast.error(data.message, {
                            timeout: 2000,
                        });
                    } else {
                        this.toast.success(data.message, {
                            timeout: 2000,
                        });
                        this.populateUsersTable();
                    }
                })
                .catch((error) => {
                    console.error("Error deactivating user:", error);
                    this.toast.error("Failed to deactivate user", {
                        timeout: 5000,
                    });
                });
        },

        async createUser(formData) {
            console.log("Creating user:", formData);
            axios
                .post("/users/store", formData, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "edms_token"
                        )}`,
                        "Content-Type": "application/json",
                    },
                })
                .then((response) => {
                    if (response.status !== 200) {
                        throw new Error("Failed to create user");
                    }
                    return response.data;
                })
                .then((data) => {
                    this.toast.success(data.message, {
                        timeout: 2000,
                    });
                    this.closeModal();
                    this.populateUsersTable();
                })
                .catch((error) => {
                    // Handle error response
                    console.error("Error creating user:", error);
                    this.toast.error(error.message);
                });
        },

        async populateRolesTable(page = 1) {
            try {
                // Fetch role data from the API
                const response = await axios.get('/roles' + "?page=" + page, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "edms_token"
                        )}`,
                        "Content-Type": "application/json",
                    },
                });

                const data = await response.data;
                this.rolePaginationData = data.data.data;
                this.rolePaginationLinks = data.data.links;
                this.rolePaginationMeta = data.data.meta;
                
                this.roles = this.rolePaginationData;
            } catch (error) {
                console.error("Error fetching role data:", error);
            }
        },

        async confirmDeletRole(role) {
            const { isConfirmed } = await Swal.fire({
                title: "Are you sure?",
                text: "Once deleted, you will not be able to recover this Role and All It's Contents!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes, delete role!",
                cancelButtonText: "No, cancel!",
                reverseButtons: true,
                showLoaderOnConfirm: true,
            });
            if (isConfirmed) {
                this.deleteRoleRequest(role);
            } else {
                this.toast.success("Role is safe!", {
                    timeout: 5000,
                });
            }
        },

        deleteRoleRequest(role) {
            axios
                .get(`/roles/delete/${role.id}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "edms_token"
                        )}`,
                        "Content-Type": "application/json",
                    },
                })
                .then((response) => {
                    if (response.status !== 200) {
                        throw new Error("Failed to delete role");
                    }
                    return response.data;
                })
                .then((data) => {
                    // Check the response and handle accordingly
                    if (data.data == 403) {
                        this.toast.error(data.message, {
                            timeout: 2000,
                        });
                    } else {
                        this.toast.success(data.message, {
                            timeout: 2000,
                        });
                        this.populateRolesTable();
                    }
                })
                .catch((error) => {
                    console.error("Error deleting role:", error);
                    this.toast.error("Failed to delete role", {
                        timeout: 5000,
                    });
                });
        },

        async populateSerializersTable(page = 1) {
            try {
                // Fetch serial data from the API
                const response = await axios.get('/serialisations' + "?page=" + page, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "edms_token"
                        )}`,
                        "Content-Type": "application/json",
                    },
                });

                const data = await response.data;
                this.serilizerPaginationData = data.data.data;
                this.serilizerPaginationLinks = data.data.links;
                this.serilizerPaginationMeta = data.data.meta;
                this.serializers = this.serilizerPaginationData;
            } catch (error) {
                console.error("Error fetching serializers data:", error);
            }
        },

        async confirmDeleteSerializer(serializer) {
            const { isConfirmed } = await Swal.fire({
                title: "Are you sure?",
                text: "Once deleted, you will not be able to recover this Serialisation and All It's Contents!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes, delete it!",
                cancelButtonText: "No, cancel!",
                reverseButtons: true,
                showLoaderOnConfirm: true,
            });
            if (isConfirmed) {
                this.deleteSerializerRequest(serializer);
            } else {
                this.toast.success("Serializer is safe!", {
                    timeout: 5000,
                });
            }
        },

        deleteSerializerRequest(serializer) {
            axios
                .get(`/serialisations/delete/${serializer.id}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "edms_token"
                        )}`,
                        "Content-Type": "application/json",
                    },
                })
                .then((response) => {
                    if (response.status !== 200) {
                        throw new Error("Failed to delete serializer");
                    }
                    return response.data;
                })
                .then((data) => {
                    // Check the response and handle accordingly
                    if (data.data == 403) {
                        this.toast.error(data.message, {
                            timeout: 2000,
                        });
                    } else {
                        this.toast.success(data.message, {
                            timeout: 2000,
                        });
                        this.populateSerializersTable();
                    }
                })
                .catch((error) => {
                    console.error("Error deleting serializer:", error);
                    this.toast.error("Failed to delete serializer", {
                        timeout: 5000,
                    });
                });
        },

        async confirmDeleteAdvocate(advocate) {
            const { isConfirmed } = await Swal.fire({
                title: "Are you sure?",
                text: "Once deleted, you will not be able to recover this Advocate!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes, delete it!",
                cancelButtonText: "No, cancel!",
                reverseButtons: true,
                showLoaderOnConfirm: true,
            });
            if (isConfirmed) {
                this.deleteAdvocateRequest(advocate);
            } else {
                this.toast.success("Advocate is safe!", {
                    timeout: 5000,
                });
            }
        },

           async createContact(contactData){
            try{
                const response = await axios.post(
                    "/contacts",
                    contactData,
                    {
                         headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${localStorage.getItem(
                                "edms_token"
                            )}`,
                        },
                    }
                );

                const data = await response.data;
                console.log(data);
                if(data){
                    this.toast.success(data.message, {
                            timeout: 2000,
                        });
                    this.closeModal();
                }

            }catch(error){
                // console.error("Error:", error.response.data.message);
                if(error){
                    this.notfound = error.response.data.message
                    setTimeout(() => {
                    this.notfound = ''
                    }, 5000);
                }

            }
        },
        async fetchContacts(lookup_id) {
            try {
                const response = await axios.get("/contacts-by-type/"+lookup_id, {
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                        "Content-type": "application/json",
                    },
                });
                const data = response.data;
                if (data.success == false) {
                    console.log(data.message);
                } else {
                    return data.data.data;
                }
            } catch (error) {
                console.error("Error fetching cases:", error);
                // Handle errors gracefully
            }
        },

        deleteAdvocateRequest(advocate) {
            axios
                .delete(`/advocates/${advocate.id}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "edms_token"
                        )}`,
                        "Content-Type": "application/json",
                    },
                })
                .then((response) => {
                    if (response.status !== 200) {
                        throw new Error("Failed to delete advocate");
                    }
                    return response.data;
                })
                .then((data) => {
                    // Check the response and handle accordingly
                    if (data.data == 403) {
                        this.toast.error(data.message, {
                            timeout: 2000,
                        });
                    } else {
                        this.toast.success(data.message, {
                            timeout: 2000,
                        });
                        this.populateAdvocatesTable();
                    }
                })
                .catch((error) => {
                    console.error("Error deleting advocate:", error);
                    this.toast.error("Failed to delete advocate", {
                        timeout: 5000,
                    });
                });
        },

        sortBy(columnKey){
             if (this.sortedColumn == columnKey) {
                this.sortOrder = this.sortOrder === "asc" ? "desc" : "asc";
            } else {
                this.sortedColumn = columnKey;
                this.sortOrder = "asc";
            }
        },

        refreshData(page = 1) {
           this.populateUsersTable(page);
        }, 
        refreshAdvocateData(page = 1) {
           this.populateAdvocatesTable(page);
        }, 
         refreshContactData(page = 1) {
           this.populateContactsTable(page);
        }, 
        refreshRoleData(page = 1) {
           this.populateRolesTable(page);
        },
         refreshSerializerData(page = 1) {
           this.populateSerializersTable(page);
        },   

        closeModal() {
            this.isModalOpen = false;
        },
    },
});
