<template>
    <div
        class="sidebar-right sidebar-mini layout-navbar-fixed layout-fixed text-sm sidebar-light-indigo elevation-4"
        id="sidebarRight"
    >
        <div class="content y mx-3" style="padding: 2%">
            <div class="row">
                <div class="col-6">
                    <div>
                        <center>
                            <b style="font-size: 16px"><u>Notifications</u></b>
                        </center>
                    </div>
                </div>
                <div class="col-6">
                    <div class="close" @click="notificationStore.toggleNotificationModal()">
                        &times;
                    </div>
                </div>
            </div>
        </div>

        <div
            class="container y c mx-2"
            style="padding: 2%"
            id="notificationsTabs"
        >
            <div style="font-weight: bolder">
                <div class="y">
                    <div class="c" v-for="(tab, index) in tabs" :key="index">
                        <input class="g" :name="`dashboard_properties_tab`" :value="index" type="radio"
                               v-model="activeTab"/>
                        <li class="x y rsbtab" @click="_tab(index)">
                            {{ tab.name }}
                        </li>
                    </div>
                    <div class="e"></div>
                </div>
            </div>
            <div class="y ba">
                <div v-for="(tab, index) in tabs" :key="index">
                    <input class="g" :name="`dashboard_properties_tabv${index}`"
                           :type="activeTab === index ? 'radio' : 'hidden'" :checked="activeTab === index"/>
                    <div class="rsbtabv y">
                        <!-- Dynamically render content based on the selected tab -->
                        <template v-if="activeTab === index">
                            <component :is="tab.component" />
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup lang="js">
import ReadTab from "@/components/ReadNotifications.vue";
import UnreadTab from "@/components/UnreadNotifications.vue";
import {ref} from "vue";
import { useNotification } from "@/stores/notification";

const notificationStore = useNotification();

let activeTab = ref(0);

let tabs = ref([
    { name: "Unread", component: UnreadTab },
    { name: "Read", component: ReadTab },
    // Add more tabs as needed
]);
const _tab = (index) => {
    activeTab.value = index;
};
</script>
