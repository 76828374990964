<template>
    <!-- ******************************Create User modal********************************** -->
    <div id="myModal" class="modalIn">
        <div class="modalIn-content-center ">
            <div class="modal-header border-bottom-0">
                <h5 class="modal-title" id="serializerModalLabel">
                    Approve/Reject File Requisition
                </h5>
                <button type="button" class="close" @click="closeModal">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <form @submit.prevent="updateRequisition">
                <div class="modal-body row">
                    <div class="form-group col-md-6">
                        <label for="File Name">File Name:</label>
                        <input :value="requisition?.file?.file_name" type="text" class="form-control" id="File Name"
                            name="File Name" disabled />
                    </div>
                    <div class="form-group col-md-6">
                        <label for="requested_by">Requested By:</label>
                        <input :value="requisition?.requisition_by?.name" type="text" class="form-control"
                            id="requested_by" name="requested_by" disabled />
                    </div>
                    <div class="form-group col-md-12">
                        <label for="requisition_reason">Requested By:</label>
                        <input :value="requisition?.requisition_reason" type="text" class="form-control"
                            id="requisition_reason" name="requisition_reason" disabled />
                    </div>
                    <div class="form-group col-md-6">
                        <label for="status">Status:</label>
                        <treeselect v-model="requisition.status" :multiple="false" :clearable="true" :searchable="true"
                            :disabled="disabled" :open-on-click="true" :open-on-focus="true" :clear-on-select="true"
                            :close-on-select="true" :always-open="false" :append-to-body="appendToBody"
                            :options="options" :limit="6" />
                    </div>
                </div>
                <div class="modal-footer border-top-0 d-flex justify-content-between mx-5 px-5">
                    <button type="button" class="btn btn-secondary" @click="closeModal" id="cancel">
                        Cancel
                    </button>
                    <button type="submit" class="btn btn-success" id="cancel">
                        Submit
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script setup>
import { useFiles } from "@/stores/files";
import { defineEmits, onMounted, ref } from "vue";
// import Treeselect from "vue3-treeselect";
// import "vue3-treeselect/dist/vue3-treeselect.css";

// import the component
import Treeselect from "@zanmato/vue3-treeselect";
// import the styles
import "@zanmato/vue3-treeselect/dist/vue3-treeselect.min.css";

const filesStore = useFiles();
const emit = defineEmits(["close-modal"]);
const requisition = ref({});
const options = ref([
    { label: "Approve", id: "Approved" },
    { label: "Reject", id: "Rejected" },
    { label: "Pending", id: "Pending" },
]);

const updateRequisition = async () => {
    filesStore.updateRequisition(requisition.value);
    closeModal();
    emit("close-modal");
};

function closeModal() {
    emit("close-modal");
}
onMounted(() => {
    requisition.value = filesStore.targetRequistion;
});

</script>

<style scoped>
/* Add your scoped styles here */
</style>
