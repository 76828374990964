import { createRouter, createWebHistory } from "vue-router";
import CaseDocument from '@/components/CaseDocuments.vue';
import FileRequisition from '@/components/FileRequisition.vue';
import CaseHistory from '@/components/CaseHistory.vue';
import CaseDiary from  '@/components/CaseDiary.vue';


const routes = [
    {
        path: "/login",
        name: "Login",
        component: () => import("./views/LoginView.vue"),
    },
    {
        path: "/forgot-password",
        name: "ForgotPassword",
        component: () => import("./views/ForgotPassword.vue"),
    },
    {
        path: "/reset-password",
        name: "ResetPassword",
        component: () => import("./views/ResetPassword.vue"),
    },
    {
        path: "/",
        name: "Dashboard",
        meta: {
            auth: true,
        },
        component: () => import("./views/DashboardView.vue"),
    },
    {
        path: "/repository",
        name: "Repository",
        meta: {
            auth: true,
        },
        component: () => import("./views/RepositoryView.vue"),
        props: true,
    },
    {
        path: "/tasks",
        name: "Tasks",
        meta: {
            auth: true,
        },
        component: () => import("./views/TaskView.vue"),
        props: true,
    },
    {
        path: "/cases",
        name: "Cases",
        meta: {
            auth: true,
        },
        component: () => import("./views/CaseView.vue"),
        props: true,
    },
    {
        path: "/recently_viewed",
        name: "Recently Viewed",
        meta: {
            auth: true,
        },
        component: () => import("./views/RecentlyviewedView.vue"),
    },
    {
        path: "/bookmarks",
        name: "Bookmarks",
        meta: {
            auth: true,
        },
        component: () => import("./views/BookmarksView.vue"),
    },
    {
        path: "/user-profile",
        name: "user-profile",
        meta: {
            auth: true,
        },
        component: () => import("./views/UserProfile.vue"),
    },
    {
        path: "/saved-searches",
        name: "Saved Searches",
        meta: {
            auth: true,
        },
        component: () => import("./views/SavedSearchView.vue"),
    },
    {
        path: "/settings",
        name: "Settings",
        meta: {
            auth: true,
        },
        component: () => import("./views/SettingsView.vue"),
    },
    {
        path: "/contracts",
        name: "Contracts",
        meta: {
            auth: true,
        },
        component: () => import("@/views/ContractsView.vue"),
    },
    {
        path: "/file-movement",
        name: "File Movement",
        meta: {
            auth: true,
        },
        component: () => import("@/views/FileMovementView.vue"),
    },
    {
        path: "/lookup",
        name: "lookup",
        meta: {
            auth: true,
        },
        component: () => import("@/components/LookupsView.vue"),
    },
    {
        path: '/case-document',
        name: 'CaseDocument',
          meta: {
            auth: true,
        },
        component: CaseDocument,
    },
    {
        path:'/case-requisition',
        name:'FileRequisition',
            meta: {
            auth: true,
        },
        component:FileRequisition

    },
    {
        path:'/case-history',
        name: 'CaseHistory',
        meta: {
            auth: true,
        },
        component:CaseHistory
        


    },
    {
        path:'/case-diary',
        name:'CaseDiary',
        meta: {
            auth: true,
        },
        component:CaseDiary
        


    },

    {
        path: "/:catchAll(.*)",
        component: () => import("@/views/404.vue"),
    },
    // {
    //   path: '/repository/:id',
    //   name: 'repository',
    //   component: () => import('./views/RepositoryView.vue'),
    //   props: route => ({ id: parseInt(route.params.id) }),
    //   meta: {
    //     data: { message: 'This is additional data for the route' }
    //   }
    // },
    // {
    //   path: '/documentviewer',
    //   name: 'DocumentViewer',
    //   component: () => import('./components/DocumentViewer.vue'),
    // }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    const loggedIn = localStorage.getItem("edms_token");

    if (to.matched.some((record) => record.meta.auth) && !loggedIn) {
        next("/login");
        return;
    }
    next();
});
export default router;
