<template>
  <footer class="main-footer">
      <strong>Copyright &copy; 2023-{{ currentYear }} <a href="https://disigroup.com/">Disi Group - Digital Imaging and scanning International (DISI)</a>.</strong>
      All rights reserved.
      <div class="float-right d-none d-sm-inline-block">
          <b>Version</b> 1.0.0
      </div>
  </footer>
</template>

<script>
export default {
data() {
  return {
    currentYear: new Date().getFullYear() // Fetch and display the current year
  };
},
methods: {
 
},
setup() {
  return {

  };
},
};
</script>

<style scoped>
</style>
