<template>
    <div id="myModal" class="modalIn">
        <div class="modalIn-content-right">
            <span class="close" @click="closeModal()">&times;</span>
            <p id="modalTitle" class="modalIn-title">
                Add User To {{ propertiesStore.current_folder.name }} Limited Access
            </p>

            <!-- Form for adding a user -->
            <div class="modal-body">
                <!-- Select User -->
                <div class="form-group">
                    <select class="form-control mb-2 mr-sm-2" v-model="selectedUserId" required>
                        <option disabled value="null">Select User</option>
                        <option v-for="user in users" :key="user.id" :value="user.id">
                            {{ user.name }}
                        </option>
                    </select>
                </div>
                <!-- Save button -->
                <button type="submit" class="btn btn-primary" @click="addUser">
                    Add User
                </button>
            </div>
        </div>
    </div>
</template>

<script setup lang="js">
import { ref, defineEmits, onMounted } from 'vue';
import axios from 'axios';
import { useToast } from 'vue-toastification';
import { useProperties } from "@/stores/properties";

const propertiesStore = useProperties();
const emit = defineEmits(['close-modal']);
const toast = useToast();
const token = localStorage.getItem('edms_token');
const selectedUserId = ref(null);
const users = ref([]);

const fetchUsers = async () => {
    try {
        const response = await axios.get(`/users`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        });
        users.value = response.data.data.data;
    } catch (error) {
        console.error('Error fetching users:', error);
    }
};

const addUser = async () => {
    try {
        let folder_allowed_users = propertiesStore.current_folder.allowed_users?.split(',') || [];
        let document_allowed_users = propertiesStore.document.allowed_users?.split(',') || [];
        if (folder_allowed_users.includes(selectedUserId.value) || document_allowed_users.includes(selectedUserId.value)) {
            toast.error('User already exists in access list', {
                timeout: 5000,
            });
            return;
        }
        if(propertiesStore.focus == "folder"){
            folder_allowed_users = folder_allowed_users.length > 0 ? folder_allowed_users.toString().concat(',' + selectedUserId.value) : folder_allowed_users.toString().concat(selectedUserId.value);
            propertiesStore.current_folder.allowed_users = folder_allowed_users.toString();
            const response = await axios.post(
                `/folder/manage_access/${propertiesStore.current_folder.id}`,
                {
                    allowed_users: folder_allowed_users.toString(),
                },
                //  propertiesStore.current_folder,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            if (!response.data.success) {
                const errorData = response.data;
                throw new Error(errorData.message || 'Failed to add user to access list');
            }
        }else{
            document_allowed_users = document_allowed_users.length > 0 ? document_allowed_users.toString().concat(',' + selectedUserId.value) : document_allowed_users.toString().concat(selectedUserId.value);
            propertiesStore.document.allowed_users = document_allowed_users.toString();
            const response = await axios.post(
                '/folder/documents/manage_access/' + propertiesStore.document.id,
                {
                    allowed_users: document_allowed_users.toString(),
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            if (!response.data.success) {
                const errorData = response.data;
                throw new Error(errorData.message || 'Failed to add user to access list');
            }
        }
        emit('close-modal');
        toast.success('Access Updated successfully', {
            timeout: 5000,
        })
    } catch (error) {
        console.error('Error updating access list:', error);
        toast.error(error.message);
    }
};

const closeModal = () => {
    selectedUserId.value = null;
    emit('close-modal');
};
onMounted(() => {
    fetchUsers();
});
</script>


<style scoped>
.toggle-on {
    color: blue !important;
}

.toggle-off {
    color: #8b4513 !important;
}
</style>
