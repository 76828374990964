<template>
    <div class="layout">
        <!-- Content Header (Page header) -->
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1>{{ route.name }}</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <a href="" @click="router.push('/')">Home</a>
                            </li>
                            <li class="breadcrumb-item active">
                                {{ route.name }}
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
            <!-- /.container-fluid -->
        </section>
        <NavBar v-if="authStore.token" />
        <div class="content-container">
            <router-view />
        </div>
        <RightSidebar v-if="authStore.token" />
        <UserProfile v-if="authStore.token" />
    </div>
    <PageFooter />
</template>

<script setup lang="js">
import NavBar from "@/components/NavBar.vue";
import PageFooter from "@/components/PageFooter.vue";
import RightSidebar from "@/components/RightSidebar.vue";
import UserProfile from '@/components/modals/UserProfile.vue';
import { useAuth } from "@/stores/auth";

import { useRoute, useRouter } from 'vue-router';

const authStore = useAuth();
const router = useRouter()
const route = useRoute()
</script>

<style></style>
