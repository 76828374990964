<template>
    <div
        class="sidebar-right sidebar-mini layout-navbar-fixed layout-fixed text-sm sidebar-light-indigo elevation-4"
        id="sidebarRight"
    >
        <div class="content y mx-3" style="padding: 2%">
            <div class="row">
                <div class="col-6">
                    <div>
                        <center>
                            <b style="font-size: 16px"><u>Your Profile</u></b>
                        </center>
                    </div>
                </div>
                <div class="col-6">
                    <div class="close" @click="hideProfileSidebar()">
                        &times;
                    </div>
                </div>
            </div>

            <!-- Buttons for uploading -->
            <div></div>
            <div style="margin-top: 20px">
                <div style="margin-top: 20px">
                    <div class="card-header row">
                        <p class="btn btn-success" @click="logout()">
                            <span class="material-symbols-outlined" style=""
                                >account_circle</span
                            >My Profile
                        </p>
                    </div>
                </div>
            </div>

            <!-- Logout button with icon -->
            <div style="margin-top: 20px">
                <div style="margin-top: 20px">
                    <div class="card-header row">
                        <p class="btn btn-warning" @click="logout()">
                            <span class="material-symbols-outlined" style=""
                                >power_settings_new</span
                            >Logout
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <UploadPhoto /> -->
</template>

<script>
import { useToast } from "vue-toastification";
import axios from "axios";

export default {
    data() {
        return {
            baseUrl: this.apiBaseUrl,
            toast: useToast(),
        };
    },
    methods: {
        hideProfileSidebar() {
            this.$emit("hide-profile-sidebar");
        },

        async logout() {
            const bearerToken = localStorage.getItem("edms_token");

            await axios.post(`/auth/logout`, {
                headers: {
                    Authorization: `Bearer ${bearerToken}`,
                    "Content-Type": "application/json",
                },
            })
                .then((response) => {
                    if (response.ok) {
                        // Remove token from localStorage
                        localStorage.removeItem("edms_token");
                        localStorage.removeItem("userId");
                        this.toast.sucess("See you next time", {
                            timeout: 2000,
                        });
                    } else {
                        // Handle logout failure
                        console.error(response.statusText);
                    }
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
        },
    },
};
</script>

<style scoped>
.container {
    display: flex;
    flex-direction: column;
    gap: 10px; /* Adjust the gap between elements as needed */
}

.card-header {
    width: 100%; /* Make each element take up full width */
}

.btn {
    width: 100%; /* Make the button take up full width within its parent */
    text-align: left; /* Align button text to the left */
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
</style>
