import axios from "axios";
import { defineStore } from "pinia";
import { useRepository } from "@/stores/repository";

export const useProperties = defineStore("properties", {
    state: () => ({
        repositoryStore: useRepository(),
        current_folder: {},
        document: {},
        focus: "",
        token: localStorage.getItem("edms_token"),
        lookups: [],
        serials: [],
        lookups_pagination: { links: "", meta: "" },
        serials_pagination: { links: "", meta: "" },
    }),
    getters: {
        // current_folder: (state) => {
        //     return state.repositoryStore.current_folder;
        // },
    },
    actions: {
        updateIndexFieldData(action, data) {
            if (action == "add") {
                this.repositoryStore.current_folder.fields.push(data);
            } else if (action == "update") {
                this.repositoryStore.current_folder.fields.splice(
                    this.repositoryStore.current_folder.fields.indexOf(data),
                    1,
                    data
                );
            } else if (action == "delete") {
                this.repositoryStore.current_folder.fields.splice(
                    this.repositoryStore.current_folder.fields.indexOf(data),
                    1
                );
            }
        },
        updateMetaDataData(action, data){
            console.log(data);
            if (action == "add") {
                this.repositoryStore.document.doc_fields.push(data);
            } else if (action == "update") {
                let old_data = this.repositoryStore.document.doc_fields.find(
                    (doc_field) => doc_field.id == data.id
                );
                this.repositoryStore.document.doc_fields.splice(
                    this.repositoryStore.document.doc_fields.indexOf(old_data),
                    1,
                    data
                );
            } else if (action == "delete") {
                let old_data = this.repositoryStore.document.doc_fields.find(
                    (doc_field) => doc_field.id == data.id
                );
                this.repositoryStore.document.doc_fields.splice(
                    this.repositoryStore.document.doc_fields.indexOf(old_data),
                    1
                );
            }
        },
        async getLookups() {
            this.lookups = [];
            const response = await axios.get("/lookups", {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            });
            if (response.status !== 200) {
                throw new Error("Failed to get lookups");
            }
            const data = response.data;
            if (data.success == false) {
                console.log(data.message);
            } else {
                this.lookups = data.data.data;
                this.lookups_pagination.links = data.data.links;
                this.lookups_pagination.meta = data.data.meta;
            }
        },
        async getSerials() {
            this.serials = [];
            const response = await axios.get("/serialisations", {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                },
            });
            if (response.status !== 200) {
                throw new Error("Failed to get serialisations");
            }
            const data = response.data;
            if (data.success == false) {
                console.log(data.message);
            } else {
                this.serials = data.data.data;
                this.serials_pagination.links = data.data.links;
                this.serials_pagination.meta = data.data.meta;
            }
        },
    },
});
