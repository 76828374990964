import axios from "axios";
import _ from "lodash";
import { defineStore } from "pinia";
import Swal from "sweetalert2";
import { useRoute } from "vue-router";
import { useToast } from "vue-toastification";
import * as pdfjsLib from "../../node_modules/pdfjs-dist/build/pdf";
import { useProperties } from "@/stores/properties";

export const useRepository = defineStore("repository", {
    state: () => ({
        propertiesStore: useProperties(),
        toast: useToast(),
        route: useRoute(),
        token: localStorage.getItem("edms_token"),
        recent_documents: [],
        recent_folders: [],
        target_folder: 1,
        listview: true,
        gridview: false,
        openReport: false,
        openProperties: false,
        openSearch: false,
        documents: [],
        thumbnails: [],
        folders: [],
        document: null,
        actual_file: "",
        showViewer: false,
        folder: {},
        pagination: {
            links: "",
            meta: "",
        },
        search_results: [],
        search_results_data: [],
        bookmark_folders: [],
        bookmark_documents: [],
        searchTerm: "",
        sortedColumn: "updated_at",
        sortOrder: "asc",
        display_thumbnails: [],
        lookups: [],
        serials: [],
        lookups_pagination: { links: "", meta: "" },
        serials_pagination: { links: "", meta: "" },
    }),
    getters: {
        showFullTopBar(state) {
            return state.route.name == "Repository";
        },
        showAdminTopBar(state) {
            return state.route.name == "Settings";
        },
        showHalfTopBar(state) {
            return state.route.name == "Repository";
        },
        current_folder: (state) => {
            return state.folder;
        },
        current_folder_path: (state) => {
            return state.folder.path ? state.folder.path.split("/") : [];
        },

        currentFolderFields: (state) => {
            return state.current_folder ? state.current_folder.fields : [];
        },
        display_folders: (state) => {
            let filteredRows = state.folders;
            filteredRows.forEach((row) => {
                row["name"] = row.name ? row.name : "";
                row["dataurl"] = null;
                row["type"] = "folder";
            });
            return state.thumbnails.concat(filteredRows);
            // return filteredRows;
        },

        display_documents: (state) => {
            let filteredRows = state.documents;
            filteredRows.forEach((row) => {
                row["updated_at"] = row.document_versions.find(
                    (version) => version.main_file == true
                )
                    ? row.document_versions.find(
                          (version) => version.main_file == true
                      ).updated_at
                    : row.updated_at;
                row["doc_version_name"] = row.document_versions.find(
                    (version) => version.main_file == true
                )
                    ? row.document_versions.find(
                          (version) => version.main_file == true
                      ).version_name
                    : "";
                row["doc_file_size"] = row.document_versions.find(
                    (version) => version.main_file == true
                )
                    ? parseInt(
                          row.document_versions.find(
                              (version) => version.main_file == true
                          ).file_size
                      ).toFixed(4)
                    : "";
                row["path"] = row.document_versions.find(
                    (version) => version.main_file == true
                ).physical_path;
            });
            if (state.searchTerm !== "") {
                const searchTermLowerCase = state.searchTerm.toLowerCase();
                filteredRows = filteredRows.filter((row) =>{
                const nameLowerCase = row.document_name.toLowerCase();

                return (
                    nameLowerCase.includes(searchTermLowerCase) 
                );
            });
            }

            if (state.sortedColumn) {
                // const orderMultiplier = sortOrder === 'asc' ? 1 : -1;
                filteredRows = _.orderBy(
                    filteredRows,
                    [state.sortedColumn],
                    [state.sortOrder]
                );
            }
            return filteredRows;
        },
    },
    actions: {
        showGridView() {
            this.listview = false;
            this.gridview = true;
            this.openReport = false;
            this.showViewer = false;
            this.openProperties = false;
            this.openSearch = false;
        },
        showListView() {
            this.listview = true;
            this.gridview = false;
            this.openReport = false;
            this.showViewer = false;
            this.openProperties = false;
            this.openSearch = false;
        },
        showProperties(focus) {
            this.propertiesStore.focus = focus;
            this.openProperties = true;
            this.openSearch = false;
        },
        showReport() {
            this.listview = false;
            this.gridview = false;
            this.openReport = true;
            this.showViewer = false;
            this.openProperties = false;
            this.openSearch = false;
        },
        showSearch() {
            this.listview = false;
            this.gridview = false;
            this.openReport = false;
            this.showViewer = false;
            this.openProperties = false;
            this.openSearch = true;
        },
        hideSidebar() {
            this.openProperties = false;
        },
        async getFolders(target_folder) {
            // this.$progress.start();

            const response = await axios("/folder/show/" + target_folder, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${this.token}`,
                    "Content-type": "application/json",
                },
            });
            const data = await response.data;
            if (data.success == true) {
                this.folder = data.data.data;
                this.folders = this.folder.children.filter(
                    (child) => child.id != child.parent_folder_id
                );
                this.propertiesStore.current_folder = this.folder;
            } else {
                console.log(data.message);
            }

            // // recently viewed folders
            // if (this.recent_folders.length > 5) {
            //  this.recent_folders.shift();  // Remove the folder
            // }
            if (!this.recent_folders.includes(parseInt(this.folder.id))) {
                this.postRecents(this.folder, "folder");
                this.recent_folders.push(this.folder.id); // Append the folder
            }
            // Update local storage with the modified array
            // localStorage.setItem("recent_folders", JSON.stringify(this.recent_folders));
            // this.$progress.finish();
        },
        async getDocuments(target_folder, page, filters) {
            this.documents = [];
            let url =
                "/folder/" + target_folder + "/documents" + "?page=" + page;

            if (filters) {
                for (const key in filters) {
                    if (filters[key]) {
                        url += `&${key}=${filters[key]}`;
                    }
                }
            }

            const response = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${this.token}`,
                    "Content-type": "application/json",
                },
            });
            const data = await response.data;
            console.log("data", data);
            if (data.success == false) {
                console.log(data.message);
            } else {
                this.documents = data.data.data;
                this.pagination.links = data.data.links;
                this.pagination.meta = data.data.meta;
            }
        },
        updateSelectDocument(document) {
            this.document = document;
            this.propertiesStore.focus = "document";
            this.propertiesStore.document = this.document;
            // // recently viewed folders
            // if (this.recent_documents.length > 5) {
            //  this.recent_documents.shift();  // Remove the folder
            // }
            if (!this.recent_documents.includes(this.document.id)) {
                this.postRecents(this.document, "document");
                this.recent_documents.push(this.document.id); // Append the folder
            }

            // Update local storage with the modified array
            // localStorage.setItem("recent_documents", JSON.stringify(this.recent_documents));
        },
        getChildren(folder_id) {
            return this.folders.filter(
                (x_folder) =>
                    x_folder.parent_folder_id == folder_id &&
                    x_folder.id != folder_id
            );
        },
        refreshData(target_folder, page = 1) {
            // this.hideSidebar();
            this.document = null;
            this.target_folder = target_folder;
            this.getDocuments(target_folder, page);
            this.getFolders(target_folder);
        },
        renderPdfViewer(actual_file, showViewer) {
            this.actual_file = actual_file;
            this.showViewer = showViewer;
            this.listview = false;
            this.gridview = false;
            this.openReport = false;
        },
        async postRecents(item, type) {
            const response = await axios.post(
                "/recent-views",
                JSON.stringify({
                    item_id: item.id,
                    type: type,
                }),
                {
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                        "Content-type": "application/json",
                    },
                }
            );
            const data = await response.data;
            console.log(data.message);
        },
        async fetchDocumentRecents() {
            try {
                const response = await axios.get("/recent-views", {
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                        "Content-type": "application/json",
                    },
                });
                const data = await response.data;
                let documents = data.filter((item) => item.type == "document");
                this.recent_documents = documents.map(({ item_id }) => item_id);
            } catch (error) {
                console.error("Error fetching Recents:", error);
                // Handle errors gracefully
            }
        },
        async fetchFolderRecents() {
            try {
                const response = await axios.get("/recent-views", {
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                        "Content-type": "application/json",
                    },
                });
                const data = await response.data;
                let folders = data.filter((item) => item.type == "folder");
                this.recent_folders = folders.map(({ item_id }) => item_id);
            } catch (error) {
                console.error("Error fetching Recents:", error);
                // Handle errors gracefully
            }
        },
        async postBookMarks(item, type) {
            try {
                const response = await axios.post(
                    "/bookmarks",
                    {
                        item_id: item.id,
                        type: type,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${this.token}`,
                            "Content-type": "application/json",
                        },
                    }
                );
                const data = response.data;
                console.log(data);
            } catch (error) {
                console.error("Error posting bookmark:", error);
            }
        },
        async fetchFolderBookmarks() {
            try {
                const response = await axios.get("/bookmarks", {
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                        "Content-type": "application/json",
                    },
                });
                const data = await response.data;
                let folders = data.filter((item) => item.type == "folder");
                this.bookmark_folders = folders.map(({ item_id }) =>
                    parseInt(item_id)
                );
            } catch (error) {
                console.error("Error fetching bookmarks:", error);
            }
        },
        async fetchDocumentBookmarks() {
            try {
                const response = await axios.get("/bookmarks", {
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                        "Content-type": "application/json",
                    },
                });
                const data = await response.data;
                let documents = data.filter((item) => item.type == "document");
                this.bookmark_documents = documents.map(({ item_id }) =>
                    parseInt(item_id)
                );
            } catch (error) {
                console.error("Error fetching bookmarks:", error);
                // Handle errors gracefully
            }
        },
        sortBy(columnKey) {
            if (this.sortedColumn == columnKey) {
                this.sortOrder = this.sortOrder === "asc" ? "desc" : "asc";
            } else {
                this.sortedColumn = columnKey;
                this.sortOrder = "asc";
            }
        },
        applyDocumentFilters(documentFilters) {
            console.log("Received document filters:", documentFilters);

            let filteredDocuments = this.documents;
            console.log("Original documents:", filteredDocuments);

            // Apply filters based on the received documentFilters
            if (documentFilters) {
                filteredDocuments = filteredDocuments.filter((doc) => {
                    console.log("Checking document:", doc.id);

                    // Check if the document id matches the filter criteria
                    if (documentFilters.id && doc.id == documentFilters.id) {
                        console.log("Document ID match found:", doc.id);
                        return true;
                    }

                    // Check if the id exists in nested arrays or objects
                    if (doc.doc_fields && documentFilters.id) {
                        for (const field of doc.doc_fields) {
                            console.log("Checking field:", field.id);
                            if (field.id == documentFilters.id) {
                                console.log("Field ID match found:", field.id);
                                return true;
                            }
                        }
                    }

                    // Check if the creator's name matches the filter criteria
                    if (documentFilters.creatorName) {
                        if (doc.document_versions) {
                            const match = doc.document_versions.some(
                                (version) => {
                                    console.log(
                                        "Checking version creator:",
                                        version.created_by.name
                                    );
                                    return (
                                        version.created_by.name ===
                                        documentFilters.creatorName
                                    );
                                }
                            );
                            if (match) {
                                console.log(
                                    "Creator name match found:",
                                    documentFilters.creatorName
                                );
                                return true;
                            }
                        }
                    }

                    // Check if any version name matches the filter criteria
                    if (documentFilters.versionName) {
                        if (doc.document_versions) {
                            const match = doc.document_versions.some(
                                (version) => {
                                    console.log(
                                        "Checking version:",
                                        version.version_name
                                    );
                                    return (
                                        version.version_name ===
                                        documentFilters.versionName
                                    );
                                }
                            );
                            if (match) {
                                console.log(
                                    "Version name match found:",
                                    documentFilters.versionName
                                );
                                return true;
                            }
                        }
                    }

                    // If no filters match, return false
                    return false;
                });
            }

            console.log("Filtered documents:", filteredDocuments);
            return filteredDocuments;
        },

        async parseFile(target_document) {
            try {
                let file_name = target_document.document_versions.find(
                    (version) => version.main_file == true
                ).physical_path;
                const fetchResponse = await axios.get(
                    "showPdf/" + file_name.split("/")[2],
                    {
                        headers: {
                            Authorization: `Bearer ${this.token}`,
                            "Content-Type": "application/json",
                            "Access-Control-Allow-Origin": "*",
                            "Access-Control-Allow-Methods":
                                "GET,POST,PATCH,OPTIONS",
                        },
                        responseType: "arraybuffer", // specify response type as arraybuffer
                    }
                );
                // const pdfArrayBuffer = await fetchResponse.arrayBuffer();
                const pdfArrayBuffer = await fetchResponse.data;
                const pdfDoc = await pdfjsLib.getDocument({
                    data: new Uint8Array(pdfArrayBuffer),
                }).promise;
                const page = await pdfDoc.getPage(1);
                const viewport = page.getViewport({ scale: 1 });
                const canvas = document.createElement("canvas");
                const context = canvas.getContext("2d");

                canvas.width = viewport.width;
                canvas.height = viewport.height;

                const renderContext = {
                    canvasContext: context,
                    viewport: viewport,
                };
                await page.render(renderContext).promise;

                const dataUrl = canvas.toDataURL("image/png");
                target_document["path"] =
                    target_document.document_versions.find(
                        (version) => version.main_file == true
                    ).physical_path;
                target_document["dataUrl"] = dataUrl;
                target_document["updated_at"] = new Date(
                    target_document.document_versions.find(
                        (version) => version.main_file == true
                    )
                        ? target_document.document_versions.find(
                              (version) => version.main_file == true
                          ).updated_at
                        : target_document.updated_at
                ).toLocaleDateString();
                target_document["version"] =
                    target_document.document_versions.find(
                        (version) => version.main_file == true
                    )
                        ? target_document.document_versions.find(
                              (version) => version.main_file == true
                          ).version_name
                        : "";
                target_document["size"] =
                    target_document.document_versions.find(
                        (version) => version.main_file == true
                    )
                        ? parseInt(
                              target_document.document_versions.find(
                                  (version) => version.main_file == true
                              ).file_size
                          ).toFixed(4)
                        : "";
                this.thumbnails.push(target_document);
            } catch (ex) {
                console.log("Error in fetch");
                console.log(ex);
            }
        },

        parseDocuments() {
            this.thumbnails = [];
            this.display_thumbnails = [];
            this.documents.forEach((document) => {
                this.parseFile(document);
            });
            let filteredRows = this.thumbnails;
            const searchTermLowerCase = this.searchTerm.toLowerCase();
            if (this.searchTerm) {
                filteredRows = filteredRows.filter((row) =>
                    Object.values(row).some((value) =>
                        value
                            .toString()
                            .toLowerCase()
                            .includes(searchTermLowerCase)
                    )
                );
            }

            // if (this.sortedColumn) {
            // filteredRows = _.orderBy(filteredRows, [this.sortedColumn], [this.sortOrder]);
            //     filteredRows = _.orderBy(filteredRows, ["updated_at"], ["asc"]);
            // }
            this.display_thumbnails = filteredRows;
            // return filteredRows;
        },
        toggleBookmark(targetRow) {
            // Ensure repositoryStore.bookmark_documents is initialized as an array
            this.bookmark_documents = this.bookmark_documents || [];

            const documentId = parseInt(targetRow.id);
            console.log(documentId);

            const index = this.bookmark_documents.indexOf(documentId);
            if (index !== -1) {
                this.bookmark_documents.splice(index, 1);
            } else {
                this.bookmark_documents.push(documentId);
            }
            this.postBookMarks(targetRow, "document");
        },
        toggleFolderBookmark(targetRow) {
            const index = this.bookmark_folders.indexOf(targetRow.id);
            if (index !== -1) {
                this.bookmark_folders.splice(index, 1);
            } else {
                this.bookmark_folders.push(targetRow.id);
            }
            this.postBookMarks(targetRow, "folder");
        },

        async deleteDocument(targetRow) {
            const { isConfirmed } = await Swal.fire({
                title: "Are you sure?",
                text: "Once deleted, you will not be able to recover this File and All It's Contents!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes, delete it!",
                cancelButtonText: "No, cancel!",
                reverseButtons: true,
                showLoaderOnConfirm: true,
            });
            if (isConfirmed) {
                this.deleteDocumentRequest(targetRow);
            } else {
                this.toast.success("File is safe!", {
                    timeout: 5000,
                });
            }
        },
        deleteDocumentRequest(targetRow) {
            axios
                .get(`/folder/documents/delete/${targetRow.id}`, {
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                    },
                })
                .then((response) => {
                    if (response.status !== 200) {
                        throw new Error("Failed to delete document");
                    }
                    return response.data;
                })
                .then((response_data) => {
                    if (response_data.data === 403) {
                        this.toast.error(response_data.message, {
                            timeout: 5000,
                        });
                    } else {
                        this.toast.success(response_data.message, {
                            timeout: 5000,
                        });
                        this.refreshData(targetRow.folder_id);
                    }
                })
                .catch((error) => {
                    console.error("Error deleting document:", error);
                    this.toast.error("Failed to delete document", {
                        timeout: 5000,
                    });
                });
        },

        async deleteFolder(targetRow) {
            const { isConfirmed } = await Swal.fire({
                title: "Are you sure?",
                text: "Once deleted, you will not be able to recover this Folder and All It's Contents!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes, delete it!",
                cancelButtonText: "No, cancel!",
                reverseButtons: true,
                showLoaderOnConfirm: true,
            });
            if (isConfirmed) {
                this.deleteFolderRequest(targetRow);
            } else {
                this.toast.success("Folder is safe!", {
                    timeout: 5000,
                });
            }
        },
        deleteFolderRequest(targetRow) {
            axios
                .get(`/folders/delete/${targetRow.id}`, {
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                    },
                })
                .then((response) => {
                    if (response.status !== 200) {
                        throw new Error("Failed to delete folder");
                    }
                    return response.data;
                })
                .then((response_data) => {
                    if (response_data.data === 403) {
                        this.toast.error(response_data.message, {
                            timeout: 5000,
                        });
                    } else {
                        this.toast.success(response_data.message, {
                            timeout: 5000,
                        });
                        this.refreshData(targetRow.folder_id);
                    }
                })
                .catch((error) => {
                    console.error("Error deleting folder:", error);
                    this.toast.error("Failed to delete folder", {
                        timeout: 5000,
                    });
                });
        },
        Searched(searchResults) {
            this.search_results = searchResults;

            this.search_results.documents.forEach((item) => {
                item.type = "document";
            });

            this.search_results.folders.forEach((item) => {
                item.type = "folder";
            });
        },

        savedSearched(savedSearches) {
            this.search_results_data = savedSearches;
        },
    },
});

// computed: {
//     filteredRows() {
//         let filteredRows = [];
//         let documents = this.$props.documents;
//         let folders = this.$props.folders;
//         if (folders.length || documents.length) {
//             documents.forEach((row) => {
//                 row["name"] = row.document_name ? row.document_name : "";
//                 row["updated_at"] = row.document_versions.find(
//                     (version) => version.main_file == true
//                 )
//                     ? row.document_versions.find(
//                           (version) => version.main_file == true
//                       ).updated_at
//                     : row.updated_at;
//                 row["path"] = row.folder
//                     ? row.folder.path + "/" + row["name"]
//                     : "";
//                 row["size"] = row.document_versions.find(
//                     (version) => version.main_file == true
//                 )
//                     ? parseInt(
//                           row.document_versions.find(
//                               (version) => version.main_file == true
//                           ).file_size
//                       ).toFixed(4)
//                     : "";
//                 row["type"] = "document";
//             });
//             folders.forEach((row) => {
//                 row["name"] = row.name ? row.name : "";
//                 row["updated_at"] = row.updated_at ? row.updated_at : "";
//                 row["path"] = row.path ? row.path : "";
//                 row["size"] = row.documents ? row.documents.length : "";
//                 row["type"] = "folder";
//             });
//             filteredRows = folders.concat(documents);
//             if (this.searchTerm) {
//                 const searchTermLowerCase = this.searchTerm.toLowerCase();
//                 filteredRows = filteredRows.filter((row) =>
//                     Object.values(row).some((value) =>
//                         value
//                             .toString()
//                             .toLowerCase()
//                             .includes(searchTermLowerCase)
//                     )
//                 );
//             }

//             if (this.sortedColumn) {
//                 // const orderMultiplier = this.sortOrder === 'asc' ? 1 : -1;
//                 filteredRows = _.orderBy(
//                     filteredRows,
//                     [this.sortedColumn],
//                     [this.sortOrder]
//                 );
//             }
//         }
//         // console.log(filteredRows);
//         return filteredRows;
//     },
// },
//     mounted() {
//         this.fetchDocumentBookmarks();
//         this.fetchFolderBookmarks();
//         this.toast = useToast();
//     },
//     methods: {
//         highlistItem(item, id) {
//             this.isActive = id;
//             if (item.type == "document") {
//                 this.$emit("update-select-document", item);
//             } else {
//                 this.$emit("update-current_folder", item.id);
//             }
//         },
//         showContextMenu(event, item, index) {
//             console.log(item);
//             event.preventDefault();
//             this.showMenu = true;
//             this.targetRow = item;
//             this.menuX = event.clientX - 130;
//             this.menuY = event.clientY - 170;
//             this.focusIndex = index;
//         },
//         closeContextMenu() {
//             this.showMenu = false;
//         },
//         handleActionClick(action) {
//             const toast = useToast();
//             this.closeContextMenu();
//             switch (action) {
//                 case "open":
//                     this.openItem(this.targetRow);
//                     this.highlistItem(this.targetRow, this.focusIndex);
//                     break;
//                 case "bookmark":
//                     if (this.targetRow.type == "document") {
//                         if (
//                             this.bookmark_documents.includes(
//                                 parseInt(this.targetRow.id)
//                             )
//                         ) {
//                             this.bookmark_documents =
//                                 this.bookmark_documents.filter(
//                                     (item) => item != this.targetRow.id
//                                 ); // Remove the document
//                             let target_doc = this.$props.documents.find(
//                                 (item) => item.id == this.targetRow.id
//                             );
//                             this.$props.documents.splice(target_doc, 1);
//                             this.postBookMarks(this.targetRow, "document");
//                         } else {
//                             this.postBookMarks(this.targetRow, "document");
//                             this.bookmark_documents.push(this.targetRow.id);
//                         }
//                         // localStorage.setItem("bookmark_documents", JSON.stringify(this.bookmark_documents));
//                     } else {
//                         if (
//                             this.bookmark_folders.includes(
//                                 parseInt(this.targetRow.id)
//                             )
//                         ) {
//                             this.bookmark_folders =
//                                 this.bookmark_folders.filter(
//                                     (item) => item != this.targetRow.id
//                                 ); // Remove the folder
//                             let target_folder = this.$props.folders.find(
//                                 (item) => item.id == this.targetRow.id
//                             );
//                             this.$props.folders.splice(target_folder, 1);
//                             this.postBookMarks(this.targetRow, "folder");
//                         } else {
//                             this.postBookMarks(this.targetRow, "folder");
//                             this.bookmark_folders.push(this.targetRow.id);
//                         }
//                         // localStorage.setItem("bookmark_folders", JSON.stringify(this.bookmark_folders));
//                     }
//                     break;
//                 case "edit":
//                     console.log(this.targetRow.type);
//                     if (this.targetRow.type == "folder") {
//                         this.editFolder = this.targetRow;
//                         this.showEditFolder = true;
//                     } else {
//                         this.editDocument = this.targetRow;
//                         this.showEditDocument = true;
//                         break;
//                     }
//                     break;
//                 case "delete":
//                     if (this.targetRow.type == "folder") {
//                         this.$swal
//                             .fire({
//                                 title: "Are you sure?",
//                                 text: "Once deleted, you will not be able to recover this Folder and All It's Contents!",
//                                 icon: "warning",
//                                 showCancelButton: true,
//                                 confirmButtonText: "Yes, delete it!",
//                                 cancelButtonText: "No, cancel!",
//                                 reverseButtons: true,
//                                 showLoaderOnConfirm: true,
//                             })
//                             .then((result) => {
//                                 if (result.isConfirmed) {
//                                     fetch(
//                                         this.baseUrl +
//                                             "/api/folders/delete/" +
//                                             this.targetRow.id,
//                                         {
//                                             method: "GET",
//                                             headers: {
//                                                 Authorization: `Bearer ${this.token}`,
//                                                 "Content-type":
//                                                     "application/json",
//                                             },
//                                         }
//                                     )
//                                         .then((response) => response.json())
//                                         .then((response_data) => {
//                                             if (response_data.data == 403) {
//                                                 // Use it!
//                                                 toast.error(
//                                                     response_data.message,
//                                                     {
//                                                         timeout: 5000,
//                                                     }
//                                                 );
//                                             } else {
//                                                 // Use it!
//                                                 toast.success(
//                                                     "Document Deleted Successfully",
//                                                     response_data.message,
//                                                     {
//                                                         timeout: 5000,
//                                                     }
//                                                 );
//                                                 this.$emit(
//                                                     "get-folder",
//                                                     this.targetRow
//                                                         .parent_folder_id
//                                                 );
//                                             }
//                                         });
//                                     // console.log(response);
//                                     // const data = response.json();
//                                 } else {
//                                     toast.success("Folder is safe!", {
//                                         timeout: 5000,
//                                     });
//                                 }
//                             });
//                     } else {
//                         this.$swal
//                             .fire({
//                                 title: "Are you sure?",
//                                 text: "Once deleted, you will not be able to recover this File and All It's Contents!",
//                                 icon: "warning",
//                                 showCancelButton: true,
//                                 confirmButtonText: "Yes, delete it!",
//                                 cancelButtonText: "No, cancel!",
//                                 reverseButtons: true,
//                                 showLoaderOnConfirm: true,
//                             })
//                             .then((result) => {
//                                 if (result.isConfirmed) {
//                                     fetch(
//                                         this.baseUrl +
//                                             "/api/folder/documents/delete/" +
//                                             this.targetRow.id,
//                                         {
//                                             method: "GET",
//                                             headers: {
//                                                 Authorization: `Bearer ${this.token}`,
//                                                 "Content-type":
//                                                     "application/json",
//                                             },
//                                         }
//                                     )
//                                         .then((response) => response.json())
//                                         .then((response_data) => {
//                                             if (response_data.data == 403) {
//                                                 // Use it!
//                                                 toast.error(
//                                                     response_data.message,
//                                                     {
//                                                         timeout: 5000,
//                                                     }
//                                                 );
//                                             } else {
//                                                 // Use it!
//                                                 toast.success(
//                                                     response_data.message,
//                                                     {
//                                                         timeout: 5000,
//                                                     }
//                                                 );
//                                                 this.$emit(
//                                                     "get-folder",
//                                                     this.targetRow.folder_id
//                                                 );
//                                             }
//                                         })
//                                         .catch((error) => {
//                                             // Handle fetch error
//                                             console.error(
//                                                 "Fetch error:",
//                                                 error
//                                             );
//                                         });
//                                     // console.log(response);
//                                     // const data = response.json();
//                                 } else {
//                                     toast.success("Folder is safe!", {
//                                         timeout: 5000,
//                                     });
//                                 }
//                             });
//                     }
//                     break;
//                 case "properties":
//                     if (this.targetRow.type == "document") {
//                         this.openItem(this.targetRow);
//                     }
//                     this.$emit("show-properties", this.targetRow.type);
//                     this.highlistItem(this.targetRow, this.focusIndex);
//                     break;
//                 // Add cases for other tabs
//                 default:
//                     break;
//             }
//         },
//         async postBookMarks(item, type) {
//             const response = await fetch(this.baseUrl + "/api/bookmarks", {
//                 method: "POST",
//                 headers: {
//                     Authorization: `Bearer ${this.token}`,
//                     "Content-type": "application/json",
//                 },
//                 body: JSON.stringify({
//                     item_id: item.id,
//                     type: type,
//                 }),
//             });
//             const data = await response.json();
//             console.log(data);
//         },
//         sortBy(columnKey) {
//             if (this.sortedColumn == columnKey) {
//                 this.sortOrder = this.sortOrder === "asc" ? "desc" : "asc";
//             } else {
//                 this.sortedColumn = columnKey;
//                 this.sortOrder = "asc";
//             }
//         },
//         openItem(item) {
//             // console.log(item);
//             if (item.type == "folder") {
//                 this.$router.push({
//                     name: "Repository",
//                     // params: { folder: item.id },
//                     query: { folder_id: item.id },
//                     // query: { folder_id: item.id, open_properties: true }
//                 });
//             } else {
//                 let file_name = item.document_versions.find(
//                     (version) => version.main_file == true
//                 ).physical_path;
//                 let path =
//                     this.baseUrl + "/api/showPdf/" + file_name.split("/")[2];
//                 let showViewer = true;
//                 this.$emit("show-viewer", path, showViewer);
//             }
//             // this.$emit('open-folder', this.targetRow);
//         },
//         closeModalHandler() {
//             // Update the prop to close the modal
//             this.showEditFolder = false;
//             this.showEditDocument = false;
//         },
//         refreshData(folder_id) {
//             this.$emit("get-folder", folder_id);
//         },
//         async fetchDocumentBookmarks() {
//             try {
//                 const response = await fetch(this.baseUrl + "/api/bookmarks", {
//                     method: "GET",
//                     headers: {
//                         Authorization: `Bearer ${this.token}`,
//                         "Content-type": "application/json",
//                     },
//                 });
//                 const data = await response.json();
//                 let documents = data.filter((item) => item.type == "document");
//                 this.bookmark_documents = documents.map(({ item_id }) =>
//                     parseInt(item_id)
//                 );
//             } catch (error) {
//                 console.error("Error fetching bookmarks:", error);
//                 // Handle errors gracefully
//             }
//         },
//         async fetchFolderBookmarks() {
//             try {
//                 const response = await fetch(this.baseUrl + "/api/bookmarks", {
//                     method: "GET",
//                     headers: {
//                         Authorization: `Bearer ${this.token}`,
//                         "Content-type": "application/json",
//                     },
//                 });
//                 const data = await response.json();
//                 let folders = data.filter((item) => item.type == "folder");
//                 this.bookmark_folders = folders.map(({ item_id }) =>
//                     parseInt(item_id)
//                 );
//             } catch (error) {
//                 console.error("Error fetching bookmarks:", error);
//                 // Handle errors gracefully
//             }
//         },
//     },
// };
