<template>
    <div id="myModal" class="modalIn">
        <div class="modalIn-content-right">
            <span class="close" @click="closeModal()">&times;</span>
            <h2 id="modalTitle" class="modalIn-title">Add Meta Data Field</h2>
            <!--------------------------------------------------------------------CREATE NEW UPLOAD FILE-------------------------------------------------------------------->
            <form v-on:submit.prevent="editFieldFunc">
                <div class="form-group">
                    <!-- <input type="text" hidden id="folder_name" aria-describedby="folder_name" v-model="newField.id" required> -->
                    <label for="cabinet_id">Field Name:</label>
                    <input v-model="newField.field_name" class="form-control" placeholder="Field Name">
                </div>
                <div class="form-group">
                    <label for="folder_name">Field Datatype:</label>
                    <treeselect v-model="newField.field_datatype" :multiple="false" :clearable="true" :searchable="true"
                                :disabled="disabled" :open-on-click="true" :open-on-focus="true" :clear-on-select="true"
                                :close-on-select="true" :always-open="false" :append-to-body="appendToBody"
                                :options="options"
                                :limit="3" :max-height="200"/>
                </div>
                <button type="submit" class="btn btn-primary">Add Field</button>
            </form>
            <!--------------------------------------------------------------------EDIT CABINET CONTENT-------------------------------------------------------------------->
        </div>
    </div>
</template>

<script>
import {useToast} from "vue-toastification";
// import Treeselect from "vue3-treeselect";
// import "vue3-treeselect/dist/vue3-treeselect.css";
// import the component
import Treeselect from "@zanmato/vue3-treeselect";
// import the styles
import "@zanmato/vue3-treeselect/dist/vue3-treeselect.min.css";
import axios from "axios";

export default {
    components: {Treeselect},
    props: {
        currentFolderFields: {
            type: Array,
            required: true,
        },
        current_folder: {
            type: Object,
            required: true,
        },
        options: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            toast: useToast(),
             baseUrl: this.baseURL,
            token: localStorage.getItem("edms_token"),
            activeTab: 0,
            cabinet_id: null,
            folder_id: null,
            version_name: null,
            cabinet_name: '',
            folder_name: '',
            document_name: '',
            document: null,
            newField: {
                folder_id: null,
                field_name: null,
                field_datatype: null,
                is_serialisation: false,
                serialisation_id: null,
                is_lookup: false,
                lookup_id: null,
                is_mandatory: false,
            },
        };
    },
    methods: {
    async editFieldFunc() {
        try {
            this.newField.folder_id = this.$props.current_folder.id;
            this.newField.is_lookup = this.newField.field_datatype.includes('lookup');
            this.newField.is_serialisation = this.newField.field_datatype.includes('serial');
            this.newField.lookup_id = this.newField.is_lookup ? this.newField.field_datatype.split('-')[1] : null;
            this.newField.serialisation_id = this.newField.is_serialisation ? this.newField.field_datatype.split('-')[1] : null;
            this.newField.field_datatype = this.newField.field_datatype.split('-')[0];

            if (!this.newField.field_name || !this.newField.field_datatype) {
                this.toast.error('Field name and data type are required.', { timeout: 5000 });
                return;
            }

            const exists = this.$props.currentFolderFields.some(field => field.field_name === this.newField.field_name);

            if (exists) {
                this.toast.error('Field with the same name already exists.', { timeout: 5000 });
                return;
            }

            const response = await axios.post('/folder/fields/store', {
                ...this.newField
            }, {
                headers: {
                    'Authorization': `Bearer ${this.token}`,
                    'Content-type': 'application/json'
                }
            });

            const data = response.data;

            if (data.data === 403) {
                this.toast.error(data.message, { timeout: 5000 });
            } else {
                this.toast.success(data.message, { timeout: 1000 });
                this.closeModal();
                this.newField = {
                    folder_id: this.$props.current_folder.id,
                    field_name: '',
                    field_datatype: '',
                    is_serialisation: false,
                    serialisation_id: null,
                    is_lookup: false,
                    lookup_id: null
                };
                this.$emit('update-index-field-data', data.data.data);
            }
        } catch (error) {
            console.error('Error:', error);
            this.toast.error('An error occurred while editing the field.', { timeout: 5000 });
        }
    },

        closeModal() {
            // Emit an event to inform the parent component to close the modal
            this.$emit('close-modal');
        }
    },
};

</script>

<style scoped>
.tab {
    cursor: pointer;
    padding: 10px;
}

.tab.active {
    background-color: #355B11;
    color: white;
}
</style>
