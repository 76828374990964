<template>
    <div id="myModal" class="modalIn">
        <div class="modalIn-content-right">
            <span class="close" @click="closeModal()">&times;</span>
            <h2 id="modalTitle" class="modalIn-title">
                Update Meta Data Field
            </h2>
            <!--------------------------------------------------------------------CREATE NEW UPLOAD FILE-------------------------------------------------------------------->
            <form v-on:submit.prevent="editFieldFunc">
                <div class="form-group">
                    <!-- <input type="text" hidden id="folder_name" aria-describedby="folder_name" v-model="targetField.id" required> -->
                    <label for="cabinet_id">Field Name:</label>
                    <input
                        v-model="targetField.field_name"
                        class="form-control"
                        placeholder="Field Name"
                        required
                    />
                </div>
                <div class="form-group">
                    <label for="folder_name">Field Name:</label>
                    <treeselect
                        v-model="targetField.field_datatype"
                        :multiple="false"
                        :clearable="true"
                        :searchable="true"
                        :disabled="disabled"
                        :open-on-click="true"
                        :open-on-focus="true"
                        :clear-on-select="true"
                        :close-on-select="true"
                        :always-open="false"
                        :append-to-body="appendToBody"
                        :options="options"
                        :limit="3"
                        :max-height="200"
                        :required="true"
                    />
                </div>
                <button type="submit" class="btn btn-primary">
                    Update Field
                </button>
            </form>
            <!--------------------------------------------------------------------EDIT CABINET CONTENT-------------------------------------------------------------------->
        </div>
    </div>
</template>

<script>
import { useToast } from "vue-toastification";
// import Treeselect from "vue3-treeselect";
// import "vue3-treeselect/dist/vue3-treeselect.css";
// import the component
import Treeselect from "@zanmato/vue3-treeselect";
// import the styles
import "@zanmato/vue3-treeselect/dist/vue3-treeselect.min.css";
import axios from "axios";

export default {
    components: { Treeselect },
    props: {
        currentFolderFields: {
            type: Array,
            required: true,
        },
        editField: {},
        options: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            targetField: this.$props.editField,
            toast: useToast(),
            baseUrl: this.baseApiUrl,
            token: localStorage.getItem("edms_token"),
            activeTab: 0,
            cabinet_id: null,
            folder_id: null,
            version_name: null,
            cabinet_name: "",
            folder_name: "",
            document_name: "",
            document: null,
            showEditModal: false,
        };
    },
    methods: {
        async editFieldFunc() {
            this.targetField.is_lookup =
                this.targetField.field_datatype.includes("lookup");
            this.targetField.is_serialisation =
                this.targetField.field_datatype.includes("serial");
            this.targetField.lookup_id = this.targetField.is_lookup
                ? this.targetField.field_datatype.split("-")[1]
                : null;
            this.targetField.serialisation_id = this.targetField
                .is_serialisation
                ? this.targetField.field_datatype.split("-")[1]
                : null;
            this.targetField.field_datatype =
                this.targetField.field_datatype.split("-")[0];

            const exists = this.$props.currentFolderFields.some(
                (field) =>
                    field.field_name === this.targetField.field_name &&
                    field.id !== this.targetField.id
            );
            // continue using exists variable as needed
            if (exists) {
                this.toast.error("Field with the same name already exists.", {
                    timeout: 5000,
                });
                return;
            }

            const response = await axios.post(
                "/folder/fields/update/" + this.targetField.id,
                {
                    ...this.targetField,
                },
                {
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                        "Content-type": "application/json",
                    },
                }
            );
            const data = await response.data;
            // console.log(data);
            if (data.data == 403) {
                // Use it!
                this.toast.error(data.message, {
                    timeout: 5000,
                });
            } else {
                // Use it!
                this.toast.success(data.message, {
                    timeout: 5000,
                });
                this.$emit("update-index-field-data", data.data.data);
            }
            this.closeModal();
        },
        closeModal() {
            // Emit an event to inform the parent component to close the modal
            this.$emit("close-modal");
        },
    },
};
</script>

<style scoped>
.tab {
    cursor: pointer;
    padding: 10px;
}

.tab.active {
    background-color: #355b11;
    color: white;
}
</style>
