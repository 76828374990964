// DocumentViewer.vue
<template>
	<div class="x y">
		<vue-pdf-app style="height: 100vh;" :pdf="the_file" theme="light" :config="config"></vue-pdf-app>
		<!-- <ThumbnailsViewer :thumbnails="thumbnails" /> -->
		<!-- <img :src="thumbnail" alt="Thumbnail" style="max-width: 100px; max-height: 200px; margin: 10px;" /> -->
	</div>
</template>

<script setup lang="js">
import VuePdfApp from 'vue3-pdf-app';
import "vue3-pdf-app/dist/icons/main.css";
import {defineProps, ref} from "vue";


const props = defineProps(['actual_file']);

const the_file = ref(props.actual_file);
const config = {
				toolbar: {
					toolbarViewerLeft: {
						previous: true
					}
				},
				title: "true"
			};
</script>

<style scoped></style>
