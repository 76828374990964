<template>
    <div class="x y">
        <!-- Content of Registered Cabinets -->
        <div class="card elevation-3">
            <div class="card-header">
                <h3 class="card-title">
                    <b>{{
                        repositoryStore.current_folder
                            ? repositoryStore.current_folder.name
                            : ""
                    }}</b>
                </h3>
            </div>
            <div class="card-body table-responsive">
                <div class="row">
                     <div class="col-md-12">
                        <div class="d-flex justify-content-between mb-4 align-items-center">
                            <div
                                style="font-weight: bold"
                                @click="showFilterModal"
                            >
                                <input
                                    class="g"
                                    type="button"
                                    v-model="activeTab"
                                />
                                <li
                                    class="taba"
                                    style="
                                    text-align: center;
                                    border-bottom: none !important;
                                    padding: 8px 15px;
                                    background-color: green;
                                    color: white;
                                    border-radius: 5px;
                                "
                                >
                                    <span
                                        class="material-symbols-outlined"
                                        style="float: left;margin-right: 10px"
                                        >filter_alt</span
                                    >
                                    Filter
                                </li>
                            </div>
                            <div
                                class="d-flex align-items-center justify-content-end text-sm font-weight-bold justify-content-between"
                                style="margin-left: 150px"
                            >
                                <!-- search bar -->
                                <SearchForm @search="handleSearch" />
                            </div>
                        </div>
                    </div>
                </div>
                <table class="table table-hover table-bordered">
                    <thead class="thead-light">
                        <tr>
                            <th>#</th>
                            <th @click="repositoryStore.sortBy('document_name')">
                                <div class="d-flex justify-content-between align-items-center" style="cursor:pointer;">
                                Name
                            <div class="d-flex flex-column mr-0 ml-4">
                                  <i class="material-symbols-outlined" 
                                      :style="
                                        (repositoryStore.sortedColumn ==
                                            'document_name' &&
                                        repositoryStore.sortOrder == 'asc') ?
                                        'color:indianred' : ''
                                    ">
                                    arrow_drop_up</i>
                                 <i class="material-symbols-outlined"
                                 :style="
                                        (repositoryStore.sortedColumn ==
                                            'document_name' &&
                                        repositoryStore.sortOrder == 'desc') ?
                                        'color:indianred' : ''
                                    "
                                 >
                                    arrow_drop_down</i>
                                </div>
                            </div>
                            </th>
                            <th @click="repositoryStore.sortBy('updated_at')">
                                <div class="d-flex justify-content-between align-items-center" style="cursor:pointer;">
                                Date Modified
                                <div class="d-flex flex-column mr-0 ml-4">
                                  <i class="material-symbols-outlined" 
                                      :style="
                                        (repositoryStore.sortedColumn ==
                                            'updated_at' &&
                                        repositoryStore.sortOrder == 'asc') ?
                                        'color:indianred' : ''
                                    ">
                                    arrow_drop_up</i>
                                 <i class="material-symbols-outlined"
                                 :style="
                                        (repositoryStore.sortedColumn ==
                                            'updated_at' &&
                                        repositoryStore.sortOrder == 'desc') ?
                                        'color:indianred' : ''
                                    "
                                 >
                                    arrow_drop_down</i>
                            </div>
                        </div>
                            </th>
                            <th @click="repositoryStore.sortBy('doc_version_name')">
                                
                                <div class="d-flex justify-content-between align-items-center" style="cursor:pointer;">
                                Version
                                <div class="d-flex flex-column mr-0 ml-4">
                                  <i class="material-symbols-outlined" 
                                      :style="
                                        (repositoryStore.sortedColumn ==
                                            'doc_version_name' &&
                                        repositoryStore.sortOrder == 'asc') ?
                                        'color:indianred' : ''
                                    ">
                                    arrow_drop_up</i>
                                 <i class="material-symbols-outlined"
                                 :style="
                                        (repositoryStore.sortedColumn ==
                                            'doc_version_name' &&
                                        repositoryStore.sortOrder == 'desc') ?
                                        'color:indianred' : ''
                                    "
                                 >
                                    arrow_drop_down</i>
                            </div>
                        </div>
                            </th>
                            <th @click="repositoryStore.sortBy('doc_file_size')">

                                 <div class="d-flex justify-content-between align-items-center" style="cursor:pointer;">
                                Size (mbs)
                                <div class="d-flex flex-column mr-0 ml-4">
                                  <i class="material-symbols-outlined" 
                                      :style="
                                        (repositoryStore.sortedColumn ==
                                            'doc_file_size' &&
                                        repositoryStore.sortOrder == 'asc') ?
                                        'color:indianred' : ''
                                    ">
                                    arrow_drop_up</i>
                                 <i class="material-symbols-outlined"
                                 :style="
                                        (repositoryStore.sortedColumn ==
                                            'doc_file_size' &&
                                        repositoryStore.sortOrder == 'desc') ?
                                        'color:indianred' : ''
                                    "
                                 >
                                    arrow_drop_down</i>
                            </div>
                        </div>

                            </th>
                            <!-- <th>Supervisor / Modified By</th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(
                                document, id
                            ) in repositoryStore.display_documents"
                            :key="id"
                            :class="{ 'table-active': isActive == document.id }"
                            @contextmenu.prevent="
                                showContextMenu($event, document, id)
                            "
                        >
                            <template
                                v-if="
                                    authStore.allowedAccess(
                                        'view_documents',
                                        null,
                                        document
                                    )
                                "
                            >
                                <td>
                                    <span
                                        v-if="
                                            repositoryStore.bookmark_documents.includes(
                                                document.id
                                            )
                                        "
                                        class="material-symbols-outlined"
                                        style="font-size: 30px"
                                        >book</span
                                    >
                                    <span
                                        class="material-symbols-outlined"
                                        v-else
                                        style="font-size: 30px"
                                        >insert_drive_file</span
                                    >
                                </td>
                                <td @click="highlistItem(document, id)">
                                    {{ document.document_name }}
                                </td>
                                <td @click="highlistItem(document, id)">
                                    {{
                                        new Date(
                                            document.updated_at
                                        ).toLocaleString()
                                    }}
                                </td>
                                <td @click="highlistItem(document, id)">
                                    {{ document.doc_version_name }}
                                </td>
                                <td @click="highlistItem(document, id)">
                                    {{ document.doc_file_size }} MB
                                </td>
                            </template>
                            <!-- <td>{{ document.created_at }}</td> -->
                        </tr>
                        <!-- Overlay to close the menu -->
                        <div
                            class="context_overlay"
                            @click="closeContextMenu"
                            v-if="showMenu"
                        />

                        <!-- Custom Context Menu -->
                        <ContextMenu
                            v-if="showMenu"
                            :actions="contextMenuActions"
                            @action-clicked="handleActionClick"
                            :x="menuX"
                            :y="menuY"
                        />
                    </tbody>
                </table>
            </div>
            <div class="card-footer clearfix">
                <ul class="pagination pagination-sm m-0 float-right">
                    <li class="page-item">
                        <a
                            class="page-link"
                            href="#"
                            @click="
                                repositoryStore.refreshData(
                                    repositoryStore.current_folder.id,
                                    repositoryStore.pagination.meta
                                        .current_page == 1
                                        ? repositoryStore.pagination.meta
                                              .current_page
                                        : repositoryStore.pagination.meta
                                              .current_page - 1
                                )
                            "
                            :disabled="
                                repositoryStore.pagination.meta.current_page ==
                                1
                            "
                            >&laquo;</a
                        >
                    </li>
                    <li class="page-item">
                        <a
                            class="page-link"
                            href="#"
                            @click="
                                repositoryStore.refreshData(
                                    repositoryStore.current_folder.id,
                                    1
                                )
                            "
                            :disabled="
                                repositoryStore.pagination.meta.current_page ==
                                1
                            "
                            >First</a
                        >
                    </li>
                    <span class="page-item page-link"
                        >Page
                        {{ repositoryStore.pagination.meta.current_page }} of
                        {{ repositoryStore.pagination.meta.last_page }}</span
                    >
                    <li class="page-item">
                        <a
                            class="page-link disabled"
                            href="#"
                            @click="
                                repositoryStore.refreshData(
                                    repositoryStore.current_folder.id,
                                    repositoryStore.pagination.meta.last_page
                                )
                            "
                            >Last</a
                        >
                    </li>
                    <li class="page-item">
                        <a
                            class="page-link"
                            href="#"
                            @click="
                                repositoryStore.refreshData(
                                    repositoryStore.current_folder.id,
                                    repositoryStore.pagination.meta
                                        .current_page ==
                                        repositoryStore.pagination.meta
                                            .last_page
                                        ? repositoryStore.pagination.meta
                                              .current_page
                                        : repositoryStore.pagination.meta
                                              .current_page + 1
                                )
                            "
                            >&raquo;</a
                        >
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <EditDocument
        :editDocument="editDocument"
        v-if="showEditDocument"
        @close-modal="closeModalHandler"
        :folders="repositoryStore.folders"
        @get-folder="repositoryStore.refreshData"
        :current_folder="repositoryStore.current_folder"
    />
    <Filter
        v-if="showFilter"
        @close-modal="closeModalHandler"
        @applyDocumentFilters="handleApplyDocumentFilters"
        @applyMetadataFilters="handleApplyDocumentFilters"
    />
</template>

<script setup lang="js">
// import { inject } from 'vue'
import {onMounted, ref} from "vue";

import ContextMenu from "@/components/ContextMenu.vue";
import SearchForm from "@/components/SearchForm.vue";
import Filter from "@/components/modals/DocumentFilter.vue";
import EditDocument from "@/components/modals/EditDocument.vue";
import {useRepository} from "@/stores/repository";
import axios from "axios";
import {useAuth} from "@/stores/auth";

const authStore = useAuth();
const repositoryStore = useRepository();

const baseUrl = `${axios.defaults.baseURL}`;

// const baseUrl = this.baseApiUrl;
let showMenu = ref(false);
let menuX = ref(0);
let menuY = ref(0);
let targetRow = ref({});
const contextMenuActions = ref([]);
let isActive = ref(null);
let showEditDocument = ref(false);
let showFilter = ref(false);
let editDocument = ref({});


function openItem(item) {
    let path = baseUrl + "/showPdf/" + item.path.split("/")[2];
    let showViewer = true;
    repositoryStore.renderPdfViewer(path, showViewer);
}

function highlistItem(document) {
    // console.log(document);
    isActive.value = document.id;
    repositoryStore.updateSelectDocument(document);
}

function showContextMenu(event, document) {
    event.preventDefault();
    showMenu.value = true;
    targetRow.value = document;
    menuX = event.clientX - 360;
    menuY = event.clientY - 180;
    highlistItem(document);
}


function closeContextMenu() {
    showMenu.value = false;
}

function handleActionClick(action) {
    closeContextMenu();
    switch (action) {
        case "open":
            openItem(targetRow.value);
            highlistItem(targetRow.value);
            break;
        case "bookmark":
            repositoryStore.toggleBookmark(targetRow.value);
            break;
        case "edit":
            editDocument.value = targetRow.value;
            showEditDocument.value = true;
            break;
        case "delete":
            repositoryStore.deleteDocument(targetRow.value);
            break;
        case "properties":
            openItem(targetRow.value);
            repositoryStore.showProperties("document")
            highlistItem(targetRow.value);
            break;
        // Add cases for other tabs
        default:
            break;
    }
}

function closeModalHandler() {
    // Update the prop to close the modal
    showEditDocument.value = false;
    showFilter.value = false;
}

function showFilterModal() {
    showFilter.value = true;
}
function handleApplyDocumentFilters(filters) {
  // console.log('Received filters:', filters);
  repositoryStore.getDocuments(repositoryStore.current_folder.id, 1, filters);
}

function handleSearch(){
// console.log(event.target.value)
repositoryStore.searchTerm = event.target.value
}

onMounted(async () => {
    repositoryStore.fetchDocumentBookmarks();
    if (await authStore.allowedAccess("view_documents")) {
        contextMenuActions.value.push({label: "Open", action: "open"})
    }
    if (await authStore.allowedAccess("edit_documents")) {
        contextMenuActions.value.push({label: 'Edit', action: 'edit'})
    }
    if (await authStore.allowedAccess("delete_documents")) {
        contextMenuActions.value.push({label: 'Delete', action: 'delete'})
    }
    if (await authStore.allowedAccess("view_document_properties")) {
        contextMenuActions.value.push({label: 'Properties', action: 'properties'})
    }
    contextMenuActions.value.push({label: 'Bookmark', action: 'bookmark'})
});
</script>

<style scoped>
.context_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    z-index: 49;
}

.context_overlay::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
}

/* .context_overlay:hover {
  cursor: pointer;
} */
.comparision_icon {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}

.form-control {
    padding-left: 2.375rem;
}

.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
}
</style>
