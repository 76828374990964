<template>
    <div id="myModal" class="modalIn">
        <div class="modalIn-content-right mt-5 mr-5">
            <span class="close" @click="closeModal()">&times;</span>
            <h2 id="modalTitle" class="modalIn-title">Upload Stamp</h2>

            <div v-if="uploadStamp">
                <form v-on:submit.prevent="uploadStamp">
                    <div class="form-group">
                        <label for="photo">Select Stamp:</label>
                        <input
                            type="file"
                            ref="stampFileInput"
                            name="photo"
                         />
                    </div>
                    <div style="text-align: right;">
                    <button
                        type="submit"
                        name="uploadPhoto"
                        class="btn "
                        style="background: #355b11; color: white;border-radius: 5px; "
                    >
                        Upload 
                    </button>
                </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import { useToast } from "vue-toastification";
import axios from "axios";

export default {
    data() {
        return {
            toast: useToast(),
            baseUrl: this.baseApiUrl,
        };
    },
    methods: {
        closeModal() {
            this.$emit("close-modal");
        },
        async uploadStamp() {
            try{
            const stampFileInput = this.$refs.stampFileInput;
            const files = stampFileInput.files;

            if (!files || files.length === 0) {
                this.toast.error("Please select a stamp image to upload", {
                    timeout: 2000,
                });

                return;
            }

            const selectedFile = files[0];
            const formData = new FormData();
            formData.append("user_stamp", selectedFile);

            const bearerToken = localStorage.getItem("edms_token");

            if (!bearerToken) {
                console.error("Unauthorized");
                return;
            }

            // Get the logged-in user ID from the JWT token
            const userId = localStorage.getItem("userId");

            const response = await axios.post(`/users/update/${userId}`,
            formData,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem(
                        "edms_token"
                    )}`,
                },
            })
                const data = await response.data
                    console.log(data);
                    if(data.success == true){
                    this.closeModal();
                    this.toast.success(data.message, {
                        timeout: 4000,
                    });
                    stampFileInput.value = "";
                }else{
                      this.toast.error(data.message, {
                        timeout: 4000,
                    });
                }
                
                }catch(error){
                    console.error("Error uploading stamp image:", error);
                    this.toast.error("Error uploading stamp", {
                            timeout: 2000,
                        });
                }
        },
    },
};
</script>
<style scoped></style>
