<template>
    <div id="myModal" class="modalIn">
        <div class="modalIn-content-right" style="float: right">
            <span class="close" @click="closeModal()">&times;</span>
            <h2 id="modalTitle" class="modalIn-title">Create New Action</h2>
            <!---------------------------------------------------------------------------------------------------------------------------------------->
            <form v-on:submit.prevent="addWorkStepFunc">
                <div
                    class="form-group"
                    v-if="targetWorkstep.workstep_type == 'Function'"
                >
                    <label for="Select Action Name">Select Action</label>
                    <select
                        class="form-control"
                        id="Select Action Name"
                        name="name"
                        required
                        v-model="newPossibleAction.name"
                    >
                        <option disabled value="null">Select Action</option>
                        <option value="success">Success</option>
                        <option value="failed">Failed</option>
                    </select>
                </div>
                <div
                    class="form-group"
                    v-if="targetWorkstep.workstep_type == 'Action'"
                >
                    <label for="name">Action Name</label>
                    <input
                        type="text"
                        class="form-control"
                        id="name"
                        placeholder="Add Action Name"
                        name="action_name"
                        required
                        v-model="newPossibleAction.name"
                    />
                </div>
                <div
                    class="form-group"
                    v-if="targetWorkstep.workstep_type == 'Action'"
                >
                    <label for="description">Description</label>
                    <input
                        type="text"
                        class="form-control"
                        id="description"
                        placeholder="Add Description"
                        name="description"
                        required
                        v-model="newPossibleAction.description"
                    />
                </div>
                <input
                    type="text"
                    id="workstep_id"
                    name="workstep_id"
                    hidden
                    v-model="newPossibleAction.workstep_id"
                />
                <div class="form-group">
                    <label for="Select Next WorkStep">Next WorkStep</label>
                    <select
                        class="form-control"
                        id="next_workstep_id"
                        name="next_workstep_id"
                        required
                        v-model="newPossibleAction.next_workstep_id"
                    >
                        <option disabled value="null">
                            Select Next WorkStep
                        </option>
                        <option
                            v-for="workstep in worksteps"
                            :key="workstep.id"
                            :value="workstep.id"
                        >
                            {{ workstep.activity }} --
                            {{ workstep.description }}
                        </option>
                    </select>
                </div>
                <div
                    class="form-group"
                    v-if="targetWorkstep.workstep_type == 'Action'"
                >
                    <label for="Button Color">Button Color</label>
                    <input
                        type="color"
                        class="form-control"
                        id="button_color"
                        name="button_color"
                        v-model="newPossibleAction.button_color"
                        requied
                    />
                </div>
                <div style="text-align: right">
                    <button type="submit" class="btn btn-success">
                        Create Action
                    </button>
                </div>
            </form>
            <!---------------------------------------------------------------------------------------------------------------------------------------->
        </div>
    </div>
</template>

<script>
import { useToast } from "vue-toastification";
import axios from "axios";

export default {
    props: {
        targetWorkstep: {},
        worksteps: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            toast: useToast(),
            baseUrl: this.baseApiUrl,
            token: localStorage.getItem("edms_token"),
            document: null,
            version: this.document_version,
            showEditModal: false,
            newPossibleAction: {
                name: null,
                description: null,
                workstep_id: this.$props.targetWorkstep.id,
                next_workstep_id: null,
                button_color: null,
            },
        };
    },
    // mounted() {
    //     this.version = this.$props.editDocument.document_versions ? this.$props.editDocument.document_versions.find(version => version.main_file == true).version_name : null;
    // },
    methods: {
        async addWorkStepFunc() {
            const response = await axios.post("/workstep/possibleactions/store",
            {
                        name: this.newPossibleAction.name,
                        description: this.newPossibleAction.description
                            ? this.newPossibleAction.description
                            : this.newPossibleAction.name,
                        workstep_id: this.newPossibleAction.workstep_id,
                        next_workstep_id:
                            this.newPossibleAction.next_workstep_id,
                        button_color: this.newPossibleAction.button_color
                            ? this.newPossibleAction.button_color
                            : "#355b11",
                    },
                {
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                        "Content-type": "application/json",
                    },
                }
            );
            const data = await response.data;
            console.log(data);
            if (!response.ok) {
                // Use it!
                this.toast.error("Please fill all fields", {
                    timeout: 5000,
                });
            } else {
                // Use it!
                this.toast.success(data.message, {
                    timeout: 5000,
                });
                this.$emit(
                    "update-actions",
                    this.$props.targetWorkstep.folder_id
                );
                this.closeModal();
            }
        },
        closeModal() {
            // Emit an event to inform the parent component to close the modal
            this.$emit("close-modal");
        },
    },
};
</script>

<style scoped>
.tab {
    cursor: pointer;
    padding: 10px;
}

.tab.active {
    background-color: #355b11;
    color: white;
}
</style>
