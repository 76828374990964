<template>
    <!-- ******************************Create Role modal********************************** -->
    <div id="myModal" class="modalIn">
        <div class="modalIn-content-right mt-5 mr-5">
            <div class="modal-header border-bottom-0">
                <h5 class="modal-title" id="serializerModalLabel">Change Password</h5>
                <button type="button" class="close" @click="closeModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <p class="success" >{{passwordChange}}
            <span class="errors" >{{passwordNot}}</span> 
            </p>
            <form @submit.prevent="changePassword">
                <div class="modal-body">
                    <div class="form-group">
                        <label for="name">Current Password:</label>
                        <input
                            v-model="formData.current"
                            :type="showPassword ? 'text' : 'password'"
                            class="form-control"
                            ref="current"
                            id="name"
                            name="name"
                            placeholder="Enter Current Password"
                            :style="passwordNot ? 'border:1px solid red' : 'border:1px solid gray-500'"                            
                        />
                    </div>
                    <div class="form-group">
                        <label for="escription">New Password:</label>
                        <input
                            v-model="formData.newPass"
                            :type="showPassword ? 'text' : 'password'"
                            class="form-control"
                            id="escription"
                            name="escription"
                            placeholder="Enter New Password"
                            
                        />
                    </div>
                    <div class="form-group">
                        <label for="confirm">Confirm Password:</label>
                        <input
                            v-model="formData.confirm"
                            :type="showPassword ? 'text' : 'password'"
                            class="form-control"
                            id="confirm"
                            name="confirm"
                            placeholder="Confirm New Password"
                            
                        />
                    </div>
                    <div class="visibility">
                        <input type="checkbox" name="showPassword" v-model="showPassword" id="showPassword" />&nbsp;
                        <span for="showPassword" @click="togglePasswordVisibility()">Show Password</span>
                    </div>
                </div>
                <div
                    class="modal-footer border-top-0 d-flex justify-content-center"
                >
                    <button
                        type="submit"
                        class="btn"
                        id="roleSubmitBtn"
                        style="background: #355b11; color: white;width: 100%; border-radius: 5px;"
                    >
                        Submit Changes
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
import { useToast } from "vue-toastification";
import axios from "axios";

export default {
    data() {
        return {
            baseUrl: this.apiBaseUrl,
            toast: useToast(),
            showPassword: false,
            passwordChange:"",
            passwordNot:"",
            formData: {
                current: "",
                newPass: "",
                confirm:"",
            },
        };
    },
    methods: {
        closeModal() {
            this.$emit("close-modal");
        },
        togglePasswordVisibility() {
        this.showPassword = !this.showPassword;
        },
         async changePassword() {
            if (this.formData.current == "") {
                this.toast.error("Current password is required", {
                    timeout: 2000,
                });
                return;
            }
            if (this.formData.newPass == "") {
                this.toast.error("New password is required", {
                    timeout: 2000,
                });
                return;
            }
            if (this.formData.newPass != this.formData.confirm) {
                this.toast.error("Passwords do not match", {
                    timeout: 2000,
                });
                return;
            }
            try{

                const response = await axios.post(
                    "/changepassword",
                    {
                        current_password: this.formData.current,
                        new_password: this.formData.newPass,
                        new_password_confirmation: this.formData.confirm,
                    },
                    {
                         headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${localStorage.getItem(
                                "edms_token"
                            )}`,
                        },
                    }
                );
                const data = await response.data;
                console.log(data)
                if (data.success == true) {
                    this.passwordChange = data.message
                    setTimeout(() => {
                    this.passwordChange = ''
                    this.closeModal()
                    }, 2000); 
                }
            }catch(error){
                console.error("Error:", error);
                if (error.response && error.response.data && error.response.data.data == 422) {
                   this.passwordNot = error.response.data.message
                   setTimeout(() => {
                    this.passwordNot = ''
                    }, 6000); 
                }
            }
        },
    }
}
</script>
<style scoped>
    .visibility{
    display: flex;
    cursor: pointer;
    color: #495057;
    font-size: 15px;
}
.success{
    display: flex;
    align-items: center; 
    justify-content: center; 
    color: green; 
    font-size: 15px
}
.errors{
    display: flex;
    align-items: center; 
    text-align: center; 
    justify-content: center; 
    color: red; 
    font-size: 15px
}
</style>