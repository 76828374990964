<template>
    <div id="myModal" class="modalIn">
        <div class="modalIn-content-right mt-5 mr-5">
            <span class="close" @click="closeModal()">&times;</span>
            <h2 id="modalTitle" class="modalIn-title">Upload Profile Photo</h2>

            <div v-if="uploadPhoto">
                <form v-on:submit.prevent="uploadPhoto">
                    <div class="form-group">
                        <label for="photo">Select Profile Photo:</label> &nbsp;
                        <input type="file" ref="photoFileInput" name="photo" />
                    </div>
                    <div style="text-align: right;">
                    <button
                        type="submit"
                        name="uploadPhoto"
                        class="btn"
                        style="background: #355b11; color: white;border-radius: 5px; "
                    >
                        Upload
                    </button>
                </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import { useToast } from "vue-toastification";
import axios from "axios";

export default {
    data() {
        return {
            toast: useToast(),
            baseUrl: this.apiBaseUrl,
        };
    },
    methods: {
        closeModal() {
            this.$emit("close-modal");
        },
        async uploadPhoto() {
            const fileInput = this.$refs.photoFileInput;
            const files = fileInput.files;

            console.log(files);

            if (!files || files.length === 0) {
                this.toast.error("Please select a file to upload", {
                    timeout: 2000,
                });
            } else {
                try{
                const selectedFile = files[0];
                const formData = new FormData();
                console.log(formData);
                formData.append("user_profile", selectedFile);

                // Get the logged-in user ID from the JWT token
                const userId = localStorage.getItem("userId");

                const response = await axios.post(`/users/update/${userId}`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "edms_token"
                        )}`,
                    },
                })
                    const data = await response.data
                    console.log(data)
                    if(data.success == true){
                        this.photoUrl = data.newPhotoUrl;
                         this.$emit('photo-uploaded',this.photoUrl);
                        this.closeModal();
                        this.toast.success(data.message, {
                            timeout: 3000,
                        });
                        fileInput.value = "";
                    }else{
                        this.toast.error(data.message, {
                            timeout: 5000,
                        });
                    }
                    }catch(error){
                        console.error("Error uploading photo:", error);
                        this.toast.error("Error uploading photo", {
                            timeout: 2000,
                        });
                    }
            }
        },
    },
};
</script>
<style scoped></style>
