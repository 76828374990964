<template>
    <div>
        <div class="menu-toggle-wrap">
            <!-- Icon inside the sidebar -->
        </div>

        <!-- Sidebar -->
        <div
            class="sidebar-right-static sidebar-mini layout-navbar-fixed layout-fixed text-sm sidebar-light-indigo elevation-4"
        >
            <!-- Add content for right sidebar here -->
            <nav class="mt-2">
                <ul style="text-align: center;" 
                    class="nav nav-pills nav-sidebar flex-column"
                    data-widget="treeview"
                    role="menu"
                    data-accordion="false"
                >
                    <li class="nav-item tab">
                        <a  >
                            <span
                               
                            >
                               <img
                                    :src="settingStore.userData.photoUrl"
                                    alt="User Photo"
                                    style="
                                    width: 40px;
                                    height: 40px;
                                    border-radius: 50%;
                                    bottom: 10px;
                                "
                                />
                            </span>
                        </a>
                        <ul class="dropdown-menuT1">
                            <span style="font-size: 16px; margin-left: 18px;margin-bottom: 6px; border-bottom: 1px solid black"><b>{{settingStore.userData.name}}</b></span>
                            <li @click="openPhotoModal = true"><span class="material-symbols-outlined" style="float: left"
                        >account_circle</span
                    >
                    &nbsp;Profile Photo</li>
                            <li @click="openStampModal = true">
                            <span class="material-symbols-outlined" style="float: left"
                        >label</span
                    >
                    &nbsp;Upload Stamp</li>
                            <li @click="openSignatureModal = true">
                                <span class="material-symbols-outlined" style="float: left"
                        >edit</span
                    >
                    &nbsp;Upload Signature
                            </li>
                            <li @click="openPasswordModal = true">
                                <span class="material-symbols-outlined" style="float: left"
                        >settings</span
                    >
                    &nbsp;Change Password
                            </li>
                            <li style="color: indianred;" @click="logout"><span class="material-symbols-outlined" style="float: left"
                        >logout</span
                    >
                    &nbsp;Sign Out</li>
                        </ul>
                    </li>
                    <li class="nav-item" style="margin-top: 20px;"></li>
                    <li class="nav-item" @click="notificationStore.toggleNotificationModal()">
                        <a class="nav-link">
                            <span
                                class="material-symbols-outlined"
                                style="font-size: 20px"
                                >notifications</span
                            >
                            <span
                                class="badge badge-pill badge-danger"
                                style="
                                    position: absolute;
                                    top: -8px;
                                    right: 20px;
                                "
                                >{{ notificationStore.unread_notifications.length}}</span
                            >
                        </a>
                    </li>
                </ul>
            </nav>
            <Upload v-if="openPhotoModal" @close-modal="closeModal" @photo-uploaded="updateUserPhoto" />
            <UploadStamp v-if="openStampModal" @close-modal="closeModal" />
            <UploadSignature
                v-if="openSignatureModal"
                @close-modal="closeModal"
            />
             <ChangePassword
                v-if="openPasswordModal"
                @close-modal="closeModal"
            />

            <Notification
                v-if="notificationStore.showNotificationModal"
                :style="{ width: notificationStore.showNotificationModal ? '18%' : '0%' }"
            />
        </div>
    </div>
</template>


<script setup lang="js">
import { ref,onMounted } from 'vue';
import Upload from "@/components/UploadModal.vue";
import UploadStamp from "@/components/modals/UploadStamp.vue";
import UploadSignature from "@/components/modals/UploadSignature.vue";
import ChangePassword from "@/components/modals/ChangePassword.vue";
import Notification from "@/components/modals/UserNotifications.vue";
import { useNotification } from "@/stores/notification";
import { useSetting } from "@/stores/settings";
import { useAuth } from "@/stores/auth";

const authStore = useAuth();
const notificationStore = useNotification();
const settingStore = useSetting();
const openPhotoModal = ref("");
const openSignatureModal = ref("");
const openPasswordModal = ref("");
const openStampModal = ref("");


const closeModal = () => {
    openPhotoModal.value = "";
    openStampModal.value = "";
    openSignatureModal.value = "";
    openPasswordModal.value =  "";
};

const logout = () => {
    authStore.logout();
};
const updateUserPhoto =(async () => {
    await settingStore.getUser();
 });
onMounted(async () => {
    await notificationStore.fetchNotifications();
    await settingStore.getUser();
 });

</script>

<style scoped>
.tab {
    cursor: pointer;
    /* padding: 10px; */
}

.tab.active {
    background-color: #355b11;
    color: white;
}

.dropdown-menuT1 {
    display: none;
    position: absolute;
    color: black;
    z-index: 1000;
    min-width: 200px;
    padding: 15px 0;
    margin: 2px 0 0;
    font-size: 14px;
    text-align: left;
    list-style: none;
    right: 100%;
    top: 2px;
    background-color: #fff;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.dropdown-menuT1 li {
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #ddd;
    font-weight: bolder;
}

.dropdown-menuT1 li:hover {
    background-color: #f5f5f5;
    border-radius: 5px;
}

.tab:hover .dropdown-menuT1,
.tab:focus .dropdown-menuT1 {
    display: block;
}
</style>
