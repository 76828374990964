<template>
    <div>
        <table class="table table-bordered" v-if="document != null">
            <thead class="thead-light">
                <tr>
                    <th>Field Name</th>
                    <th>Value</th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(doc_field, id) in document
                        ? document.doc_fields
                        : []"
                    :key="id"
                    @contextmenu.prevent="showContextMenu($event, doc_field)"
                >
                    <td>{{ doc_field.field.field_name }}</td>
                    <td>{{ doc_field.value }}</td>
                </tr>
            </tbody>
        </table>
        <form v-if="showAddNewRow" v-on:submit.prevent="addItem">
            <div class="form-group">
                <label for="Field_name">Field Name</label>
                <treeselect
                    v-model="newDocField.field_id"
                    :multiple="false"
                    :clearable="true"
                    :searchable="true"
                    :disabled="disabled"
                    :open-on-click="true"
                    :open-on-focus="true"
                    :clear-on-select="true"
                    :close-on-select="true"
                    :always-open="false"
                    :append-to-body="appendToBody"
                    :options="fields_options"
                    :limit="3"
                />
            </div>
            <div class="form-group">
                <label for="value">Value</label>
                <treeselect
                    v-model="newDocField.value"
                    :multiple="false"
                    :clearable="true"
                    :searchable="true"
                    :disabled="disabled"
                    :open-on-click="true"
                    :open-on-focus="true"
                    :clear-on-select="true"
                    :close-on-select="true"
                    :always-open="false"
                    :append-to-body="appendToBody"
                    :options="lookup_options"
                    :limit="3"
                    v-if="showLookupSelector"
                />
                <input
                    v-model="newDocField.value"
                    class="form-control"
                    type="text"
                    placeholder="Serial No."
                    v-else-if="showSerialSelector"
                />
                <input
                    v-model="newDocField.value"
                    class="form-control"
                    type="datetime-local"
                    placeholder="value"
                    v-else-if="showDateSelector"
                />
                <input
                    v-model="newDocField.value"
                    class="form-control"
                    type="text"
                    placeholder="value"
                    v-else
                />
            </div>
            <button type="submit" class="btn btn-success" name="addItem">
                Add
            </button>
        </form>
    </div>

    <!-- Custom Context Menu -->
    <ContextMenu
        v-if="showMenu"
        :actions="contextMenuActions"
        @action-clicked="handleActionClick"
        :x="menuX"
        :y="menuY"
    />
    <EditMetaData
        :editMetaData="editMetaData"
        v-if="showEditField"
        v-on:close-modal="closeModalHandler"
        @update-index-field-data="updateMetaData"
        :fields_options="fields_options"
        :lookup_options="lookup_options"
    />
</template>

<script>
// import Treeselect from "vue3-treeselect";
// import "vue3-treeselect/dist/vue3-treeselect.css";
// import the component
import Treeselect from "@zanmato/vue3-treeselect";
// import the styles
import "@zanmato/vue3-treeselect/dist/vue3-treeselect.min.css";
// import the styles
import ContextMenu from "@/components/ContextMenu.vue";
import EditMetaData from "@/components/modals/EditMetaData.vue";
import { ref } from "vue";
import { useRepository } from "@/stores/repository";
import { useToast } from "vue-toastification";
import axios from "axios";
export default {
    // register the component
    components: {
        Treeselect,
        ContextMenu,
        EditMetaData,
    },
    props: {
        document: {},
        current_folder: {
            type: Object,
            required: true,
        },
        serials: Array,
        lookups: Array,
    },
    data() {
        return {
            toast: useToast(),
            baseUrl: this.baseApiUrl,
            token: localStorage.getItem("edms_token"),
            value: null,
            repositoryStore: useRepository(),
            newDocField: {
                document_id: null,
                field_id: null,
                value: null,
            },
            lookups_bases: [],
            serials_bases: [],
            editMetaData: {},
            showMenu: ref(false),
            menuX: ref(0),
            menuY: ref(0),
            targetRow: ref({}),
            contextMenuActions: ref([
                { label: "Edit", action: "edit" },
                { label: "Delete", action: "delete" },
            ]),
            showEditField: ref(false),
        };
    },
    computed: {
        fields_options() {
            let fields_array = [];
            // define options
            let valued_fields = [];
            if (this.$props.document != null) {
                if (Object.hasOwn(this.$props.document, "doc_fields")) {
                    if (Object.hasOwn(this.$props.document.folder, "fields")) {
                        this.$props.document.folder.fields.forEach((field) => {
                            this.$props.document.doc_fields.forEach(
                                (doc_field) => {
                                    if (field.id == doc_field.field_id) {
                                        valued_fields.push(field.id);
                                    }
                                }
                            );
                        });
                        console.log(this.$props.current_folder);
                        this.repositoryStore.current_folder.fields.forEach(
                            (field) => {
                                if (!valued_fields.includes(field.id)) {
                                    fields_array.push({
                                        id: field.id,
                                        label: field.field_name,
                                    });
                                }
                            }
                        );
                    }
                    return fields_array;
                }
            }
            return [];
        },
        //TODO:: check the is_lookup and lookup_id to find the lookup options
        lookup_options() {
            let lookup_array = [];
            let main_lookup_id =
                this.repositoryStore.current_folder.fields.find(
                    (field) => field.id == this.newDocField.field_id
                ).lookup_id;
            let main_lookup = Array.isArray(this.$props.lookups)
                ? this.$props.lookups.find(
                      (lookup) => lookup.id == main_lookup_id
                  )
                : null;
            console.log("children", main_lookup.children);
            main_lookup.children.forEach((child) => {
                lookup_array.push(this.parseItem(child));
            });
            return lookup_array;
        },
        showLookupSelector() {
            if (this.newDocField.field_id) {
                // return true;
                return this.repositoryStore.current_folder.fields.find(
                    (field) => field.id == this.newDocField.field_id
                ).is_lookup;
            }
            return false;
        },
        showSerialSelector() {
            if (this.newDocField.field_id) {
                // return true;
                // this.generatedSerial(this.$props.current_folder.fields.find(field => field.id == this.newDocField.field_id));
                return this.repositoryStore.current_folder.fields.find(
                    (field) => field.id == this.newDocField.field_id
                ).is_serialisation;
            }
            return false;
        },
        showDateSelector() {
            if (this.newDocField.field_id) {
                // return true;
                return (
                    this.repositoryStore.current_folder.fields.find(
                        (field) => field.id == this.newDocField.field_id
                    ).field_datatype == "Datetime"
                );
            }
            return false;
        },
        showAddNewRow() {
            return this.fields_options.length > 0;
        },
        generatedSerial(field) {
            if (this.showSerialSelector) {
                return this.fetchSerialNumber(field);
            }
            return "";
        },
    },
    mounted() {
        this.$props.lookups.forEach((lookup) => {
            this.lookups_bases.push({ id: lookup.id, label: lookup.name });
        });
        this.$props.serials.forEach((serial) => {
            this.lookups_bases.push({
                id: serial.id,
                label:
                    serial.prefix +
                    " {" +
                    serial.number_format +
                    "} {" +
                    serial.date_format +
                    "} " +
                    serial.postfix +
                    " (order: " +
                    serial.order_of_items +
                    ")",
            });
        });
    },
    methods: {
        showContextMenu(event, doc_field) {
            event.preventDefault();
            this.showMenu = true;
            this.targetRow = doc_field;
            this.menuX = event.clientX - 1350;
            this.menuY = event.clientY - 20;
        },
        closeContextMenu() {
            this.showMenu = false;
        },
        async handleActionClick(action) {
            this.closeContextMenu();
            switch (action) {
                case "edit":
                    this.editMetaData = this.targetRow;
                    this.showEditField = true;
                    break;
                case "delete":
                    this.$swal
                        .fire({
                            title: "Are you sure?",
                            text: "Once deleted, you will not be able to recover this Meta Data and All It's Contents!",
                            icon: "warning",
                            showCancelButton: true,
                            confirmButtonText: "Yes, delete it!",
                            cancelButtonText: "No, cancel!",
                            reverseButtons: true,
                            showLoaderOnConfirm: true,
                        })
                        .then((result) => {
                            if (result.isConfirmed) {
                                axios.get(
                                        "/folder/fields/delete/" +
                                        this.targetRow.id,
                                    {
                                        headers: {
                                            Authorization: `Bearer ${this.token}`,
                                            "Content-type": "application/json",
                                        },
                                    }
                                )
                                    .then((response) => response.data)
                                    .then((response_data) => {
                                        if (response_data.data == 403) {
                                            // Use it!
                                            this.toast.error(
                                                response_data.message,
                                                {
                                                    timeout: 5000,
                                                }
                                            );
                                        } else {
                                            // Use it!
                                            this.toast.success(
                                                response_data.message,
                                                {
                                                    timeout: 5000,
                                                }
                                            );
                                            this.$emit(
                                                "update-index-field-data",
                                                "delete",
                                                response_data.data.data
                                            );
                                        }
                                    });
                                // console.log(response);
                                // const data = response.json();
                            } else {
                                this.toast.success("Meta Data Field is safe!", {
                                    timeout: 5000,
                                });
                            }
                        });
                    break;
                // Add cases for other tabs
                default:
                    break;
                // console.log(action);
                // console.log(this.targetRow);
            }
        },
        closeModalHandler() {
            // Update the prop to close the modal
            this.showEditField = false;
            this.showAddField = false;
        },
        fetchSerialNumber(field) {
            return axios.get(
                "/serialisations/generate/" + field.id,
                {
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                        "Content-type": "application/json",
                    },
                }
            )
                .then((response) => {
                    return response.data;
                })
                .then((data) => {
                    console.log(data.data);
                    return data.data;
                });
        },
        parseItem(item) {
            return {
                id: item.name,
                label: item.name,
                children: item.children
                    ? item.children.map((child) => this.parseItem(child))
                    : [],
            };
        },
        async addItem() {
            // Implement logic to handle editing the indexing field
            this.newDocField.document_id = this.$props.document.id;
            const response = await axios.post(
                "/document/docfields/store",
                {
                        document_id: this.newDocField.document_id,
                        field_id: this.newDocField.field_id,
                        value: this.newDocField.value,
                    },
                {
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                        "Content-type": "application/json",
                    },
                }
            );
            const data = await response.data;
            if (data.data == 403) {
                // Use it!
                this.toast.error(data.message, {
                    timeout: 5000,
                });
            } else {
                // Use it!
                this.toast.success(data.message, {
                    timeout: 5000,
                });
                this.$emit("update-meta-data-data", "add", data.data);
            }
        },
        updateMetaData(field) {
            this.$emit("update-meta-data-data", "update", field);
        },
    },
};
</script>

<style scoped></style>
