import axios from "axios";
import { defineStore } from "pinia";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";

export const useAuth = defineStore("auth", {
    state: () => ({
        toast: useToast(),
        router: useRouter(),
        token: localStorage.getItem("edms_token"),
        id: localStorage.getItem("userId"),
        user: {},
        user_permissions: [],
        allowedFolders: [],
        allowedDocuments: [],
        username: "",
        password: "",
        email:"",
        folder: {},
        document: {},
        notfound:"",
        found:"",
        newPassword:"",
        confirmPassword:"",
        passwordReset:"",
        tokenNotFound:"",
        showPassword:false,
        Loading:false,
    }),

    getters: {},

    actions: {
        handleLoginSuccess() {
            this.router.push("/");
        },
        logout() {
            localStorage.removeItem("edms_token");
            localStorage.removeItem("userId");
            this.Loading = false
            this.token = null;
            window.location.reload();
            this.router.push("/login");
         },
        async login() {

            try {
                this.Loading = true
                const response = await axios.post(
                    "/auth/login/",
                    {
                        username: this.username,
                        password: this.password,
                    },
                    {
                        headers: { "Content-type": "application/json" },
                    }
                );

                const data = response.data;

                if (data.user && data.user.id && data.user.is_active == 1) {
                    this.token = data.access_token;
                    localStorage.setItem("edms_token", this.token);
                    this.user = data.user;
                    localStorage.setItem("userId", this.user.id);
                    this.id = this.user.id;
                    await this.checkUserStatus();
                     setTimeout(() => {
                    this.handleLoginSuccess();
                    }, 1000); 
                    
                } 
                else {
                    setTimeout(() => {
                    this.notfound = "Unauthorized Access"
                    this.Loading = false
                    }, 3000); 
                    setTimeout(() => {
                    this.notfound = ''
                    }, 5000); 
                }
            } catch (error) {
                console.error("Error:", error);
                  if(error.response.data.status == "false"){
                    this.notfound = error.response.data.error
                    this.Loading = false
                    setTimeout(() => {
                    this.notfound = ''
                    }, 5000); 
                    }else{
                    this.notfound = "Internal server error, please try again"
                    this.Loading = false
                    setTimeout(() => {
                    this.notfound = ''
                    }, 5000); 
                }
            }
        },
        async checkUserStatus() {
            if (!this.id) {
                console.warn("User ID is null");
                return;
            }
            try {
                const response = await axios.get(`/user/show/${this.id}`, {
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                        "Content-Type": "application/json",
                    },
                });

                const data = response.data;
                this.user = data.data.data;
                console.log("this.user",this.user)

                if (!data) {
                    this.toast.error(data.message, {
                        timeout: 5000,
                    });
                }

                if (this.user.is_active === 1) {
                    this.user.roles.forEach((role) => {
                        role.permissions.forEach((permission) => {
                            //return array of the permissions and the array should be unique
                            if (
                                !this.user_permissions.includes(
                                    permission.permission_name
                                )
                            ) {
                                this.user_permissions.push(
                                    permission.permission_name
                                );
                            }
                        });
                    });
                }
            } catch (error) {
                console.error("Error:", error);
                // this.toast.error("Unauthorized Access", {
                //     timeout: 5000,
                // });
            }
        },
        async forgotPassword() {
            try{
                this.Loading = true
                const response = await axios.post(
                    "/auth/forgotpassword",
                    {
                        email: this.email,
                    },
                    {
                        headers: { "Content-type": "application/json" },
                    }
                );

                const data = await response.data;
                console.log(data)

                if (data.success == true) {
                    this.found = data.message
                    this.Loading = false
                    setTimeout(() => {
                    this.found = ''
                    this.email = ""
                    }, 5000); 
                }
            }catch(error){
                console.error("Error:", error);
                if(error && error.response && error.response.data && error.response.data.data == 404){
                    this.notfound = error.response.data.message
                    this.Loading = false
                    setTimeout(() => {
                    this.notfound = ''
                    }, 5000); 
                }else{
                    this.notfound = "Internal server error, please try again"
                    this.Loading = false
                    setTimeout(() => {
                    this.notfound = ''
                    }, 5000); 
                }
            }

        },

        async resetPassword(){
             const params = new URLSearchParams(window.location.search);
             const token = params.get('token');
             const email = params.get('email');
             console.log('token',token,'email',email);
            try{
                this.Loading = true
                const response = await axios.post(
                    "/auth/resetpassword",
                    {
                        email: email,
                        token: token,
                        password: this.newPassword,
                        password_confirmation: this.confirmPassword
                    },
                    {
                        headers: { "Content-type": "application/json" },
                    }
                );
                const data = await response.data;
                console.log(data)
                if (data.success == true) {
                    this.passwordReset = data.message
                    this.Loading = false
                    setTimeout(() => {
                    this.passwordReset = ''
                    this.router.push("/login");
                    }, 3000); 
                     
                }
            }catch(error){
                if (error.response.data.data == 404) {
                    this.tokenNotFound = error.response.data.message;
                    this.Loading = false
                    setTimeout(() => {
                    this.tokenNotFound = ''
                    }, 5000); 
                }
                if(error.response && error.response.status == 422) {
                    this.tokenNotFound = "Password and confirm password do not match"
                    this.Loading = false
                    setTimeout(() => {
                    this.tokenNotFound = ''
                    }, 3000); 
                }
            }
        },
        allowedAccess(permission, folder = {}, document = {}) {
            let folder_allowed_users = folder?.allowed_users?.split(",") || [];
            let document_allowed_users =
                document?.allowed_users?.split(",") || [];
            if (this.user_permissions.includes(permission)) {
                if (
                    folder_allowed_users.length == 0 &&
                    document_allowed_users.length == 0
                ) {
                    return true; // No restrictions on folders or documents
                } else if (
                    folder_allowed_users.includes(this.user.id.toString()) ||
                    document_allowed_users.includes(this.user.id.toString())
                ) {
                    return true;
                }
            }
            return false;
        },
    },
});
