<template>
    <div id="myModal" class="modalIn">
        <div class="modalIn-content-right" style="float: right">
            <span class="close" @click="closeModal()">&times;</span>
            <h2 id="modalTitle" class="modalIn-title">Create New WorkStep</h2>
            <!---------------------------------------------------------------------------------------------------------------------------------------->
            <form v-on:submit.prevent="addWorkStepFunc">
                <input
                    type="text"
                    id="folder_id"
                    name="folder_id"
                    hidden
                    v-model="newWorkStep.folder_id"
                />
                <div class="form-group">
                    <label for="Action Type">Action Type</label>
                    <select
                        class="form-control"
                        id="Action Type"
                        name="workstep_type"
                        required
                        v-model="newWorkStep.workstep_type"
                    >
                        <option disabled value="null">Select Type</option>
                        <option value="Action">Action</option>
                        <option value="Function">Function</option>
                    </select>
                </div>
                <div
                    class="form-group"
                    v-if="newWorkStep.workstep_type == 'Function'"
                >
                    <label for="Function">Function</label>
                    <select
                        class="form-control"
                        id="Function"
                        name="workstep_type"
                        required
                        v-model="newWorkStep.activity"
                    >
                        <option disabled value="null">
                            Select System Function
                        </option>
                        <option value="print_document">Print Document</option>
                        <option value="tesing_function">Tesing Function</option>
                    </select>
                </div>
                <div
                    class="form-group"
                    v-if="newWorkStep.workstep_type == 'Action'"
                >
                    <label for="name">Activity</label>
                    <input
                        type="text"
                        class="form-control"
                        id="name"
                        placeholder="Add activity"
                        name="activity"
                        required
                        v-model="newWorkStep.activity"
                    />
                </div>
                <div
                    class="form-group"
                    v-if="newWorkStep.workstep_type == 'Action'"
                >
                    <label for="description">Description</label>
                    <input
                        type="text"
                        class="form-control"
                        id="description"
                        placeholder="Add Description"
                        name="description"
                        required
                        v-model="newWorkStep.description"
                    />
                </div>
                <div class="form-group">
                    <label for="Select Previous">Previous</label>
                    <select
                        class="form-control"
                        id="previous"
                        name="previous"
                        required
                        v-model="newWorkStep.previous"
                    >
                        <option disabled value="0">
                            Select Previous Workstep
                        </option>
                        <option
                            v-for="workstep in worksteps"
                            :key="workstep.id"
                            :value="workstep.id"
                        >
                            {{ workstep.activity }} --
                            {{ workstep.description }}
                        </option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="Select Group">Assign Team</label>
                    <select
                        class="form-control"
                        id="group_id"
                        name="group_id"
                        required
                        v-model="newWorkStep.group_id"
                    >
                        <option disabled value="null">Select Team</option>
                        <option
                            v-for="group in groups"
                            :key="group.id"
                            :value="group.id"
                        >
                            {{ group.group_name }}
                        </option>
                    </select>
                </div>
                <div style="text-align: right">
                    <button type="submit" class="btn btn-success mt-2">
                        Create WorkStep
                    </button>
                </div>
            </form>
            <!---------------------------------------------------------------------------------------------------------------------------------------->
        </div>
    </div>
</template>

<script>
import { useToast } from "vue-toastification";
import axios from "axios";

export default {
    props: {
        current_folder: {},
        worksteps: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            toast: useToast(),
            baseUrl: this.baseApiUrl,
            token: localStorage.getItem("edms_token"),
            document: null,
            version: this.document_version,
            showEditModal: false,
            newWorkStep: {
                folder_id: this.$props.current_folder.id,
                activity: null,
                description: null,
                workstep_type: null,
                previous: 0,
                group_id: null,
            },
            groups: {},
        };
    },
    mounted() {
        this.getGroups(this.$props.current_folder.folder_owner_id);
    },
    methods: {
        async getGroups(group_admin_id) {
            const response = await axios.get(
                `/groups/` + group_admin_id,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${this.token}`,
                    },
                }
            );
            const data = await response.data;
            this.groups = data.data.data;
        },
        async addWorkStepFunc() {
            const response = await axios.post(
                "/folder/worksteps/store",
                {
                        folder_id: this.newWorkStep.folder_id,
                        activity: this.newWorkStep.activity,
                        description: this.newWorkStep.description
                            ? this.newWorkStep.description
                            : this.newWorkStep.activity,
                        workstep_type: this.newWorkStep.workstep_type,
                        previous: this.newWorkStep.previous,
                        group_id: this.newWorkStep.group_id,
                    },
                {
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                        "Content-type": "application/json",
                    },
                }
            );
            const data = await response.data;
            // console.log(data);
            if (!response.ok) {
                // Use it!
                this.toast.error("Please fill all fields", {
                    timeout: 5000,
                });
            } else {
                // Use it!
                this.toast.success(data.message, {
                    timeout: 5000,
                });
                this.$emit("update-actions", this.$props.current_folder.id);
                this.closeModal();
            }
        },
        closeModal() {
            // Emit an event to inform the parent component to close the modal
            this.$emit("close-modal");
        },
    },
};
</script>

<style scoped>
.tab {
    cursor: pointer;
    padding: 10px;
}

.tab.active {
    background-color: #355b11;
    color: white;
}
</style>
