import { useProperties } from "@/stores/properties";
import { useRepository } from "@/stores/repository";
import axios from "axios";
import _ from "lodash";
import { defineStore } from "pinia";
import { useToast } from "vue-toastification";

export const useCases = defineStore("cases", {
    state: () => ({
        propertiesStore: useProperties(),
        repositoryStore: useRepository(),
        toast: useToast(),
        token: localStorage.getItem("edms_token"),
        userId: localStorage.getItem("userId"),
        showList: true,
        showThumbails: false,
        openReport: false,
        contacts: [],
        searchContact: "",
        openCases: true,
        contactview: false,
        openProperties: false,
        openCaseDetails: false,
        openCaseHistory: false,
        openCaseDocuments: false,
        openCaseDiary: false,
        openCaseRequisition: false,
        targetCase: "",
        casehistory:{
             data: [],
            links: "",
            meta: "",

        },
        casePage: false,
        sortedColumn: "created_at",
        sortOrder: "asc",
        contactsPaginationData: [],
        contactsPaginationMeta: "",
        contactsPaginationLinks: "",
        cases: {
            data: [],
            links: "",
            meta: "",
        },
        document: {},
        folder: {},
    }),
    getters: {
        filteredContacts: (state) => {
            let contacts = state.contacts;
            if (state.searchContact !== "") {
                const searchTermLowerCase = state.searchContact
                    .toString()
                    .toLowerCase();
                contacts = contacts.filter((contact) => {
                    // console.log(contact.contact)
                    const nameLowerCase = contact.name.toLowerCase();
                    const contactNameLowerCase = contact.email.toLowerCase();
                    return (
                        nameLowerCase.includes(searchTermLowerCase) ||
                        contactNameLowerCase.includes(searchTermLowerCase)
                    );
                });
            }
            if (state.sortedColumn) {
                contacts = _.orderBy(
                    contacts,
                    [state.sortedColumn],
                    [state.sortOrder]
                );
            }
            return contacts;
        },
    },
    actions: {
        async fetchCases(page = 1) {
            try {
                const response = await axios.get("/cases" + "?page=" + page, {
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                        "Content-type": "application/json",
                    },
                });
                const data = response.data;
                if (data.success == false) {
                    console.log(data.message);
                } else {
                    this.cases.data = data.data.data;
                    this.cases.links = data.data.links;
                    this.cases.meta = data.data.meta;
                }
            } catch (error) {
                console.error("Error fetching cases:", error);
                // Handle errors gracefully
            }
        },


        async addCase(caseData) {
            try {
                const response = await axios.post("/cases", caseData, {
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                        "Content-type": "application/json",
                    },
                });
                const data = response.data;
                if (data.data === 403) {
                    this.toast.error(data.message, {
                        timeout: 5000,
                    });
                } else {
                    this.toast.success(data.message, {
                        timeout: 5000,
                    });
                    this.cases.data.push(data.data.data);
                }
            } catch (error) {
                console.error(
                    "Error adding case:",
                    error.response ? error.response.data : error.message
                );
            }
        },
        async getFolder(target_folder) {
            try {
                const response = await axios.get(
                    `/folder/show/${target_folder}`,
                    {
                        headers: {
                            Authorization: `Bearer ${this.token}`,
                            "Content-type": "application/json",
                        },
                    }
                );
                const data = response.data;
                if (data.success) {
                    this.propertiesStore.current_folder = data.data.data;
                } else {
                    console.log(data.message);
                }
            } catch (error) {
                console.error("Error fetching folder:", error);
                // Handle errors gracefully
            }
        },

        async populateContactsTable(page = 1) {
            try {
                // Fetch contact data from the API
                const response = await axios.get(
                    "/contacts" + "?page=" + page,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem(
                                "edms_token"
                            )}`,
                            "Content-Type": "application/json",
                        },
                    }
                );
                const data = await response.data;
                this.contactsPaginationData = data.data.data;
                this.contactsPaginationLinks = data.data.links;
                this.contactsPaginationMeta = data.data.meta;

                this.contacts = this.contactsPaginationData;
            } catch (error) {
                console.error("Error fetching contacts data:", error);
            }
        },

        async openItem(id) {
            try {
                const response = await axios.get("/cases/" + id, {
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                        "Content-type": "application/json",
                    },
                });
                const data = response.data;
                this.targetCase = data.data.data;
                this.showCaseDetails();
            } catch (error) {
                console.error("Error fetching Case:", error);
                // Handle errors gracefully
            }
        },
        refreshData(page = 1) {
            this.showListView();
            this.fetchCases(page);
        },
        sortBy(columnKey) {
            console.log(columnKey);
            if (this.sortedColumn == columnKey) {
                this.sortOrder = this.sortOrder === "asc" ? "desc" : "asc";
            } else {
                this.sortedColumn = columnKey;
                this.sortOrder = "asc";
            }
        },

        showReport() {
            this.openReport = true;
            this.openProperties = false;
            this.openCaseDetails = false;
            this.openCases = false;
            this.casePage = false;
            this.openCaseHistory = false;
            this.openCaseDocuments = false;
            this.openCaseDiary = false;
            this.contactview = false;
            this.openCaseRequisition = false;
        },
        showListView() {
            this.openCases = true;
            this.openReport = false;
            this.openProperties = false;
            this.openCaseDetails = false;
            this.openCaseHistory = false;
            this.openCaseDocuments = false;
            this.openCaseDiary = false;
            this.openCaseRequisition = false;
            this.contactview = false;
            this.casePage = false;
        },
        showProperties() {
            this.focus = focus;
            this.propertiesStore.focus = focus;
            this.contactview = false;
            this.openProperties = true;
        },
        showCaseDetails() {
            this.openCases = false;
            this.openReport = false;
            this.openProperties = false;
            this.openCaseDetails = true;
            this.openCaseHistory = false;
            this.openCaseDocuments = false;
            this.openCaseDiary = false;
            this.openCaseRequisition = false;
            this.contactview = false;
            this.casePage = true;
        },
        showCaseHistory() {
            this.openCases = false;
            this.openReport = false;
            this.openProperties = false;
            this.openCaseDetails = false;
            this.openCaseHistory = true;
            this.openCaseDocuments = false;
            this.openCaseDiary = false;
            this.openCaseRequisition = false;
            this.contactview = false;
            this.casePage = true;
        },
        showCaseDiary() {
            this.openCases = false;
            this.openReport = false;
            this.openProperties = false;
            this.openCaseDetails = false;
            this.openCaseDocuments = false;
            this.openCaseHistory = false;
            this.openCaseDiary = true;
            this.openCaseRequisition = false;
            this.contactview = false;
            this.casePage = true;
        },
        showCaseDocuments() {
            this.openCases = false;
            this.openReport = false;
            this.openProperties = false;
            this.openCaseDetails = false;
            this.openCaseHistory = false;
            this.openCaseDocuments = true;
            this.openCaseDiary = false;
            this.openCaseRequisition = false;
            this.contactview = false;
            this.casePage = true;
        },
        showCaseRequisition() {
            this.openCases = false;
            this.openReport = false;
            this.openProperties = false;
            this.openCaseDetails = false;
            this.openCaseHistory = false;
            this.openCaseDocuments = false;
            this.openCaseDiary = false;
            this.openCaseRequisition = true;
            this.contactview = false;
            this.casePage = true;
        },
        showContactView() {
            this.openCases = false;
            this.openReport = false;
            this.openProperties = false;
            this.openCaseDetails = false;
            this.openCaseHistory = false;
            this.openCaseDocuments = false;
            this.openCaseDiary = false;
            this.openCaseRequisition = false;
            this.contactview = true;
            this.casePage = false;
        },

        hideSidebar() {
            this.openProperties = false;
        },
    },
});
