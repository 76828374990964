<template>
    <div id="editUserModal" class="modalIn">
        <div class="modalIn-content-right" style="float: right">
            <span class="close" @click="closeModal">&times;</span>
            <h2 class="modalIn-title">Edit {{ group.group_name }} Team</h2>

            <!-- Form for editing a user -->
            <form @submit.prevent="editedGroup">
                <!-- Select User -->
                <div class="form-group">
                    <input
                        class="form-control mb-2 mr-sm-2"
                        v-model="editGroup.group_name"
                        placeholder="Team Name"
                    />
                </div>

                <!-- Save button -->
                <button type="submit" class="btn btn-primary">
                    Save Changes
                </button>
            </form>
        </div>
    </div>
</template>

<script>
import { useToast } from "vue-toastification";
import axios from "axios";

export default {
    props: {
        group: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            toast: useToast(),
            baseUrl: this.baseApiUrl,
            token: localStorage.getItem("edms_token"),
            users: [],
            editGroup: {},
        };
    },
    mounted() {
        this.editGroup = this.group;
    },
    methods: {
        async editedGroup() {
            try {
                const response = await axios.post(
                    `/groups/update/${this.editGroup.id}`,
                    {
                            group_name: this.group.group_name,
                            group_admin_id: this.group.group_admin_id,
                        },
                    {
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${this.token}`,
                        },
                    }
                );

                if (response.ok) {
                    this.toast.success("Group updated successfully", {
                        timeout: 3000,
                    });
                    this.closeModal();
                } else {
                    this.toast.error("Failed to update group", {
                        timeout: 3000,
                    });
                }
            } catch (error) {
                console.error("Error updating group:", error);
                this.toast.error("An error occurred while updating group", {
                    timeout: 3000,
                });
            }
        },
        closeModal() {
            this.$emit("close-modal");
        },
    },
};
</script>
